import React, { Component } from "react";

import * as htmlToImage from "html-to-image";

import { Button, Card, CardBody, Col, Container, Row } from "shards-react";

import { connect } from "react-redux";
import { personnel } from "../actions";

import card from "../images/logo-color.png";
import cardUser from "../images/techinician_profile.png";

/**
 * Componente que muestra los detalles públicos de un miembro del personal.
 * @class
 * @extends Component
 */

class PersonnelDetailPublic2 extends Component {
  /**
   * Constructor de la clase que inicializa el estado del componente.
   * @constructor
   * @param {Object} props - Propiedades pasadas al componente.
   */
  constructor(props) {
    super(props);

    this.state = {
      first_names: "",
      last_names: "",
      rfc: "",
      ssn: "",
      role: "",
      zone: "",
      address: "",
      role_definition: "",
      name: "",
      id: null,
      phone: "",
      status: false,
      activeEdit: false,
      accreditation_flag: "",
      accreditation_number: "",
      profile_picture: "",
      company: "",
      email: "",
      company_name: "",
      role_type: "",
      validity: "",
      active: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.download_image = this.download_image.bind(this);
  }
  /**
   * Se llama después de que el componente se haya montado en el DOM.
   * Se usa para cargar datos iniciales de la API y actualizar el estado del componente.
   * @memberof PersonnelDetailPublic2
   */
  componentDidMount() {
    this.props.fetch_roles();
    this.props.fetch_companies();
    this.props.fetch_zones();
    this.props.fetch_personnel_detail(this.props.match.params.id).then(() => {
      this.setState({
        first_names: this.props.personnel_detail.first_names,
        last_names: this.props.personnel_detail.last_names,
        rfc: this.props.personnel_detail.rfc,
        ssn: this.props.personnel_detail.ssn,
        role: this.props.personnel_detail.role,
        role_definition: this.props.personnel_detail.role_definition,
        role_type: this.props.personnel_detail.role_type,
        zone: this.props.personnel_detail.zone,
        address: this.props.personnel_detail.address,
        name: this.props.personnel_detail.name,
        id: this.props.personnel_detail.id,
        phone: this.props.personnel_detail.phone,
        status: this.props.personnel_detail.status,
        accreditation_flag: this.props.personnel_detail.accreditation_flag,
        accreditation_number: this.props.personnel_detail.accreditation_number,
        company: this.props.personnel_detail.company,
        company_name: this.props.personnel_detail.company_name,
        profile_picture: this.props.personnel_detail.profile_picture,
        email: this.props.personnel_detail.email,
        validity: this.props.personnel_detail.accreditation_expiration_date,
        active: this.props.personnel_detail.active,
      });
    });
  }
  /**
   * Función que maneja el cambio de estado del componente cuando el usuario interactúa con un elemento.
   * @memberof PersonnelDetailPublic2
   * @param {Object} event - Evento que activa la función.
   */
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  /**
   * Función que maneja el cambio del estado de la casilla de verificación.
   * @memberof PersonnelDetailPublic2
   */
  handleCheckbox() {
    this.setState({
      status: !this.state.status,
    });
  }
  /**
   * Función que maneja el cambio del estado de la variable 'activeEdit' que controla la edición de datos.
   * @memberof PersonnelDetailPublic2
   */
  toggleActive(event) {
    event.preventDefault();
    this.setState({
      activeEdit: !this.state.activeEdit,
    });
  }
  /**
   * Descarga una imagen en formato JPEG de los detalles del personal en la página
   * @function
   * @name download_image
   * @memberof PersonnelDetailPublic2
   * @returns {void}
   */
  download_image() {
    console.log("Clicked Download");
    console.log(this.state);
    const name = `${this.state.first_names} ${this.state.last_names}`;
    htmlToImage
      .toJpeg(document.getElementById("person-one"))
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = `${name}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  }
  /**
   * Manejador de evento que se ejecuta al enviar el formulario de actualización de los detalles del personal
   * @function
   * @name handleSubmit
   * @memberof PersonnelDetailPublic2
   * @param {object} event - El evento que desencadena el envío del formulario
   * @returns {void}
   */
  handleSubmit(event) {
    event.preventDefault();
    const data = {
      first_names: this.state.first_names,
      last_names: this.state.last_names,
      rfc: this.state.rfc,
      ssn: this.state.ssn,
      role: this.state.role,
      zone: this.state.zone,
      address: this.state.address,
      phone: this.state.phone,
      status: this.state.status,
    };
    this.props
      .update_personnel_detail(data, this.props.match.params.id)
      .then(() => {
        this.setState({
          activeEdit: false,
        });
      });
  }

  render() {
    // let id = this.props.match.params.id;
    let { personnel_detail } = this.props;
    let id = this.props.match.params.id;
    let profile_picture = null;
    if (personnel_detail !== null) {
      // profile_picture = personnel_detail.profile_picture;
      if (personnel_detail.profile_picture !== null) {
        profile_picture = new URL(personnel_detail.profile_picture).pathname;
      }
    }

    if (this.props.loading || personnel_detail === null) {
      return <h1>Cargando información de personal...</h1>;
    }
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    return (
      <Container fluid>
        <Row>
          {!this.props.user.groups.includes("comercial") && (
            <Col lg="12">
              <Button className="col-lg-4" onClick={this.download_image}>
                Descargar
              </Button>
            </Col>
          )}
          <Col lg="12">
            <Card className="my-3 public-card" id="person">
              <CardBody className="class-body">
                <Row>
                  <Col>
                    <div className="personnel-title">
                      Acreditación {this.state.role_type}
                    </div>
                  </Col>
                </Row>
                <Row className="row-title">
                  <Col>
                    <div className="personnel-title-company">
                      Nombre de la empresa
                    </div>
                  </Col>
                </Row>
                <Row className="row-company">
                  <Col>
                    <div className="personnel-title-company-one">
                      {this.state.company_name}
                    </div>
                  </Col>
                </Row>
                <Row className="container-div">
                  <div>
                    <img
                      src={profile_picture ? profile_picture : cardUser}
                      alt="personnel-img"
                      className="personnel-img"
                    />
                  </div>
                  <div className="personnel-data">
                    <Row>
                      <Col className="data-personnel-label">Nombre: </Col>
                      <Col className="data-personnel">
                        {this.state.first_names}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="data-personnel-label">Apellidos: </Col>
                      <Col className="data-personnel">
                        {this.state.last_names}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="data-personnel-label">Código: </Col>
                      <Col className="data-personnel">
                        {this.state.accreditation_number}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="data-personnel-label">Perfil: </Col>
                      <Col className="data-personnel">
                        {this.state.role_definition}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="color-orange-label">Validez: </Col>
                      <Col
                        className={
                          this.state.accreditation_flag === "danger" ||
                          this.state.active === false
                            ? "color-danger"
                            : "color-orange"
                        }
                      >
                        {this.state.accreditation_flag === "danger" ||
                        this.state.active === false
                          ? "Sin vigencia"
                          : this.state.validity}
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Col sm={3} className="test-one">
                    <img
                      src={`/personnel/${id}/qr/`}
                      alt="qr code"
                      className="qr"
                    />
                  </Col>
                  <Col sm={9} className="pagination-centered">
                    <hr
                      className="my-3"
                      style={{ borderTop: "1px solid #000000", width: "100%" }}
                    />
                    <Row>
                      <Col
                        sm={mediaQuery.matches ? "8" : "12"}
                        md="8"
                        lg="8"
                        className="p-ver"
                      >
                        <p>Verifica la identidad escaneando este QR</p>
                      </Col>
                      <Col sm={mediaQuery.matches ? "4" : "12"} md="4" lg="4">
                        <img
                          src={card}
                          alt="naturgy"
                          className="img-responsive"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="test">
                    <p>
                      La Empresa Colaboradora está autorizada para efectuar
                      acciones comerciales a nombre de Naturgy
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card className="download-card">
              <Card className="public-card-download" id="person-one">
                <CardBody className="class-body-download">
                  <Row className="personnel-title-download-container">
                    <div className="personnel-title-download">
                      {this.state.company_name}
                    </div>
                  </Row>
                  <Row>
                    <Col sm="4" className="personnel-img-download-container">
                      <img
                        src={profile_picture ? profile_picture : cardUser}
                        alt="personnel-img"
                        className="personnel-img-download"
                      />
                    </Col>
                    <Col sm="8">
                      <div className="personnel-data-download">
                        <Row>
                          <Col className="data-personnel-label-download">
                            Nombre:{" "}
                          </Col>
                          <Col className="data-personnel-download">
                            {this.state.first_names}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="data-personnel-label-download">
                            Apellidos:{" "}
                          </Col>
                          <Col className="data-personnel-download">
                            {this.state.last_names}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="data-personnel-label-download">
                            Código:{" "}
                          </Col>
                          <Col className="data-personnel-download">
                            {this.state.accreditation_number}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="data-personnel-label-download">
                            Perfil:{" "}
                          </Col>
                          <Col className="data-personnel-download">
                            {this.state.role_definition}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="color-orange-label-download">
                            Validez:&nbsp;
                          </Col>
                          <Col
                            className={
                              this.state.accreditation_flag === "danger" ||
                              this.state.active === false
                                ? "color-danger-download"
                                : "color-orange-download"
                            }
                          >
                            {this.state.accreditation_flag === "danger" ||
                            this.state.active === false
                              ? "Sin vigencia"
                              : this.state.validity}
                          </Col>
                        </Row>
                        <Row>
                          <p className="p-ver-download">
                            Verifica la identidad escaneando este QR
                          </p>
                        </Row>
                        <Row>
                          <Col sm={3} className="test-one">
                            <img
                              src={`/personnel/${id}/qr/`}
                              alt="qr code"
                              className="qr-download"
                            />
                          </Col>
                          <Col sm={9} className="pagination-centered">
                            <Row>
                              <Col
                                sm={{ size: 5, offset: 5 }}
                                className="p-ver-download-one"
                              >
                                <img
                                  src={card}
                                  alt="naturgy"
                                  className="img-responsive-download"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <p className="test-download">
                      La Empresa Colaboradora está autorizada para efectuar
                      acciones comerciales a nombre de Naturgy
                    </p>
                  </Row>
                </CardBody>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    personnel_detail: state.personnel.personnel_detail,
    loading: state.personnel.loading_personnel_detail,
    roles: state.personnel.roles,
    zones: state.personnel.zones,
    companies: state.personnel.companies,
    user: state.auth.user,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_personnel_detail: (id) => {
      return dispatch(personnel.fetch_personnel_detail_public(id));
    },
    fetch_roles: () => {
      return dispatch(personnel.fetch_roles());
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
    fetch_companies: () => {
      return dispatch(personnel.fetch_companies());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonnelDetailPublic2);
