import axios from 'axios';

/**
 * Función que se encarga de realizar una petición GET al endpoint '/api/quotations/' para obtener las cotizaciones.
 * @name fetch_quotations
 * @function
 * @returns {Promise} Una promesa que resuelve con un objeto que contiene el tipo de acción y las cotizaciones obtenidas.
 * @throws {Error} Si hay algún error durante la petición GET.
*/

export const fetch_quotations = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get("/api/quotations/", {headers}).then(res => {
            return dispatch({type: 'FETCH_QUOTATIONS', quotations: res.data})
        }).catch(err => {
            console.log("Error fetching quotations");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función para crear una cotización y enviarla al servidor mediante una petición POST.
 * @name create_quotation
 * @param {object} data - Los datos de la cotización que se van a enviar al servidor en formato JSON.
 * @returns {Promise} Promesa que devuelve el resultado de la petición POST al servidor, con los datos de la cotización creada.
*/

export const create_quotation = data => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
  * Realiza una petición POST al servidor para crear la cotización con los datos proporcionados.
  * @function
  * @param {string} url - La URL del endpoint para crear la cotización en el servidor.
  * @param {object} data - Los datos de la cotización que se van a enviar al servidor en formato JSON.
  * @param {object} headers - Las cabeceras de la petición, incluyendo el token de autorización si está disponible.
  * @returns {Promise} Promesa que devuelve el resultado de la petición POST al servidor, con los datos de la cotización creada.
*/

        return axios.post("/api/quotations/", data, {headers}).then(res => {
            return dispatch({type: "CREATE_QUOTATION", quotation: res.data})
        }).catch(err => {
            console.log("Error crceating quotation");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función para recuperar los datos de una cotización existente a partir de su ID, enviando una petición GET al servidor.
 * @name fetch_quotation
 * @param {number} id - El ID de la cotización que se desea recuperar del servidor.
 * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con los datos de la cotización solicitada.
*/

export const fetch_quotation = id => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
  * Realiza una petición GET al servidor para recuperar los datos de la cotización con el ID especificado.
  * @function
  * @param {string} url - La URL del endpoint para recuperar la cotización del servidor.
  * @param {object} headers - Las cabeceras de la petición, incluyendo el token de autorización si está disponible.
  * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con los datos de la cotización solicitada.
*/

        return axios.get(`/api/quotations/${id}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_QUOTATION', quotation: res.data})
        }).catch(err => {
            console.log("Error fetching quotation");
            console.log(err);
            console.log(err.response.status);
        })
    };
};


/**
 * Función para recuperar los datos de precios de un producto o servicio existente a partir de su ID, enviando una petición GET al servidor.
 * @name fetch_pricing
 * @param {number} id - El ID del producto o servicio del que se desean recuperar los datos de precios.
 * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con los datos de precios del producto o servicio solicitado.
*/

export const fetch_pricing = id => {
   return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
  * Realiza una petición GET al servidor para recuperar los datos de precios del producto o servicio con el ID especificado.
  * @function
  * @param {string} url - La URL del endpoint para recuperar los datos de precios del producto o servicio del servidor.
  * @param {object} headers - Las cabeceras de la petición, incluyendo el token de autorización si está disponible.
  * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con los datos de precios del producto o servicio solicitado.
*/
        return axios.get(`/api/pricing/${id}/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_PRICING', pricing: res.data})
        }).catch(err => {
            console.log("Error fetching pricing");
            console.log(err);
            console.log(err.response.status);
        })
    };
};

/**
 * @name fetch_all_pricing
 * Función para recuperar todos los datos de precios de los productos y servicios existentes, enviando una petición GET al servidor.
 * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con todos los datos de precios de los productos y servicios existentes.
*/

export const fetch_all_pricing = () => {
   return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
/**
  * Realiza una petición GET al servidor para recuperar todos los datos de precios de los productos y servicios existentes.
  * @function
  * @param {string} url - La URL del endpoint para recuperar todos los datos de precios del servidor.
  * @param {object} headers - Las cabeceras de la petición, incluyendo el token de autorización si está disponible.
  * @returns {Promise} Promesa que devuelve el resultado de la petición GET al servidor, con todos los datos de precios de los productos y servicios existentes.
*/
        return axios.get(`/api/pricing/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_ALL_PRICING', pricing: res.data})
        }).catch(err => {
            console.log("Error fetching pricing");
            console.log(err);
            console.log(err.response.status);
        })
    };
};

/**
 * Función para actualizar una cotización existente, enviando una petición PUT al servidor.
 * @name update_quotation
 * @param {object} data - El objeto que contiene los nuevos datos de la cotización que se va a actualizar.
 * @param {number} id - El ID de la cotización que se va a actualizar.
 * @returns {Promise} Promesa que devuelve el resultado de la petición PUT al servidor, con los datos actualizados de la cotización.
*/

export const update_quotation = (data, id) => {
    return (dispatch, getState) => {
        console.log('IN UPDATE');
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        data = JSON.stringify(data);

/**
  * Realiza una petición PUT al servidor para actualizar una cotización existente.
  * @function
  * @param {string} url - La URL del endpoint para actualizar la cotización existente en el servidor.
  * @param {object} data - El objeto que contiene los nuevos datos de la cotización que se va a actualizar.
  * @param {object} headers - Las cabeceras de la petición, incluyendo el token de autorización si está disponible.
  * @returns {Promise} Promesa que devuelve el resultado de la petición PUT al servidor, con los datos actualizados de la cotización.
*/
        return axios.put(`/api/quotations/${id}/`, data, {headers}).then(res => {
            console.log(res.data);
            return dispatch({type: 'UPDATE_QUOTATION', quotation: res.data})
        }).catch(err => {
            console.log("Error updating quotation");
            console.log(err);
            console.log(err.response.status);
        })
    };
};