/**
 * Un objeto Dispatcher que utiliza el patrón Flux para manejar acciones en una aplicación.
 * @typedef {Object} Dispatcher
*/

/**
 * Una instancia de Dispatcher que se exporta como predeterminada.
 * @type {Dispatcher}
*/

import { Dispatcher } from "flux";

export default new Dispatcher();
