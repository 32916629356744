import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";

import { companies, personnel } from "../actions";
import CompanyData from "../components/companies/CompanyData";
import CompanyFilesMultiple from "../components/companies/CompanyFilesMultiple";
import CompanyPersonnel from "../components/companies/CompanyPersonnel";
import CompanyUsers from "../components/companies/CompanyUsers";
import Contacts from "../components/companies/Contacts";

/**
 * Clase que representa la vista de la empresa del usuario.
 * @extends Component
 */
class UserCompany extends Component {
  /**
   * Constructor de la clase UserCompany.
   * @constructor
   * @param {Object} props - Las propiedades de la clase.
   */
  constructor(props) {
    super(props);
    /**
     * El estado de la clase.
     * @type {Object}
     * @property {string} company_name - El nombre de la empresa.
     * @property {string} zone - La zona de la empresa.
     * @property {boolean} edit - Bandera para habilitar la edición.
     */
    this.state = {
      company_name: "",
      zone: "",
      edit: false,
    };
    /**
     * Método que se encarga de enviar el formulario.
     * @function submit
     * @param {Object} event - El evento del formulario.
     */
    this.submit = this.submit.bind(this);
  }

  submit(event) {}

  /**
   * Método que se ejecuta después de que el componente se monta.
   * @function componentDidMount
   */
  componentDidMount() {
    // console.log('USER COMPANY PROPS', this.props)
    /**
     * Método que se encarga de obtener la información de la empresa y actualizar el estado.
     * @function fetch_company
     * @param {number} this.props.company_id - El ID de la empresa.
     * @returns {Promise} - La promesa con la información de la empresa.
     */
    this.props.fetch_company(this.props.company_id).then(() => {
      const { company } = this.props;
      this.setState({
        company_name: company.company_name,
        zone: company.zone.id,
      });
    });
    /**
     * Método que se encarga de obtener las zonas.
     * @function fetch_zones
     */
    this.props
      .fetch_zones()
      .then((zones) => this.setState({ ...this.state, zones: zones.zones }));
  }

  /**
   * Método que renderiza el componente.
   * @function render
   * @returns {JSX.Element} - El componente renderizado.
   */
  render() {
    const { company, contacts } = this.props;
    if (company === null)
      return (
        <Container fluid>
          <h2>Cargando información</h2>
        </Container>
      );
    const personnel = company.personnel;
    const users = company.users;

    return (
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="my-4">
              <CardHeader>
                <h6 className="m-0">Datos de la empresa</h6>
              </CardHeader>
              <CardBody>
                <CompanyData
                  company_id={this.props.company_id}
                  company={true}
                  zones={this.state.zones}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg="6">
            <Contacts
              contacts={contacts}
              company_id={this.props.company_id}
              company={true}
            />
          </Col>
          <Col lg="6">
            <CompanyUsers
              users={users}
              company_id={this.props.company_id}
              company={true}
            />
          </Col>
          <Col lg="12">
            <CompanyFilesMultiple
              company_id={this.props.company_id}
              company={true}
            />
          </Col>
          <Col lg="6">
            <Card className="my-4">
              <CardHeader>
                <h6 className="m-0">Personal</h6>
              </CardHeader>
              <CardBody>
                <CompanyPersonnel personnel={personnel} company={true} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    company: state.companies.detail,
    zones: state.personnel.zones,
    contacts: state.companies.contacts,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_company: (id) => {
      return dispatch(companies.fetch_company(id));
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCompany);
