import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader} from "shards-react";
import CustomTable from "../CustomTable";

/**
 * Componente de clase que representa la sección de usuarios de una empresa.
 * @extends Component
*/
class CompanyUsers extends Component {
/**
 * Constructor de la clase CompanyUsers.
 * @param {Object} props - Propiedades que recibe el componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            file: "",
            name: "",
        }

        this.toggle_file = this.toggle_file.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }
/**
 * Manejador del evento de envío de formulario de carga de archivo.
 * @param {Event} e - Evento del formulario.
*/
    submit_file = (e) => {
        e.preventDefault();
        this.props.create_company_file(this.props.company_id, this.state).then(this.clear_all());
    };
/**
 * Función que limpia el formulario de carga de archivo.
*/
    clear_all = () => {
        this.setState({
            open: false,
            name: "",
            file: "",
        })
    };
/**
 * Función que cambia el estado de la variable open para mostrar u ocultar el modal de carga de archivo.
*/
    toggle_file = () => {
        this.setState({
            open: !this.state.open
        });
    };

    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };
/**
 * Manejador del evento de cambio de valor de un input.
 * @param {Event} event - Evento del input.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    render() {
        const {users} = this.props;
        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };
        const header = [
            {title: 'Nombre', prop: 'name', filterable: true},
            // {title: 'Editar', prop: 'edit'},
        ];
        let table_items = users.map((user, id) => ({
            name: user.name,
        }));

        return (
            <div>
                <Card className="my-4">
                    <CardHeader>
                        <h6 className="m-0">Usuarios
                        </h6>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive">
                            <CustomTable
                                tableHeaders={header}
                                tableBody={table_items}
                                tableClass="table-striped hover responsive"
                                rowsPerPage={10}
                                initialSort={{prop: 'name', isAscending: true}}
                                labels={customLabels}
                            />
                        </div>

                    </CardBody>
                </Card>
                <Modal open={this.state.open} toggle={this.toggle_file}>
                    <ModalHeader>Agregar Usuario</ModalHeader>
                    <ModalBody>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

/**
 * Función que mapea las propiedades del estado de la aplicación a las propiedades del componente.
 * @param {Object} state - Estado de la aplicación.
 * @returns {Object} - Objeto con las propiedades que se le pasan al componente.
*/

const mapStateToProps = state => {
    return {
    }
};
/**
 * Función que mapea las acciones de la aplicación a las propiedades del componente.
 * @param {Function} dispatch - Función de dispatch de Redux.
*/
const mapDispatchToProps = dispatch => {
    return {

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsers);
