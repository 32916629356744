import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Row,
} from "shards-react";

import CustomTable from "../components/CustomTable";

import { personnel } from "../actions";

/**
 * Clase que representa un componente "Personnel" que extiende de "Component".
 * @class
 * @extends Component
 * @constructor
 * @param {object} props - Propiedades para inicializar el componente.
 * @param {function} props.create_personnel - Función para crear personal.
 * @param {function} props.fetch_zones - Función para obtener zonas.
 * @param {function} props.fetch_roles - Función para obtener roles.
 * @param {function} props.fetch_personnel - Función para obtener personal.
 * @param {function} props.fetch_companies - Función para obtener empresas.
 * @property {object} state - Estado del componente.
 * @property {string} state.first_names - Nombres del personal.
 * @property {string} state.last_names - Apellidos del personal.
 * @property {string} state.accreditation_number - Número de acreditación del personal.
 * @property {string} state.address - Dirección del personal.
 * @property {string} state.rfc - Registro Federal de Contribuyentes (RFC) del personal.
 * @property {string} state.phone - Teléfono del personal.
 * @property {string} state.company - Empresa a la que pertenece el personal.
 * @property {string} state.ssn - Número de Seguro Social (SSN) del personal.
 * @property {string} state.role - Rol que desempeña el personal.
 * @property {string} state.email - Correo electrónico del personal.
 * @function submit_personnel - Función que se encarga de enviar los datos del formulario para crear personal.
 * @function componentDidMount - Función del ciclo de vida del componente que se ejecuta después de que el componente haya sido montado.
 * @function form_cleanup - Función que se encarga de limpiar el formulario después de que se ha enviado correctamente.
 * @returns {React.Component} - Componente "Personnel".
 */

class Personnel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_names: "",
      last_names: "",
      accreditation_number: "",
      address: "",
      rfc: "",
      phone: "",
      company: "",
      ssn: "",
      role: "",
      email: "",
      addPersonal: false,
      selectedFilter: "all",
    };
    this.toggle = this.toggle.bind(this);
    this.submit_personnel = this.submit_personnel.bind(this);
    this.form_cleanup = this.form_cleanup.bind(this);
  }

  submit_personnel(event) {
    event.preventDefault();
    this.props.create_personnel(this.state).then(this.form_cleanup());
  }

  toggle() {
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  }

  componentDidMount() {
    this.props.fetch_zones();
    this.props.fetch_roles();
    this.props.fetch_personnel();
    this.props.fetch_companies();
  }

  form_cleanup() {
    this.setState({
      first_names: "",
      last_names: "",
      accreditation_number: "",
      address: "",
      rfc: "",
      phone: "",
      company: "",
      ssn: "",
      role: "",
      email: "",
      addPersonal: false,
      open: false,
    });
  }

  handleFilterSelection = (value) => {
    this.setState({
      selectedFilter: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log(prevState.selectedFilter);
    console.log(this.state.selectedFilter);
    if (prevState.selectedFilter !== this.state.selectedFilter) {
      this.props.fetch_personnel(this.state.selectedFilter);
    }
  }

  render() {
    const personnel = this.props.personnel.personnel;
    const customLabels = {
      first: "<<",
      last: ">>",
      prev: "<",
      next: ">",
      show: "Display",
      entries: "rows",
      noResults: "No hay resultados",
      filterPlaceholder: "Buscar",
    };
    const personnel_header = [
      { title: "Estatus", prop: "status" },
      { title: "Nombre", prop: "name", filterable: true },
      { title: "Rol", prop: "role", filterable: true },
      { title: "Empresa", prop: "company", filterable: true },
      { title: "Zona", prop: "zone", filterable: true },
      { title: "Ver", prop: "view" },
    ];
    let personnel_table_items = personnel.map((person, id) => ({
      name: person.name,
      status: (
        <i
          className={`material-icons label-success tl-${person.accreditation_flag}`}
        >
          brightness_1
        </i>
      ),
      role: person.role_name,
      company: person.company_name,
      zone: person.zone,
      view: (
        <Link to={this.props.user.groups.includes('comercial') ? `/personnel/credential/${person.id}` : `/personnel/${person.id}`}>
          <Button className="m-0">Ver</Button>
        </Link>
      ),
    }));

    return (
      <Container fluid>
        <Row>
          <Col lg={this.state.addPersonal === false ? "12" : "6"}>
            <Card className="my-3">
              <CardHeader>
                <h6 className="m-0">Plantilla de Personal</h6>
              </CardHeader>

              <CardBody>
                <Row>
                  <Dropdown
                    open={this.state.open}
                    toggle={this.toggle}
                    className="d-table"
                    group
                  >
                    <DropdownToggle caret>
                      {this.state.selectedFilter === "all"
                        ? "Filtrar"
                        : `${this.state.selectedFilter}`}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.handleFilterSelection("activos")}
                      >
                        Activos
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleFilterSelection("inactivos")}
                      >
                        Inactivos
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.handleFilterSelection("all")}
                      >
                        Todos
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  {!this.props.user.groups.includes("comercial") && (
                    <Col className="mb-6">
                      <Link
                        to={`/personnel/qr/zip/?user_id=${this.props.user.id}`}
                        target="_blank"
                        className="btn btn-primary col-lg-12"
                        rel="noopener noreferrer"
                        download
                      >
                        Descargar Códigos QR
                      </Link>
                    </Col>
                  )}
                  {!this.props.user.groups.includes("comercial") &&
                    (this.state.addPersonal === true ? null : (
                      <Col className="mb-6">
                        <Button
                          className="col-lg-12"
                          onClick={() => {
                            this.setState({ addPersonal: true });
                          }}
                        >
                          Dar de Alta Personal
                        </Button>
                      </Col>
                    ))}
                </Row>
                <div className="table-responsive my-4">
                  <CustomTable
                    tableHeaders={personnel_header}
                    tableBody={personnel_table_items}
                    tableClass="table-striped hover responsive"
                    rowsPerPage={10}
                    initialSort={{ prop: "name", isAscending: true }}
                    labels={customLabels}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {this.state.addPersonal === false
            ? null
            : (() => {
                return (
                  <Col lg="6">
                    <Card className="my-3">
                      <CardHeader>
                        <h6 className="m-0">Dar de Alta Personal</h6>
                      </CardHeader>
                      <CardBody>
                        <Col className="mb-6">
                          <Button
                            className="col-lg-12"
                            onClick={() => {
                              this.setState({ addPersonal: false });
                            }}
                          >
                            Cerrar formulario
                          </Button>
                        </Col>
                        <Form onSubmit={this.submit_personnel} className="my-4">
                          <Row>
                            <Col>
                              <Row form>
                                <Col md="6" className="form-group">
                                  <label htmlFor="first-name">
                                    Nombre(s) *
                                  </label>
                                  <FormInput
                                    id="first-name"
                                    type="text"
                                    placeholder="Nombre"
                                    value={this.state.first_names}
                                    onChange={(e) =>
                                      this.setState({
                                        first_names: e.target.value,
                                      })
                                    }
                                    required
                                    className="upper"
                                  />
                                </Col>
                                <Col md="6" className="form-group">
                                  <label htmlFor="last-name">
                                    Apellido(s) *
                                  </label>
                                  <FormInput
                                    id="last-name"
                                    type="text"
                                    placeholder="Apellido"
                                    value={this.state.last_names}
                                    onChange={(e) =>
                                      this.setState({
                                        last_names: e.target.value,
                                      })
                                    }
                                    required
                                    className="upper"
                                  />
                                </Col>
                                <Col md="6" className="form-group">
                                  <label htmlFor="email">
                                    Correo Electrónico *
                                  </label>
                                  <FormInput
                                    id="email"
                                    type="email"
                                    placeholder="Correo Electrónico"
                                    value={this.state.email}
                                    onChange={(e) =>
                                      this.setState({ email: e.target.value })
                                    }
                                    className="upper"
                                    requiured
                                  />
                                </Col>
                                <Col md="6" className="form-group">
                                  <label htmlFor="rfc">R.F.C.</label>
                                  <FormInput
                                    id="rfc"
                                    type="text"
                                    pattern="[A-Za-z]{4}[0-9]{6}"
                                    placeholder="R.F.C. sin homoclave"
                                    value={this.state.rfc}
                                    onChange={(e) =>
                                      this.setState({ rfc: e.target.value })
                                    }
                                    className="upper"
                                    required
                                  />
                                </Col>
                                <Col md="12" className="form-group">
                                  <label htmlFor="role">Perfil *</label>
                                  <FormSelect
                                    id="role"
                                    value={this.state.role}
                                    onChange={(e) =>
                                      this.setState({ role: e.target.value })
                                    }
                                    required
                                  >
                                    <option value="">---</option>
                                    {this.props.roles.map((role) => (
                                      <option key={role.id} value={role.id}>
                                        {role.name}
                                      </option>
                                    ))}
                                  </FormSelect>
                                </Col>
                                <Col md="12" className="form-group">
                                  <label htmlFor="company">
                                    Empresa Colaboradora *
                                  </label>
                                  <FormSelect
                                    id="company"
                                    value={this.state.company}
                                    onChange={(e) =>
                                      this.setState({ company: e.target.value })
                                    }
                                    required
                                  >
                                    <option value="">---</option>
                                    {this.props.companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </FormSelect>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <hr />
                                </Col>

                                <Col md="6" className="form-group">
                                  <label htmlFor="phone-number">Teléfono</label>
                                  <FormInput
                                    id="phone-number"
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    placeholder="lada + teléfono (10 dígitos)"
                                    value={this.state.phone}
                                    onChange={(e) =>
                                      this.setState({ phone: e.target.value })
                                    }
                                    // required
                                    className="upper"
                                  />
                                </Col>
                                <Col md="6" className="form-group">
                                  <label htmlFor="nss">N.S.S.</label>
                                  <FormInput
                                    id="nss"
                                    type="text"
                                    placeholder="NSS"
                                    value={this.state.ssn}
                                    onChange={(e) =>
                                      this.setState({ ssn: e.target.value })
                                    }
                                    // required
                                    className="upper"
                                  />
                                </Col>
                                <Col lg="12" className="form-group">
                                  <label htmlFor="address">Dirección</label>
                                  <FormTextarea
                                    id="address"
                                    value={this.state.address}
                                    onChange={(e) =>
                                      this.setState({ address: e.target.value })
                                    }
                                  ></FormTextarea>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="my-4">
                                  <Button type="submit" className="col-lg-12">
                                    Dar de Alta
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                );
                // }
              })()}
        </Row>
      </Container>
    );
  }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */

const mapStateToProps = (state) => {
  return {
    personnel: state.personnel,
    roles: state.personnel.roles,
    zones: state.personnel.zones,
    companies: state.personnel.companies,
    user: state.auth.user,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    create_personnel: (data) => {
      return dispatch(personnel.create_personnel(data));
    },
    fetch_personnel: (filter) => {
      return dispatch(personnel.fetch_personnel(filter));
    },
    fetch_roles: () => {
      return dispatch(personnel.fetch_roles());
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
    fetch_companies: () => {
      return dispatch(personnel.fetch_companies());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personnel);
