import React, {Component} from "react";

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

import {auth} from "../actions";

/**
 * Clase que representa el componente de inicio de sesión.
 * @name Login
 * @extends Component
*/
class Login extends Component {
/**
 * Crea un objeto Login.
 * @param {Object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isAuthenticated: false,
        };
    }
/**
 * Manejador de evento para el envío del formulario de inicio de sesión.
 * @param {Object} e - Evento de envío del formulario.
*/
    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password);
    };

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />
        }

        return (
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <h1 className="ml-lg-auto mr-lg-auto ml-md-auto mr-md-auto ml-sm-auto mr-sm-auto ml-xs-auto mr-xs-auto text-center">Naturgy</h1>

                </Row>
                <Row>
                    <Col lg="12">
                        <Card small className="mb-4">
                            <ListGroup flush>
                                <ListGroupItem className="p-3">
                                    <Row>
                                        <Col>
                                            <Form onSubmit={this.onSubmit}>
                                                <Row form>
                                                    {/* Email */}
                                                    <Col lg="12">
                                                        <InputGroup seamless className="mb-3">
                                                            <InputGroupAddon type="prepend">
                                                                <InputGroupText>
                                                                    <i className="material-icons">person</i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormInput
                                                                type="text"
                                                                id="username"
                                                                placeholder="Usuario"
                                                                onChange={e => this.setState({username: e.target.value})}
                                                                autoComplete="username"
                                                                required
                                                            />
                                                        </InputGroup>
                                                    </Col>

                                                    {/* Password */}
                                                    <Col lg="12">
                                                        <InputGroup seamless className="mb-3">
                                                            <InputGroupAddon type="prepend">
                                                                <InputGroupText>
                                                                    <i className="material-icons">lock</i>
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <FormInput
                                                                type="password"
                                                                id="Password"
                                                                placeholder="Contraseña"
                                                                onChange={e => this.setState({password: e.target.value})}
                                                                autoComplete="current-password"
                                                                required
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        {this.props.errors.length > 0 && (
                                                            <ul>
                                                                {this.props.errors.map(error => (
                                                                    <li key={error.field}>{error.message}</li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12" className="my-2">
                                                        <Button theme="accent" className="btn-block btn btn-primary">Login</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
/**
 * Mapea el estado de la aplicación a las propiedades del componente.
 * @param {Object} state - El estado de la aplicación.
 * @returns {Object} Las propiedades del componente.
*/
const mapStateToProps = state => {
    let errors = [];
    if (state.auth.errors) {
        errors = Object.keys(state.auth.errors).map(field => {
            return {field, message: state.auth.errors[field]};
        });
    }
    return {
        errors,
        isAuthenticated: state.auth.isAuthenticated
    };
};
/**
 * Mapea las acciones de la aplicación a las propiedades del componente.
 * @param {function} dispatch - La función dispatch de Redux.
 * @returns {Object} Las propiedades del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => {
            return dispatch(auth.login(username, password));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
