import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

/**
 * Un objeto Dispatcher que utiliza el patrón Flux para manejar acciones en una aplicación.
 * @typedef {Object} Dispatcher
*/

/**
 * Un objeto que contiene constantes utilizadas en la aplicación.
 * @typedef {Object} Constants
*/

/**
 * Un objeto que representa un Store, que contiene los datos de la aplicación y maneja la lógica de negocio.
 * @typedef {Object} Store
 * @property {function} getMenuState - Obtiene el estado del menú.
 * @property {function} getSidebarItems - Obtiene los elementos de la barra lateral.
 * @property {function} addChangeListener - Agrega un escucha de eventos de cambio.
 * @property {function} removeChangeListener - Remueve un escucha de eventos de cambio.
*/

/**
 * Un objeto que contiene referencias a las constantes y el Dispatcher de la aplicación.
 * @typedef {Object} AppComponents
 * @property {Constants} Constants - Constantes utilizadas en la aplicación.
 * @property {Dispatcher} Dispatcher - Dispatcher utilizado en la aplicación.
*/

/**
 * El estado inicial del Store.
 * @typedef {Object} InitialState
 * @property {boolean} menuVisible - Determina si el menú está visible.
 * @property {Array} navItems - Elementos de la barra lateral.
*/

/**
 * El estado actual del Store.
 * @type {InitialState}
 * @private
*/

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems()
};

/**
 * Una clase que representa un Store, que contiene los datos de la aplicación y maneja la lógica de negocio.
 * @name Store
 * @extends EventEmitter
 * @type {Store}
*/

class Store extends EventEmitter {
  constructor() {
    super();
/**
 * Registra las acciones que afectan al Store.
 * @param {Object} payload - La información asociada con la acción.
*/
    this.registerToActions = this.registerToActions.bind(this);
/**
 * Cambia el estado del menú y emite un evento de cambio.
*/
    this.toggleSidebar = this.toggleSidebar.bind(this);
/**
 * Registra el Store con el Dispatcher para recibir acciones.
*/
    Dispatcher.register(this.registerToActions.bind(this));
  }
/**
 * Registra las acciones que afectan al Store.
 * @param {Object} payload - La información asociada con la acción.
*/
  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }
/**
 * Cambia el estado del menú y emite un evento de cambio.
*/
  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }
/**
 * Obtiene el estado actual del menú. 
 * @returns {boolean} - true si el menú está visible, false si no.
*/
  getMenuState() {
    return _store.menuVisible;
  }
/**
 * Obtiene los elementos de la barra lateral.
 * @returns {Array} - Elementos de la barra lateral.
*/
  getSidebarItems() {
    return _store.navItems;
  }
/**
 * Agrega un escucha de eventos de cambio.
 * @param {function} callback - La función que se llamará cuando ocurra un evento de cambio.
*/
  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
