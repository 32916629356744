/**
 * Estado inicial del módulo de quote
 * @typedef {Object} initialState
 * @property {Array} files - Arreglo que contiene los archivos
*/

const initialState = {
    files: [],
};

/**
 * Función reductora para el módulo de quote
 * @function quote
 * @param {initialState} state - Estado actual del módulo
 * @param {Object} action - Objeto de acción enviado por el store
 * @param {string} action.type - Tipo de acción a realizar
 * @param {Array} action.files - Arreglo que contiene los archivos a actualizar
 * @param {Object} action.file - Objeto del archivo a agregar
 * @returns {initialState} Nuevo estado del módulo
*/

export default function quote(state = initialState, action) {
    switch (action.type) {

        case 'FETCH_FILES':
            return {...state, files: action.files};
        case 'UPLOAD_FILE':
            return {...state, files: [action.file, ...state.files]};

        default:
            return state;
    }
}