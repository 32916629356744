import React, {Component} from 'react';
import './MonthPicker.css';
import {Button, Row, Col} from "shards-react";
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css';
import {Link} from "react-router-dom";

/**
 * Clase para representar un componente de caja de mes.
 * @name MonthBox
 * @extends Component
*/

class MonthBox extends Component {
/**
 * Crea una instancia de MonthBox.
 * @param {Object} props - Propiedades para inicializar el componente.
 * @param {Object} context - Contexto del componente.
*/
    constructor(props, context) {
        super(props, context)
/**
 * Estado del componente.
 * @type {Object}
 * @property {string} value - Valor del componente.
 * @property {number} month - Mes del componente.
 * @property {number} year - Año del componente.
 */
        this.state = {
            value: this.props.value || 'N/A',
            month: this.props.month,
            year: this.props.year
        }

        this._handleClick = this._handleClick.bind(this);
    }
/**
 * Actualiza el estado del componente con las nuevas propiedades recibidas.
 * @param {Object} nextProps - Nuevas propiedades recibidas.
*/
    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value || 'N/A',
            month: nextProps.month,
            year: nextProps.year
        })
    }

    render() {
        return (
            <div className="box" onClick={this._handleClick}>
                <label>{this.state.value}</label>
            </div>
        );
    }
/**
 * Método que se encarga de manejar el evento click en la caja del mes.
 * @param {Object} e - Evento click.
*/
    _handleClick(e) {
        this.props.onClick && this.props.onClick(e);
    }
}

/**
 * Clase List que extiende de Component y representa una lista de elementos.
 * @class
 * @extends Component
 * @constructor
 * @param {Object} props - Las propiedades del componente.
 * @param {Object} context - El contexto del componente. 
 * @property {Object} state - El estado del componente, que incluye:
 * mvalue: un objeto con las propiedades year y month, que representan el año y el mes actual.
 * month: el mes actual.
 * year: el año actual.
 * @method componentWillReceiveProps - Actualiza el estado del componente con las nuevas propiedades.
 * @method handleClickMonthBox - Maneja el evento de clic en el cuadro del mes.
 * @param {Object} e - El objeto del evento de clic.
 * @method handleAMonthChange - Maneja el evento de cambio de mes en el selector.
 * @param {Object} value - El valor seleccionado en el selector.
 * @param {String} text - El texto del valor seleccionado en el selector.
 * @method handleAMonthDissmis - Maneja el evento de cancelación del selector.
 * @param {Object} value - El valor seleccionado en el selector.
 * @returns {JSX.Element} Un elemento JSX que representa la lista de elementos.
*/

class List extends Component {
    constructor(props, context) {
        super(props, context)

        let date = new Date(Date.now())

        this.state = {
            mvalue: {year: date.getFullYear(), month: date.getMonth() + 1},
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthChange = this.handleAMonthChange.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)
    }

    componentWillReceiveProps(nextProps){

        this.setState({
            value: nextProps.value || 'N/A',
            month: nextProps.value.month || "mes",
            year: nextProps.value.year || "ano",
        })
    }

    render() {

        const pickerLang = {
            months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            from: 'De', to: 'A',
        }
        const mvalue = this.state.mvalue
        const month = this.state.mvalue.month
        const year = this.state.mvalue.year

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
            return '?'
        }

        return (
            <ul>
                <li>
                    <label><b>Fecha</b><span>(Años disponibles: 2019 - 2021)</span></label>
                    <div className="edit">
                        <Picker
                            ref="pickAMonth"
                            years={[2019, 2020, 2021]}
                            value={mvalue}
                            lang={pickerLang.months}
                            onChange={this.handleAMonthChange}
                            onDismiss={this.handleAMonthDissmis}
                        >
                            <MonthBox value={makeText(mvalue)} month={month} year={year} onClick={this.handleClickMonthBox} />
                        </Picker>
                    </div>
                </li>

                <Row>
                    <Col className="mt-2">
                        <Link to={`/report-download/${this.state.month}/${this.state.year}/download/`} target='_blank' rel="noopener noreferrer" download>
                            <Button className="float-right col-md-6 col-lg-3">Generar Reporte</Button>
                        </Link>
                    </Col>
                </Row>

            </ul>

        )
    }

    handleClickMonthBox(e) {
        this.refs.pickAMonth.show()
    }
    handleAMonthChange(value, text) {
        //
    }
    handleAMonthDissmis(value) {
        this.setState({
            mvalue: value,
            month: value.month,
            year: value.year,
        })
    }
}

/**
 * Clase Main que representa un componente React que renderiza un área de lista que contiene un componente List.
 * @name Main
 * @extends Component
*/

class Main extends Component {
/**
 * Crea una instancia de Main.
 * @param {object} props - Las props que recibe el componente.
 * @param {object} context - El contexto del componente.
*/
    constructor(props, context) {
        super(props, context)

        this.state = {
            value: this.props.value,
            month: this.props.month
        }
    }
/**
 * Método del ciclo de vida de React que se ejecuta cuando las props del componente cambian.
 * Actualiza el estado del componente con las nuevas props recibidas.
 * @param {object} nextProps - Las nuevas props que recibe el componente.
*/
    componentWillReceiveProps(nextProps){
        this.setState({
            value: nextProps.value,
            month: nextProps.month
        })
    }
/**
 * Método que renderiza el componente Main y su hijo List.
 * @returns {JSX.Element} - El componente renderizado.
*/
    render() {
        return (
            <div className="list-area">
                <List />
            </div>
        )
    }
}

/**
 * Componente funcional que exporta una instancia del componente Main.
 * @returns {JSX.Element} - El componente Main.
*/

export default () => {
    return (<Main/>);
}
