import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";
import { Link } from "react-router-dom";

import CustomTable from "../components/CustomTable";

import { companies, personnel } from "../actions";

/**
 * Componente React que muestra una lista de empresas colaboradoras y permite agregar nuevas.
 * @name Companies
 * @class Companies
 * @extends {Component}
 */

class Companies extends Component {
  /**
   * Crea una instancia de Companies.
   * @param {Object} props - Propiedades pasadas al componente.
   * @memberof Companies
   */
  constructor(props) {
    super(props);
    /**
     * Estado del componente.
     * @property {string} company_name - Nombre de la empresa.
     * @property {string} zone - Zona en la que se encuentra la empresa.
     */
    this.state = {
      company_name: "",
      zone: "",
    };

    this.submit = this.submit.bind(this);
  }
  /**
   * Método que se ejecuta al enviar el formulario de agregar empresa colaboradora.
   * Previene la acción por defecto del evento y llama a la función create_company de las propiedades del componente.
   * @param {Object} event - Evento del formulario.
   * @memberof Companies
   */
  submit(event) {
    event.preventDefault();
    console.log("SUBMIT", this.state);
    this.props.create_company(this.state);
  }
  /**
   * Método que se ejecuta al montarse el componente.
   * Llama a las funciones fetch_companies y fetch_zones de las propiedades del componente.
   * @memberof Companies
   */

  componentDidMount() {
    this.props.fetch_companies();
    this.props.fetch_zones();
  }

  render() {
    console.log("HELLO", this.props.user);
    const { companies } = this.props;
    const { zones } = this.props;
    const customLabels = {
      first: "<<",
      last: ">>",
      prev: "<",
      next: ">",
      show: "Display",
      entries: "rows",
      noResults: "No hay resultados",
      filterPlaceholder: "Buscar",
    };
    const companies_header = [
      { title: "Nombre", prop: "name", filterable: true },
      { title: "Zona", prop: "zone", filterable: true },
      { title: "Activa", prop: "active", filterable: true },
    ];
    let companies_table_items = companies.map((company, id) => ({
      name: <Link to={`/companies/${company.id}`}>{company.company_name}</Link>,
      zone: company.zone.name,
      active: company?.active ? "Si" : "No",
    }));
    return (
      <Container fluid>
        <Row>
          <Col lg="7">
            <Card className="my-3">
              <CardHeader>
                <h6 className="m-0">Listado de Empresas Colaboradoras</h6>
              </CardHeader>

              <CardBody>
                <CustomTable
                  tableHeaders={companies_header}
                  tableBody={companies_table_items}
                  tableClass="table-striped hover responsive"
                  rowsPerPage={10}
                  initialSort={{ prop: "name", isAscending: true }}
                  labels={customLabels}
                />
              </CardBody>
            </Card>
          </Col>
          {(() => {
            if (!this.props.user.read_only) {
              return (
                <Row>
                  <Col>
                    <Card className="my-3">
                      <CardHeader>
                        <h6 className="m-0">
                          Dar de alta Empresa Colaboradora
                        </h6>
                      </CardHeader>

                      <CardBody>
                        <Form onSubmit={this.submit}>
                          <Row>
                            <Col>
                              <Row form>
                                <Col md="12" className="form-group">
                                  <label htmlFor="first-name">
                                    Razón Social *
                                  </label>
                                  <FormInput
                                    id="company_name"
                                    type="text"
                                    placeholder="Razón Social"
                                    value={this.state.company_name}
                                    onChange={(e) =>
                                      this.setState({
                                        company_name: e.target.value,
                                      })
                                    }
                                    required
                                    className="upper"
                                  />
                                </Col>
                                <Col md="12" className="form-group">
                                  <label htmlFor="role">Zona *</label>
                                  <FormSelect
                                    id="zone"
                                    value={this.state.zone}
                                    onChange={(e) =>
                                      this.setState({ zone: e.target.value })
                                    }
                                    required
                                  >
                                    <option value="">---</option>
                                    {zones.map((zone) => (
                                      <option key={zone.id} value={zone.id}>
                                        {zone.name}
                                      </option>
                                    ))}
                                  </FormSelect>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="my-4">
                                  <Button type="submit" className="col-lg-12">
                                    Agregar
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            }
          })()}
        </Row>
      </Container>
    );
  }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    companies: state.companies.list,
    zones: state.personnel.zones,
    user: state.auth.user,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_companies: () => {
      return dispatch(companies.fetch_companies());
    },
    create_company: (data) => {
      return dispatch(companies.create_company(data));
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
