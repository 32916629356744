import React, { Component } from "react";
import "react-dropzone-uploader/dist/styles.css";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormCheckbox,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";
import { companies } from "../../actions";

/**
 * Clase que representa un componente de datos de empresa.
 * @extends Component
 */

class CompanyData extends Component {
  /**
   * Crea una instancia del componente de datos de empresa.
   * @constructor
   * @param {Object} props - Propiedades del componente.
   */
  constructor(props) {
    super(props);

    this.state = {
      ruec: "",
      company_name: "",
      rfc: "",
      sap_creditor_id: "",
      sap_client_id: "",
      billing_address: "",
      provider_url: "",
      sap_gor: "",
      sap_pm: "",
      repse_number: "",
      work_division: [],
      business_address: "",
      business_area: "",
      sap_payment_alert_blocked: "",
      prevention_security_health_environment_certified: "",
      legal_representative: "",
      security_supervisor: "",
      active: "",
      zone: "",
    };
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * Se ejecuta después de que el componente se monta en el DOM.
   * Recupera los datos de la empresa y actualiza el estado del componente.
   */
  componentDidMount() {
    this.props.fetch_company_data(this.props.company_id).then(() => {
      const { data } = this.props;
      console.log(data);
      this.setState({ ...data });
    });
  }
  /**
   * Maneja el evento de cambio de valor en los elementos del formulario.
   * Actualiza el estado del componente según el valor del elemento.
   * @param {Object} event - Evento de cambio de valor del elemento del formulario.
   * @param {string} name - Nombre del campo en el estado del componente.
   */
  handleChange(e, name) {
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    if (name === "work_division") this.setState({ work_division: value });
    if (name === "business_area") this.setState({ business_area: value });
  }
  /**
   * Maneja el evento de envío del formulario.
   * Actualiza los datos de la empresa en la base de datos.
   * @param {Object} event - Evento de envío del formulario.
   */

  submit(event) {
    event.preventDefault();
    console.log("SUBMIT", this.state);
    this.props
      .update_company_data(this.state, this.props.company_id)
      .then((data) => {
        console.log(data);
      });
  }
  /**
   * Propiedades del componente.
   * @typedef {Object} CompanyDataProps
   * @property {string} company_id - ID de la empresa.
   * @property {Object} data - Datos de la empresa.
   * @property {Function} fetch_company_data - Función para recuperar los datos de la empresa.
   * @property {Function} update_company_data - Función para actualizar los datos de la empresa.
   */

  render() {
    console.log("CURRENT STATE", this.state);
    console.log("CURRENT PROPS", this.props);
    let disabled = this.props.company;
    if (this.props.user.read_only) disabled = true;

    return (
      <div>
        <Form onSubmit={this.submit}>
          <Row>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">RUEC</label>
              <FormInput
                id="ruec"
                type="text"
                value={this.state.ruec}
                onChange={(e) => this.setState({ ruec: e.target.value })}
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Razón Social</label>
              <FormInput
                type="text"
                value={this.state.company_name}
                onChange={(e) =>
                  this.setState({ company_name: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">RFC</label>
              <FormInput
                type="text"
                value={this.state.rfc}
                onChange={(e) => this.setState({ rfc: e.target.value })}
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Numero de Acreedor SAP</label>
              <FormInput
                type="text"
                value={this.state.sap_creditor_id}
                onChange={(e) =>
                  this.setState({ sap_creditor_id: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Numero de Cliente SAP</label>
              <FormInput
                type="text"
                value={this.state.sap_client_id}
                onChange={(e) =>
                  this.setState({ sap_client_id: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Direccion Fiscal</label>
              <FormInput
                type="text"
                value={this.state.billing_address}
                onChange={(e) =>
                  this.setState({ billing_address: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Zona</label>
              <FormSelect
                id="zone"
                value={this.state.zone}
                onChange={(e) => this.setState({ zone: e.target.value })}
                required
              >
                <option value="">---</option>
                {this.props.zones?.map((zone) => (
                  <option key={zone.id} value={zone.id}>
                    {zone.name}
                  </option>
                ))}
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Portal de Proveedores</label>
              <FormInput
                type="text"
                value={this.state.provider_url}
                onChange={(e) =>
                  this.setState({ provider_url: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">SAP GOR</label>
              <FormInput
                type="text"
                value={this.state.sap_gor}
                onChange={(e) => this.setState({ sap_gor: e.target.value })}
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">SAP PM</label>
              <FormInput
                type="text"
                value={this.state.sap_pm}
                onChange={(e) => this.setState({ sap_pm: e.target.value })}
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Número de aviso REPSE</label>
              <FormInput
                type="text"
                value={this.state.repse_number}
                onChange={(e) =>
                  this.setState({ repse_number: e.target.value })
                }
                disabled={disabled}
              />
            </Col>

            <Col md="6" className="form-group">
              <label htmlFor="first-name">Representante legal</label>
              <FormInput
                type="text"
                value={this.state.legal_representative}
                onChange={(e) =>
                  this.setState({ legal_representative: e.target.value })
                }
                disabled={disabled}
              />
            </Col>
            <Col md="6" className="form-group">
              <label htmlFor="first-name">Supervisor de seguridad</label>
              <FormInput
                type="text"
                value={this.state.security_supervisor}
                onChange={(e) =>
                  this.setState({ security_supervisor: e.target.value })
                }
                disabled={disabled}
              />
            </Col>

            <Col md="6" className="form-group">
              <label htmlFor="first-name">División de Trabajo</label>
              <FormSelect
                id="zone"
                value={this.state.work_division}
                onChange={(e) => this.handleChange(e, "work_division")}
                disabled={disabled}
                multiple
              >
                <option value="">---</option>
                <option value="X1">X1</option>
                <option value="X2">X2</option>
                <option value="X3">X3</option>
                <option value="X4">X4</option>
                <option value="X5">X5</option>
                <option value="X6">X6</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
              </FormSelect>
            </Col>

            <Col md="6" className="form-group">
              <label htmlFor="first-name">Área de Trabajo</label>
              <FormSelect
                id="zone"
                value={this.state.business_area}
                onChange={(e) => this.handleChange(e, "business_area")}
                multiple
                disabled={disabled}
              >
                <option value="">---</option>
                <option value="PERSONAS">PERSONAS</option>
                <option value="COMPRAS E INSTALACIONES">
                  COMPRAS E INSTALACIONES
                </option>
                <option value="SECURITY">SECURITY</option>
                <option value="SISTEMAS DE INFORMACION">
                  SISTEMAS DE INFORMACION
                </option>
                <option value="SEGUIMIENTO OPERATIVO">
                  SEGUIMIENTO OPERATIVO
                </option>
                <option value="FINANZAS">FINANZAS</option>
                <option value="CONTABILIDAD FISCAL">CONTABILIDAD FISCAL</option>
                <option value="SERVICIOS JURIDICOS">SERVICIOS JURIDICOS</option>
                <option value="REGULACION Y TARIFAS">
                  REGULACION Y TARIFAS
                </option>
                <option value="PLANIFICACION, CALIDAD Y SEGURIDAD">
                  PLANIFICACION, CALIDAD Y SEGURIDAD
                </option>
                <option value="EXPLOTACIÓN">EXPLOTACIÓN</option>
                <option value="PROYECTOS">PROYECTOS</option>
                <option value="BALANCE Y CONTROL DE LA ENERGÍA">
                  BALANCE Y CONTROL DE LA ENERGÍA
                </option>
                <option value="COMUNICACIÓN">COMUNICACIÓN</option>
                <option value="COMPRAS DE ENERGÍA">COMPRAS DE ENERGÍA</option>
                <option value="SOLUCIONES Y PRODUCTOS LIBERALIZADOS">
                  SOLUCIONES Y PRODUCTOS LIBERALIZADOS
                </option>
                <option value="VENTAS DE ENERGÍA">VENTAS DE ENERGÍA</option>
                <option value="PLANIFICACION COMERCIAL Y BI">
                  PLANIFICACION COMERCIAL Y BI
                </option>
                <option value="MARKETING">MARKETING</option>
                <option value="PROYECTOS DE TRANSFORMACION">
                  PROYECTOS DE TRANSFORMACION
                </option>
                <option value="DATA ANALISIS">DATA ANALISIS</option>
                <option value="SERVICIO AL CLIENTE">SERVICIO AL CLIENTE</option>
                <option value="EXPANSION">EXPANSION</option>
                <option value="SERVICIOS TECNICOS">SERVICIOS TECNICOS</option>
                <option value="CONSTRUCCIÓN">CONSTRUCCIÓN</option>
                <option value="OPERACIONES CLIENTES">
                  OPERACIONES CLIENTES
                </option>
                <option value="GRAN CONSUMO">GRAN CONSUMO</option>
              </FormSelect>
            </Col>
            <Col md="6">
              <label htmlFor="email">Empresa Activa</label>
              <FormCheckbox
                checked={this.state.active}
                onChange={(e) => this.setState({ active: !this.state.active })}
              ></FormCheckbox>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" className="col-lg-12" hidden={disabled}>
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

/**
 * Conecta el componente con el store de Redux.
 * @function
 * @param {Object} state - Estado de la aplicación.
 * @returns {Object} Props del componente.
 */
const mapStateToProps = (state) => {
  return {
    data: state.companies.data,
    user: state.auth.user,
  };
};

/**
 * Conecta el componente con el store de Redux.
 * @function
 * @param {Function} dispatch - Función de Redux
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_company_data: (id) => {
      return dispatch(companies.fetch_company_data(id));
    },
    update_company_data: (data, id) => {
      return dispatch(companies.update_company_data(data, id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyData);
