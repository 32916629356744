/**
 * Un objeto que exporta dos constantes utilizadas por la aplicación.
 * @typedef {Object} ConstantesApp
 * @property {string} CHANGE - Una constante utilizada para notificar cambios en la aplicación.
 * @property {string} TOGGLE_SIDEBAR - Una constante utilizada para alternar el estado de la barra lateral.
*/

/**
 * Un objeto que contiene dos constantes para ser utilizadas por la aplicación.
 * @type {ConstantesApp}
*/

export default {
  CHANGE: "CHANGE",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR"
};
