import React from "react";

import { Dispatcher, Constants } from "../../../flux";

/**
 * Componente que representa el botón de toggle para mostrar y ocultar la barra lateral de navegación.
 * @name NavbarToggle
 * Este componente se utiliza para cambiar el estado de la barra lateral de navegación entre visible y oculta.
 * @component
 * @example
 * return (
 * <NavbarToggle />
 * )
*/

class NavbarToggle extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }
/**
 * Manejador del evento click. Este método utiliza el Dispatcher del flux para enviar un mensaje
 * que indica que la barra lateral de navegación debe ser mostrada o oculta.
 * @function
*/
  handleClick() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    return (
      <nav className="nav">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={this.handleClick} className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center">
          <i className="material-icons">&#xE5D2;</i>
        </a>
      </nav>
    )
  }
}

export default NavbarToggle;
