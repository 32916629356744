import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Col, FormInput, InputGroup, InputGroupAddon, Row} from "shards-react";
import {personnel} from "../../actions";


/**
 * La clase Paging es un componente que se utiliza para paginar los resultados en una lista de acreditaciones de personal.
 * @name Paging
 * @class
 * @extends Component
*/

class Paging extends Component {
/**
 * Crea una instancia de Paging.
 * @constructor
 * @param {object} props - Las propiedades del componente.
*/
    constructor(props) {
        super(props);
        this.state = {
            page: null,

        };
        this.handleChange = this.handleChange.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }
/**
 * Maneja el evento de cambio de valor en el input.
 * @function
 * @name handleChange
 * @param {object} event - El evento que desencadena el cambio.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }
/**
 * Maneja el evento de clic en el botón de página siguiente.
 * @function
 * @name nextPage
*/
    nextPage() {
        this.props.fetch_personnel_accreditations(this.props.page + 1)
    }
/**
 * Maneja el evento de clic en el botón de página anterior.
 * @function
 * @name previousPage
*/
    previousPage() {
        this.props.fetch_personnel_accreditations(this.props.page - 1)
    }
/**
 * Renderiza el componente Paging.
 * @function
 * @name render
 * @returns {JSX.Element} - Devuelve el elemento JSX que representa el componente.
*/
    render() {
        const {page, pages} = this.props;

        return (
            <Row>
                <Col md={12}>

                    <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                            <Button
                                disabled={this.props.previous === null}
                                onClick={this.previousPage}
                            >Anterior</Button>
                        </InputGroupAddon>
                        <FormInput
                            type="text"
                            min={1}
                            step={1}

                            value={`Página ${page} de ${pages}`}
                            // onChange={this.handleChange}
                            className={'text-center'}
                            disabled
                        />
                        <InputGroupAddon type="append">
                            <Button
                                onClick={this.nextPage}
                                disabled={this.props.next === null}
                            >Siguiente</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
        )
    }
}
/**
 * Función que mapea el estado de Redux a las propiedades del componente.
 * @function
 * @name mapStateToProps
 * @param {object} state - El estado actual de la aplicación.
 * @returns {object} - Devuelve un objeto con las propiedades page, next, previous y pages del estado.
*/
const mapStateToProps = state => {
    return {
        page: state.personnel.personnelAccreditation.page,
        next: state.personnel.personnelAccreditation.next,
        previous: state.personnel.personnelAccreditation.previous,
        pages: state.personnel.personnelAccreditation.pages,
    }
};
/**
 * Función que mapea las acciones de Redux a las propiedades del componente.
 * @function
 * @name mapDispatchToProps
 * @param {function} dispatch - La función de despacho de Redux.
 * @returns {object} - Devuelve un objeto con la acción fetch_personnel_accreditations.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_personnel_accreditations: (page) => {
            return dispatch(personnel.fetch_personnel_accreditations(page))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Paging);
