import { combineReducers } from 'redux';

/**
 * Módulo de combinación de reducers para la aplicación
 * @module projectApp
*/

import auth from "./auth";
import quote from "./quote";
import personnel from "./personnel";
import files from "./files";
import users from "./users";
import companies from "./companies";

/**
 * Función que combina todos los reducers de la aplicación en un solo reducer raíz
 * @function combineReducers
 * @param {Object} reducers - Objeto que contiene todos los reducers de la aplicación
 * @param {Function} reducers.auth - Reducer para el módulo de autenticación
 * @param {Function} reducers.quote - Reducer para el módulo de cotizaciones
 * @param {Function} reducers.personnel - Reducer para el módulo de personal
 * @param {Function} reducers.files - Reducer para el módulo de archivos
 * @param {Function} reducers.users - Reducer para el módulo de usuarios
 * @param {Function} reducers.companies - Reducer para el módulo de empresas
 * @returns {Object} Reducer raíz combinado
*/

const projectApp = combineReducers({
    auth, quote, personnel, files, users, companies,
});

export default projectApp;