import React, {Component} from 'react';
import {quote} from '../actions';
import {connect} from 'react-redux';

/**
 * Componente de detalle de precios.
 * @class PricingDetail
 * @extends Component
 * @param {Object} props - Propiedades para inicializar el componente.
 * @property {string} state.meters - Variable para almacenar los metros de la cotización.
 * @property {string} state.materials_price - Variable para almacenar el precio de los materiales de la cotización.
 * @property {string} state.workforce - Variable para almacenar la mano de obra de la cotización.
 * @property {string} state.indirect_costs - Variable para almacenar los costos indirectos de la cotización.
 * @return {JSX.Element} - Elemento de tabla que muestra los detalles de precios.
*/
class PricingDetail extends Component {
    constructor(props) {
        super(props);
/**
  * Estado inicial del componente.
  * @type {Object}
  * @property {string} meters - Variable para almacenar los metros de la cotización.
  * @property {string} materials_price - Variable para almacenar el precio de los materiales de la cotización.
  * @property {string} workforce - Variable para almacenar la mano de obra de la cotización.
  * @property {string} indirect_costs - Variable para almacenar los costos indirectos de la cotización.
*/
        this.state = {
            meters: "",
            materials_price: "",
            workforce: "",
            indirect_costs: "",
        }
    }

    render() {

        console.log("pricing component render");
        console.log(this.props.pricingId);

        return <table className="table">
                <thead className="bg-light">
                <th scope="col" className="border-0">Variable</th>
                <th scope="col" className="border-0">Value</th>
                </thead>
                <tbody>
                <tr>
                    <td>{this.state.meters}</td>
                    <td>Meters</td>
                </tr>
                <tr>
                    <td>{this.state.materials_price}</td>
                    <td>Materials Price</td>
                </tr>
                <tr>
                    <td>{this.state.workforce}</td>
                    <td>Workforce</td>
                </tr>
                <tr>
                    <td>{this.state.indirect_costs}</td>
                    <td>Indirect Costs</td>
                </tr>
                </tbody>
            </table>
    }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/

const mapStateToProps = state => {
    return {
        pricing_detail: state.quote.pricing_detail,
    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_pricing: id => {
            return dispatch(quote.fetch_pricing(id))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingDetail)