/**
 * Objeto que representa el estado inicial del reducer auth.
 * @typedef {Object} AuthInitialState
 * @property {string} token - Token almacenado en el Local Storage.
 * @property {boolean} isAuthenticated - Indica si el usuario está autenticado.
 * @property {boolean} isLoading - Indica si se está cargando la información del usuario.
 * @property {Object|null} user - Objeto que representa al usuario autenticado.
 * @property {Object} errors - Objeto que contiene los errores que puedan ocurrir durante la autenticación.
*/

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  errors: {},
};

/**
 * Reducer que se encarga de manejar las acciones relacionadas con la autenticación del usuario.
 * @name auth
 * @param {AuthInitialState} state - Estado actual del reducer.
 * @param {Object} action - Acción que se está ejecutando.
 * @returns {AuthInitialState} - Nuevo estado del reducer.
*/

export default function auth(state=initialState, action) {

  switch (action.type) {

    case 'USER_LOADING':
      return {...state, isLoading: true};

    case 'USER_LOADED':
      let user = action.user
      user.read_only = user.groups.includes('read_only');
      return {...state, isAuthenticated: true, isLoading: false, user: action.user};

    case 'LOGIN_SUCCESSFUL':
    case 'REGISTRATION_SUCCESSFUL':
      console.log('LOGIN SUCCESSFUL', action.data.token);
      localStorage.setItem("token", action.data.token);
      let t = localStorage.getItem("token", action.data.token);
      console.log(t);
      let data = action.data;
      action.data.user.read_only = action.data.user.groups.includes('read_only') || action.data.user.groups.includes('comercial');
      return {...state, ...data, isAuthenticated: true, isLoading: false, errors: null};

    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
    case 'REGISTRATION_FAILED':
    case 'LOGOUT_SUCCESSFUL':
      localStorage.removeItem("token");
      return {...state, errors: action.data, token: null, user: null,
        isAuthenticated: false, isLoading: false};

    default:
      return state;
  }
}