import React, { Component } from "react";

import { Card, CardBody, Col, Container, Row } from "shards-react";

import { connect } from "react-redux";
import { personnel } from "../actions";
import card from "../images/logo-color.png";
import na from "../images/personnel/na.png";
import cardUser from "../images/techinician_profile.png";
/**
 * Componente de detalle de personal público.
 * @name PersonnelDetailPublic
 * @extends Component
 */
class PersonnelDetailPublic extends Component {
  /**
   * Constructor de la clase.
   * @param {object} props - Propiedades del componente.
   */
  constructor(props) {
    super(props);

    this.state = {
      first_names: "",
      last_names: "",
      rfc: "",
      ssn: "",
      role: "",
      zone: "",
      address: "",
      name: "",
      id: null,
      phone: "",
      status: false,
      activeEdit: false,
      accreditation_flag: "",
      accreditation_number: "",
      company: "",
      validity: "",
      email: "",
    };
  }
  /**
   * Método que se ejecuta al montar el componente.
   * Se encarga de obtener los detalles del personal y actualizar el estado del componente.
   */
  componentDidMount() {
    this.props.fetch_personnel_detail(this.props.match.params.id).then(() => {
      this.setState({
        first_names: this.props.personnel_detail.first_names,
        last_names: this.props.personnel_detail.last_names,
        rfc: this.props.personnel_detail.rfc,
        ssn: this.props.personnel_detail.ssn,
        role: this.props.personnel_detail.role_definition,
        role_type: this.props.personnel_detail.role_type,
        zone: this.props.personnel_detail.zone,
        address: this.props.personnel_detail.address,
        name: this.props.personnel_detail.name,
        id: this.props.personnel_detail.id,
        phone: this.props.personnel_detail.phone,
        status: this.props.personnel_detail.status,
        accreditation_flag: this.props.personnel_detail.accreditation_flag,
        accreditation_number: this.props.personnel_detail.accreditation_number,
        validity: this.props.personnel_detail.accreditation_expiration_date,
        company: this.props.personnel_detail.company_name,
        email: this.props.personnel_detail.email,
        active: this.props.personnel_detail.active,
      });
    });
  }

  render() {
    let id = this.props.match.params.id;
    let { personnel_detail } = this.props;
    let profile_picture = null;
    if (personnel_detail !== null) {
      if (personnel_detail.profile_picture !== null) {
        profile_picture = new URL(personnel_detail.profile_picture).pathname;
      }
    }

    const mediaQuery = window.matchMedia("(max-width: 767px)");

    if (
      this.state.accreditation_flag === "danger" ||
      this.state.active === false
    ) {
      return (
        <Container>
          <Col>
            <Card className="my-3">
              <img src={na} alt="personal no vigente" />
            </Card>
          </Col>
        </Container>
      );
    }
    return (
      <Container>
        <Col>
          <Card className="my-3 public-card" id="person">
            <CardBody className="class-body">
              <Row>
                <Col>
                  <div className="personnel-title">
                    Acreditación {this.state.role_type}
                  </div>
                </Col>
              </Row>
              <Row className="row-title">
                <Col>
                  <div className="personnel-title-company">
                    Nombre de la empresa
                  </div>
                </Col>
              </Row>
              <Row className="row-company">
                <Col>
                  <div className="personnel-title-company-one">
                    {this.state.company}
                  </div>
                </Col>
              </Row>
              <Row className="container-div">
                <div>
                  <img
                    src={profile_picture ? profile_picture : cardUser}
                    alt="personnel-img"
                    className="personnel-img"
                  />
                </div>
                <div className="personnel-data">
                  <Row>
                    <Col className="data-personnel-label">Nombre: </Col>
                    <Col className="data-personnel">
                      {this.state.first_names}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="data-personnel-label">Apellidos: </Col>
                    <Col className="data-personnel">
                      {this.state.last_names}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="data-personnel-label">Código: </Col>
                    <Col className="data-personnel">
                      {this.state.accreditation_number}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="data-personnel-label">Perfil: </Col>
                    <Col className="data-personnel">{this.state.role}</Col>
                  </Row>
                  <Row>
                    <Col className="color-orange-label">Validez: </Col>
                    <Col
                      className={
                        this.state.accreditation_flag === "danger" ||
                        this.state.active === false
                          ? "color-danger"
                          : "color-orange"
                      }
                    >
                      {this.state.accreditation_flag === "danger" ||
                      this.state.active === false
                        ? "Sin vigencia"
                        : this.state.validity}
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row>
                <Col sm={12} className="pagination-centered">
                  <hr
                    className="my-3"
                    style={{ borderTop: "1px solid #000000", width: "100%" }}
                  />
                  <Row>
                    <Col sm={{ size: 5, offset: 5 }}>
                      <img
                        src={card}
                        alt="naturgy"
                        className="img-responsive"
                        style={{marginLeft: "-1rem"}}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="test">
                  <p>
                    La Empresa Colaboradora está autorizada para efectuar
                    acciones comerciales a nombre de Naturgy
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    );
  }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */

const mapStateToProps = (state) => {
  return {
    personnel_detail: state.personnel.personnel_detail,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_personnel_detail: (id) => {
      return dispatch(personnel.fetch_personnel_detail_public(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonnelDetailPublic);
