import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "shards-react";

import {connect} from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from "react-router-dom";

/**
 * La clase Reports es un componente que representa una sección de la aplicación que genera un reporte.
 * Extiende de la clase Component de React.
*/

class Reports extends Component {
/**
 * La función render se encarga de renderizar el componente en el DOM.
 * @returns {JSX.Element} - El elemento JSX que representa al componente.
*/
    render() {

        return <><Container fluid className="main-content-container px-4">
            <Row className="py-4 justify-content-center">
                <Col lg={6}>
                    <Row>
                        <Col md={12} className="np">

                        </Col>
                        <Col md={12} className="np pull-left">
                            <Card small className="h-100">
                                {/* Card Header */}
                                <CardHeader>
                                    <h6 className="m-0">Header</h6>
                                </CardHeader>

                                <CardBody className="d-flex flex-column">
                                    <Link to={`/personnel/reports/accreditation/`} target='_blank' rel="noopener noreferrer" download>
                                        <Button className="w-100">Generar Reporte</Button>
                                    </Link>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container></>;
    }

}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(Reports);
