import React from 'react';
import {Card, CardBody, Container} from "shards-react";
import MonthPicker from "../components/month-picker/MonthPicker";
/**
 * Componente que representa la descarga de un informe.
 * @component
*/

/**
 * Función que retorna un componente que contiene un contenedor, una tarjeta y un selector de mes.
 * @name ReportDownload
 * @returns {JSX.Element} Componente de descarga de informe.
*/
const ReportDownload = () => {
    return <Container>

            <Card className="my-3">
                <CardBody>
                        <MonthPicker/>
                </CardBody>
            </Card>

        </Container>
}


export default ReportDownload;