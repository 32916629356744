import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {companies} from '../../actions';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormInput,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "shards-react";

/**
 * Componente que muestra los archivos de una empresa y permite agregar o eliminar archivos múltiples.
 * @name CompanyFilesMultiple
 * @extends Component
*/
class CompanyFilesMultiple extends Component {
/**
 * Crea una instancia del componente.
 * @constructor
 * @param {object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            file_open: false,
            delete_open: false,
            file: "",
            name: "",
            id_to_delete: null,
            name_to_delete: "",
        }

        this.toggle_file = this.toggle_file.bind(this);
        this.toggle_delete = this.toggle_delete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }
/**
 * Maneja el envío de archivos.
 * @param {object} e - Evento del envío.
*/
    submit_file = (e) => {
        e.preventDefault();
        const data = {
            file: this.state.file,
            name: this.state.name,
            company: this.props.company_id
        }
        this.props.create_company_file_multiple(data).then(() => {
            this.setState({
                file_open: false,
                file: "",
                name: "",
            })
        });
    };
/**
 * Obtiene los nombres de los archivos y los archivos de la empresa.
*/
    componentDidMount() {
        this.props.fetch_company_file_names();
        this.props.fetch_company_files(this.props.company_id);
    }
/**
 * Alterna el estado de la ventana emergente de carga de archivos.
*/
    toggle_file = () => {
        this.setState({
            file_open: !this.state.file_open
        });
    };
/**
 * Alterna el estado de la ventana emergente de eliminación de archivos.
 * @param {number} id - ID del archivo a eliminar.
 * @param {string} name - Nombre del archivo a eliminar.
*/
    toggle_delete = (id, name) => {
        this.setState({
            id_to_delete: id,
            delete_open: !this.state.delete_open,
            name_to_delete: name,
        });
    };
/**
 * Maneja el cambio en el estado del archivo.
 * @param {object} e - Evento del cambio.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };
/**
 * Maneja el cambio en el estado de los campos.
 * @param {object} event - Evento del cambio.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }
/**
 * Maneja la eliminación de un archivo.
*/
    handleDelete() {
        this.props.delete_company_file_multiple(this.state.id_to_delete, this.props.company_id).then(() => {
            this.setState({
                id_to_delete: null,
                name_to_delete: "",
                delete_open: false,
            })
            this.props.fetch_company_files(this.props.company_id);
        })
    }
/**
 * Renderiza el componente.
 * @returns {JSX.Element} Elemento del DOM.
*/
    render() {
        const {file_names, files} = this.props;

        return (
            <Row>
                <Col lg={6}>
                    <Card className="my-4">
                        <CardHeader>
                            <h6 className="m-0">Archivos Generales{((this.props.user.admin_access && !this.props.user.read_only) || this.props.company) && <i className="material-icons add-elements"
                               onClick={this.toggle_file}>add</i>}</h6>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Nombre
                                        </th>
                                        <th scope="col" className="border-0">
                                            Fecha de Carga
                                        </th>
                                        <th scope="col" className="border-0">
                                            Descargar
                                        </th>
                                        {!this.props.company && <th scope="col" className="border-0">
                                            Borrar
                                        </th>}


                                    </tr>
                                    </thead>
                                    <tbody>

                                    {files.map(file => {
                                        if (file.category === 'General') {
                                            return <tr key={file.id}>
                                                <td>{file.name}</td>
                                                <td>{file.created_at}</td>
                                                <td>
                                                    <a href={file.file} target='_blank' className="btn btn-primary"
                                                       rel="noopener noreferrer" download>Descargar</a>
                                                </td>
                                                {!this.props.company && <td>
                                                    <Button theme="danger" className="mb-2 mr-1"
                                                            onClick={() => this.toggle_delete(file.id, file.name)}

                                                    >
                                                        Eliminar
                                                    </Button>
                                                </td>}

                                            </tr>

                                        }
                                        return <div/>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="my-4">
                        <CardHeader>
                            <h6 className="m-0">Archivos REPSE{((this.props.user.admin_access && !this.props.user.read_only) || this.props.company) && <i className="material-icons add-elements"
                               onClick={this.toggle_file}>add</i>}</h6>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Nombre
                                        </th>
                                        <th scope="col" className="border-0">
                                            Fecha de Carga
                                        </th>
                                        <th scope="col" className="border-0">
                                            Descargar
                                        </th>
                                        {!this.props.company && <th scope="col" className="border-0">
                                            Borrar
                                        </th>}
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {files.map(file => {
                                        if (file.category === 'REPSE') {
                                            return <tr key={file.id}>
                                                <td>{file.name}</td>
                                                <td>{file.created_at}</td>
                                                <td><a href={`${file.file}`} target='_blank'
                                                       className="btn btn-primary"
                                                       rel="noopener noreferrer" download>Descargar</a></td>
                                                {!this.props.company && <td>
                                                    <Button theme="danger" className="mb-2 mr-1"
                                                            onClick={() => this.toggle_delete(file.id, file.name)}

                                                    >
                                                        Eliminar
                                                    </Button>
                                                </td>}
                                            </tr>
                                        }
                                        return <div/>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="my-4">
                        <CardHeader>
                            <h6 className="m-0">Archivos SASISOPA{((this.props.user.admin_access && !this.props.user.read_only) || this.props.company) && <i className="material-icons add-elements"
                               onClick={this.toggle_file}>add</i>}</h6>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Nombre
                                        </th>
                                        <th scope="col" className="border-0">
                                            Fecha de Carga
                                        </th>
                                        <th scope="col" className="border-0">
                                            Descargar
                                        </th>
                                        {!this.props.company && <th scope="col" className="border-0">
                                            Borrar
                                        </th>}
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {files.map(file => {
                                        if (file.category === 'SASISOPA') {
                                            return <tr key={file.id}>
                                                <td>{file.name}</td>
                                                <td>{file.created_at}</td>
                                                <td><a href={file.file} target='_blank'
                                                       className="btn btn-primary"
                                                       rel="noopener noreferrer" download>Descargar</a>
                                                </td>
                                                {!this.props.company && <td>
                                                    <Button theme="danger" className="mb-2 mr-1"
                                                            onClick={() => this.toggle_delete(file.id, file.name)}

                                                    >
                                                        Eliminar
                                                    </Button>
                                                </td>}
                                            </tr>
                                        }
                                        return <div/>
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </CardBody>
                    </Card>
                </Col>

                <Modal open={this.state.file_open} toggle={this.toggle_file}>
                    <ModalHeader>Agregar Archivo</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.submit_file}>
                            <Row>
                                <Col lg="12" className="form-group">
                                    <label>Archivo</label>
                                    <FormInput
                                        type="file"
                                        onChange={this.fileChangeHandler}
                                        required
                                    />
                                    <label htmlFor="file_type">Tipo de archivo</label>
                                    <FormSelect
                                        id="name"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        <option value={""}>---</option>
                                        {file_names.map(file => (
                                            <option key={file.id} value={file.id}>{file.category}: {file.name}</option>
                                        ))}

                                    </FormSelect>
                                </Col>
                                <Col lg="12" className="my-4">
                                    <Button type="submit" className="col-lg-12">Agregar Archivo</Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Modal open={this.state.delete_open} toggle={this.toggle_delete}>
                    <ModalHeader>Agregar Archivo</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <h3>Estás seguro de que quieres eliminar el archvio '{this.state.name_to_delete}'</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Col lg="12" className="my-4">
                                    <Button type="submit" className="col-lg-12"
                                            theme="danger" onClick={this.handleDelete}>Eliminar</Button>
                                </Col>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

            </Row>
        )
    }

}

/**
 * Función que mapea el estado de la aplicación al props de un componente de React
 * @function
 * @name mapStateToProps
 * @param {Object} state - Estado de la aplicación
 * @returns {Object} - Props para el componente de React
*/

const mapStateToProps = state => {
    return {
        file_names: state.companies.company_files_multiple_names,
        files: state.companies.company_files_multiple,
        user: state.auth.user,
    }
};

/**
 * Función que mapea las acciones a los props de un componente de React
 * @function
 * @name mapDispatchToProps
 * @param {function} dispatch - Función dispatch para enviar acciones a la store
 * @returns {Object} - Props para el componente de React
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_company_file_names: () => {
            return dispatch(companies.fetch_company_file_names());
        },
        fetch_company_files: (id) => {
            return dispatch(companies.fetch_company_files(id));
        },
        create_company_file_multiple: (data) => {
            return dispatch(companies.create_company_file_multiple(data));
        },
        delete_company_file_multiple: (id, company_id) => {
            return dispatch(companies.delete_company_file_multiple(id, company_id));
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CompanyFilesMultiple);
