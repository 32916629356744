import React from "react";
import {Nav} from "shards-react";
import UserActions from "./UserActions";

/**
 * Componente que representa una barra de navegación para mostrar las acciones del usuario.
 * @component
 * @return {JSX.Element} Elemento JSX que renderiza la barra de navegación.
*/

export default () => (
  <Nav navbar className="border-left flex-row">
    {/*<Notifications />*/}
    <UserActions />
  </Nav>
);
