import React from "react";

/**
 * Componente para carga de archivos personalizada con estilo Bootstrap
 * @function
 * @name CustomFileUpload
 * @returns {JSX.Element} Elemento JSX que muestra el formulario de carga de archivos personalizado
 * @example
 * import CustomFileUpload from './CustomFileUpload';
 * const App = () => (
 * <div>
 * php
 * Copy code
 * <CustomFileUpload />
 * </div>
 * );
*/

const CustomFileUpload = () => (
  <div className="custom-file mb-3">
    <input type="file" className="custom-file-input" id="customFile2" />
    <label className="custom-file-label" htmlFor="customFile2">
      Choose file...
    </label>
  </div>
);

export default CustomFileUpload;
