import React, {Component} from "react";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import projectApp from "./reducers";
import {Provider} from "react-redux";
import Routes from "./components/Routes";
import {loadUser} from "./actions/auth";

import "./App.css"
// import "./assets/ng_font/styles.css"
/**
 * Crea una instancia de store usando la función createStore de redux y la aplicación projectApp
 * @type {Object}
*/
let store = createStore(projectApp, applyMiddleware(thunk));

/**
 * Componente principal de la aplicación, que carga el usuario al montarse
 * @name App
 * @class
 * @extends Component
*/
class App extends Component {
/**
 * Llama a la acción loadUser() usando el store.dispatch() al montarse el componente
*/
    componentDidMount() {
        store.dispatch(loadUser());
    }
/**
 * Renderiza el componente Provider, que provee el store creado para la aplicación y la ruta principal de la aplicación
 * @returns {JSX.Element}
*/
    render () {
        return (
            <Provider store={store}>
                <Routes />
            </Provider>
        )
    }
}

export default App;
