import React, { Component } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormCheckbox,
  FormInput,
  FormSelect,
  FormTextarea,
  Row,
} from "shards-react";

import { connect } from "react-redux";
import { personnel } from "../actions";
import PersonnelFiles from "../components/personnel/Files";
import NamedFiles from "../components/personnel/NamedFiles";

/**
 * @name PersonnelDetail
 * La clase PersonnelDetail es un componente que se encarga de mostrar y editar detalles de personal.
 * Se encarga de obtener y actualizar información de un empleado.
 * @extends Component
 */

class PersonnelDetail extends Component {
  /**
   * Crea una instancia de PersonnelDetail.
   * @param {object} props - Propiedades pasadas al componente.
   */
  constructor(props) {
    super(props);

    this.state = {
      first_names: "",
      last_names: "",
      accreditation_number: "",
      rfc: "",
      ssn: "",
      role: "",
      zone: "",
      address: "",
      name: "",
      id: null,
      phone: "",
      email: "",
      status: false,
      activeEdit: false,
      profile_picture: "",
      upload_profile_picture: "",
      accreditation_date: "",
      created_at: "",
      company: "",
      validity: "",
      active: "",
      naturgy_id: "",
      typeUser: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  /**
   * Se ejecuta después de que el componente es montado en el DOM.
   * Obtiene la información del personal y las opciones de selección de archivos.
   */
  componentDidMount() {
    this.props.fetch_roles();
    this.props.fetch_zones();
    this.props.fetch_companies();
    this.props.fetch_personnel_file_choices();
    this.props.fetch_personnel_detail(this.props.match.params.id).then(() => {
      this.setState({
        first_names: this.props.personnel_detail.first_names,
        last_names: this.props.personnel_detail.last_names,
        accreditation_number: this.props.personnel_detail.accreditation_number,
        rfc: this.props.personnel_detail.rfc,
        ssn: this.props.personnel_detail.ssn,
        role: this.props.personnel_detail.role,
        zone: this.props.personnel_detail.zone,
        address: this.props.personnel_detail.address,
        name: this.props.personnel_detail.name,
        id: this.props.personnel_detail.id,
        phone: this.props.personnel_detail.phone,
        email: this.props.personnel_detail.email,
        status: this.props.personnel_detail.status,
        profile_picture: this.props.personnel_detail.profile_picture,
        accreditation_date: this.props.personnel_detail.accreditation_date,
        company: this.props.personnel_detail.company,
        validity: this.props.personnel_detail.accreditation_expiration_date,
        naturgy_id: this.props.personnel_detail.naturgy_id,
        active: this.props.personnel_detail.active,
        typeUser: this.props.user.groups,
      });
    });
  }
  /**
   * Maneja los cambios en los campos del formulario.
   * @param {object} event - Evento del cambio en el campo del formulario.
   */
  handleChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  }
  /**
   * Función para manejar la casilla de verificación
   */
  handleCheckbox() {
    if (this.state.activeEdit) {
      this.setState({
        active: !this.state.active,
      });
    }
  }
  /**
   * Función para alternar la activación de la edición
   * @param {object} event - Evento de click del mouse
   */
  toggleActive(event) {
    event.preventDefault();
    this.setState({
      activeEdit: !this.state.activeEdit,
    });
  }
  /**
   * Función para manejar el cambio de archivo
   * @param {object} e - Evento de cambio de archivo
   */
  fileChangeHandler = (e) => {
    this.setState({
      upload_profile_picture: e.target.files[0],
    });
  };

  /**
   * Función para manejar el envío del formulario
   * @param {object} event - Evento de envío del formulario
   */
  handleSubmit(event) {
    event.preventDefault();
    const data = {
      first_names: this.state.first_names,
      last_names: this.state.last_names,
      rfc: this.state.rfc,
      ssn: this.state.ssn,
      role: this.state.role,
      zone: this.state.zone,
      address: this.state.address,
      phone: this.state.phone,
      email: this.state.email,
      status: this.state.status,
      accreditation_date: this.state.accreditation_date,
      accreditation_number: this.state.accreditation_number,
      profile_picture: this.state.upload_profile_picture,
      company: this.state.company,
      active: this.state.active,
      naturgy_id: this.state.naturgy_id,
    };
    this.props
      .update_personnel_detail(data, this.props.match.params.id)
      .then(() => {
        this.setState({
          activeEdit: false,
        });
      });
  }

  handleViewCredential(id, profile_picture) {
    if (profile_picture !== null) {
      window.location.href = `/personnel/credential/${id}`;
    } else {
      alert("Los datos del personal estan incompletos");
    }
  }
  /**
   * Función para renderizar la interfaz de usuario
   */
  render() {
    let disable_validity = true;
    let groups = ["naturgy", "certifier"];
    let intersections = groups.filter(
      (e) => this.props.user.groups.indexOf(e) !== -1
    );
    if (intersections.length > 0) {
      disable_validity = !this.state.activeEdit;
    }
    let id = this.props.match.params.id;
    let { personnel_detail } = this.props;
    let profile_picture = null;
    if (personnel_detail !== null) {
      // profile_picture = personnel_detail.profile_picture;
      if (personnel_detail.profile_picture !== null) {
        profile_picture = new URL(personnel_detail.profile_picture).pathname;
      }
    }
    return (
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="my-3">
              <CardHeader>
                <h6 className="m-0">
                  Detalles del Técnico{" "}
                  {!this.props.user.read_only && (
                    <i
                      className="material-icons add-elements"
                      onClick={this.toggleActive}
                    >
                      edit
                    </i>
                  )}
                </h6>
              </CardHeader>

              <CardBody>
                <Row className="mb-3">
                  <Col md="6" className="image square">
                    <img
                      src={profile_picture}
                      alt="profile"
                      className="profile-img"
                    />
                  </Col>
                  <Col md="6" className="">
                    <Button
                      onClick={() =>
                        this.handleViewCredential(id, profile_picture)
                      }
                    >
                      ver credencial
                    </Button>
                  </Col>
                </Row>
                <Form
                  onSubmit={this.handleSubmit}
                  className="personnel-detail-form"
                >
                  <Row
                    className="pb-5"
                    style={{ justifyContent: "right", display: "flex" }}
                    hidden={!this.state.activeEdit}
                  >
                    <Col>
                      <label>Foto de perfil</label>
                      <FormInput
                        type="file"
                        onChange={this.fileChangeHandler}
                        disabled={!this.state.activeEdit}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="first-name">Nombre(s)</label>
                      <FormInput
                        id="first-name"
                        size="sm"
                        type="text"
                        name="first_names"
                        value={this.state.first_names}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="last-name">Apellido(s)</label>
                      <FormInput
                        id="last-name"
                        size="sm"
                        type="text"
                        name="last_names"
                        value={this.state.last_names}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                        className="upper"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="">Fecha de Acreditación</label>
                      <FormInput
                        size="sm"
                        type="date"
                        name="accreditation_date"
                        onChange={this.handleChange}
                        value={this.state.accreditation_date}
                        disabled={
                          disable_validity ||
                          this.state.typeUser.includes("company")
                        }
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="">Vigencia</label>
                      <FormInput
                        size="sm"
                        type="date"
                        name="validity"
                        value={this.state.validity}
                        disabled
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <label htmlFor="direccion">Dirección</label>
                      <FormTextarea
                        id="direccion"
                        size="sm"
                        type="text"
                        name="address"
                        value={this.state.address}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="role">Perfil</label>
                      <FormSelect
                        id="role"
                        type="text"
                        name="role"
                        value={this.state.role}
                        disabled={
                          !this.state.activeEdit ||
                          this.state.typeUser.includes("company")
                        }
                        onChange={this.handleChange}
                        required
                      >
                        {this.props.roles.map((role, index) => (
                          <option value={role.id} key={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="rfc">R.F.C.</label>
                      <FormInput
                        id="rfc"
                        size="sm"
                        type="text"
                        pattern="[A-Za-z]{4}[0-9]{6}"
                        placeholder="R.F.C. sin homoclave"
                        name="rfc"
                        value={this.state.rfc}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                        className="upper"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="telefono">Teléfono</label>
                      <FormInput
                        id="telefono"
                        size="sm"
                        type="tel"
                        pattern="[0-9]{10}"
                        name="phone"
                        placeholder="lada + teléfono (10 dígitos)"
                        value={this.state.phone}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <label htmlFor="zone">Empresa Colaboradora</label>
                      <FormSelect
                        id="company"
                        type="text"
                        name="company"
                        value={this.state.company}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                        required
                      >
                        {this.props.companies.map((company) => (
                          <option value={company.id} key={company.id}>
                            {company.company_name}
                          </option>
                        ))}
                      </FormSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="ssn">N.S.S.</label>
                      <FormInput
                        id="ssn"
                        size="sm"
                        type="text"
                        name="ssn"
                        value={this.state.ssn}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                        className="upper"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="accreditation_number">
                        Número de Acreditación
                      </label>
                      <FormInput
                        id="accreditation_number"
                        size="sm"
                        type="text"
                        name="accreditation_number"
                        value={this.state.accreditation_number}
                        disabled={
                          !this.state.activeEdit ||
                          this.state.typeUser.includes("company")
                        }
                        onChange={this.handleChange}
                        className="upper"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="email">Correo electrónico</label>
                      <FormInput
                        id="email"
                        size="sm"
                        type="email"
                        name="email"
                        value={this.state.email}
                        disabled={!this.state.activeEdit}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="email">ID 900</label>
                      <FormInput
                        id="naturgy_id"
                        size="sm"
                        type="text"
                        name="naturgy_id"
                        value={this.state.naturgy_id}
                        disabled={
                          !this.state.activeEdit ||
                          this.state.typeUser.includes("company")
                        }
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  {true && (
                    <Row>
                      <Col md="6" className="mb-3">
                        <label htmlFor="email">Personal Activo</label>

                        <FormCheckbox
                          checked={this.state.active}
                          onChange={
                            !this.state.typeUser.includes("company")
                              ? this.handleCheckbox
                              : null
                          }
                          disabled={
                            !this.state.activeEdit ||
                            this.state.typeUser.includes("company")
                          }
                        ></FormCheckbox>
                      </Col>
                    </Row>
                  )}

                  <Row className="pt-3" hidden={!this.state.activeEdit}>
                    <Button className="w-100" type="submit">
                      Actualizar
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <PersonnelFiles personnel_id={id} />
          </Col>
          <Col lg="12">
            <NamedFiles personnel_id={id} />
          </Col>
        </Row>
      </Container>
    );
  }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    personnel_detail: state.personnel.personnel_detail,
    roles: state.personnel.roles,
    zones: state.personnel.zones,
    companies: state.personnel.companies,
    user: state.auth.user,
    personnel_file_choices: state.personnel.personnel_file_choices,
  };
};

/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_personnel_detail: (id) => {
      return dispatch(personnel.fetch_personnel_detail(id));
    },
    update_personnel_detail: (data, id) => {
      return dispatch(personnel.update_personnel_detail(data, id));
    },
    fetch_roles: () => {
      return dispatch(personnel.fetch_roles());
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
    fetch_companies: () => {
      return dispatch(personnel.fetch_companies());
    },
    fetch_personnel_file_choices: () => {
      return dispatch(personnel.fetch_personnel_file_choices());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelDetail);
