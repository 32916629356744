import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {Link} from "react-router-dom";
import {Button} from "shards-react";
import CustomTable from "../CustomTable";

/**
 * Componente de React que muestra una tabla con el personal de una empresa.
 * @class
 * @extends Component
 * @property {Array} personnel - Array con la información del personal de la empresa.
*/
class CompanyPersonnel extends Component {
/**
 * Método que se encarga de renderizar el componente.
 * @returns {JSX.Element} Componente de React que muestra la tabla con el personal de la empresa.
*/

    render() {
        const {personnel} = this.props;
        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };
        const personnel_header = [
            {title: 'Estatus', prop: 'status'},
            {title: 'Nombre', prop: 'name', filterable: true},
            {title: 'Rol', prop: 'role', filterable: true},
            {title: 'Empresa', prop: 'company', filterable: true},
            {title: 'Ver', prop: 'view'},
        ];
        let personnel_table_items = personnel.map((person, id) => ({
            name: person.name,
            status: <i className={`material-icons label-success tl-${person.accreditation_flag}`}>brightness_1</i>,
            role: person.role_name,
            company: person.company_name,
            view: <Link to={`/personnel/${person.id}`}><Button className="m-0">Ver</Button></Link>,
            // razonsocial: customer.company_name,
            // contract_id: customer.contract_id,
            // ver: <Link to={`/clientes/${customer.id}`}><Button>Ver</Button></Link>
        }));

        return (
            <div className="table-responsive">
                <CustomTable
                    tableHeaders={personnel_header}
                    tableBody={personnel_table_items}
                    tableClass="table-striped hover responsive"
                    rowsPerPage={10}
                    initialSort={{prop: 'name', isAscending: true}}
                    labels={customLabels}
                />
            </div>
        )
    }

}

export default CompanyPersonnel;
