/**
 * loadUser carga los datos del usuario actual en el sistema a través de una petición HTTP
 * @function
 * @async
 * @returns {Promise<Object>} Retorna una promesa que se resuelve en un objeto con el estado de la respuesta y los datos del usuario cargado si la respuesta es satisfactoria.
 * @throws {Error} Lanza un error en caso de que la respuesta del servidor no sea satisfactoria.
 */

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: "USER_LOADING" });
    // dispatch({type: "USER_LOADED"});

    const token = getState().auth.token;

    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch("/api/auth/user/", { headers })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          //console.log("Server Error!");
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("200");
          dispatch({ type: "USER_LOADED", user: res.data });
          return res.data;
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 401
        ) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "AUTHENTICATION_ERROR", data: null });
          throw res.data;
        }
      });
  };
};

/**
 * login realiza la petición HTTP para iniciar sesión en el sistema con las credenciales proporcionadas
 * @function
 * @async
 * @param {string} username - Nombre de usuario para iniciar sesión.
 * @param {string} password - Contraseña del usuario para iniciar sesión.
 * @returns {Promise<Object>} Retorna una promesa que se resuelve en un objeto con el estado de la respuesta y los datos de la respuesta si la petición es satisfactoria.
 * @throws {Error} Lanza un error en caso de que la respuesta del servidor no sea satisfactoria.
 */

export const login = (username, password) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let body = JSON.stringify({ username, password });

    return fetch("/api/auth/login/", { headers, body, method: "POST" })
      .then((res) => {
        if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: "LOGIN_SUCCESSFUL", data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        } else {
          dispatch({ type: "LOGIN_FAILED", data: res.data });
          throw res.data;
        }
      });
  };
};

/**
 * logout realiza la petición HTTP para cerrar la sesión del usuario actualmente conectado.
 * @function
 * @async
 * @returns {Promise<Object>} Retorna una promesa que se resuelve en un objeto con el estado de la respuesta y los datos de la respuesta si la petición es satisfactoria.
 * @throws {Error} Lanza un error en caso de que la respuesta del servidor no sea satisfactoria.
 */

export const logout = () => {
  return (dispatch, getState) => {
    console.log("LOGOUT");
    let headers = { "Content-Type": "application/json" };

    const token = getState().auth.token;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch("/api/auth/logout/", { headers, body: "", method: "POST" })
      .then((res) => {
        if (res.status === 204) {
          return { status: res.status, data: {} };
        } else if (res.status < 500) {
          return res.json().then((data) => {
            return { status: res.status, data };
          });
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then((res) => {
        console.log("LOGOUT", res);
        if (res.status === 204) {
          dispatch({ type: "LOGOUT_SUCCESSFUL" });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
          throw res.data;
        }
      });
  };
};
