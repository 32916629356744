import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

/**
 * Componente de ruta privada que verifica si el usuario está autenticado antes de renderizar el componente especificado.
 * @name PrivateRoute
 * @param {Object} props - Propiedades del componente.
 * @param {React.Component} props.component - Componente a renderizar.
 * @param {Object} props.auth - Información de autenticación del usuario.
 * @param {boolean} props.auth.isLoading - Indica si se está cargando la información de autenticación del usuario.
 * @param {boolean} props.auth.isAuthenticated - Indica si el usuario está autenticado.
 * @param {Object} rest - Resto de las propiedades que se pasarán a la ruta.
 * @returns {React.Component} - Componente de ruta privada.
*/

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <h2>Loading...</h2>;
            } else if (!auth.isAuthenticated) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { originPath: props.match.path }
                        }}
                    />
                );
            } else {
                return <Component {...props} />;
            }
        }}
    />
);

/**
 * Función que mapea las propiedades del estado de autenticación a las propiedades del componente.
 * @param {Object} state - Estado de la aplicación.
 * @param {Object} state.auth - Información de autenticación del usuario.
 * @returns {Object} - Propiedades del componente.
*/

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
