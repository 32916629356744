/**
 * Estado inicial del módulo de cotizaciones.
 * @typedef {Object} initialState
 * @property {Array} quotations - Arreglo de cotizaciones.
 * @property {Array} all_pricing - Arreglo de todos los precios.
 * @property {Object} quotation_detail - Detalles de la cotización.
 * @property {Object} pricing_detail - Detalles de los precios.
 * @property {number} quotations_current_page - Página actual de cotizaciones.
*/

const initialState = {
    quotations: [],
    all_pricing: [],
    quotation_detail: null,
    pricing_detail: null,
    quotations_current_page: null,
};

/**
 * Reducer de cotizaciones.
 * @param {initialState} state - Estado actual del módulo de cotizaciones.
 * @param {Object} action - Objeto de acción que contiene un type y una carga útil.
 * @returns {initialState} - Nuevo estado del módulo de cotizaciones.
*/

export default function quote(state = initialState, action) {
    switch (action.type) {

        case 'FETCH_QUOTATIONS':
            return {...state, quotations: action.quotations};

        case 'CREATE_QUOTATION':
            return {...state, quotations: [action.quotation, ...state.quotations]};

        case 'FETCH_QUOTATION':
            return {...state, quotation_detail: action.quotation};

        case 'UPDATE_QUOTATION':
            console.log('UPDATE');

            return {...state, quotation_detail: action.quotation};

        case 'FETCH_PRICING':
            return {...state, pricing_detail: action.pricing};

        case 'FETCH_ALL_PRICING':
            return {...state, all_pricing: action.pricing};

        default:
            return state;
    }
}