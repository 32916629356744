import axios from 'axios';

/**
 * fetch_files función que realiza una solicitud para obtener los archivos relacionados a una persona especificada por ID.
 * @param {number} personnel_id - El ID de la persona a la que se le solicitan los archivos.
 * @returns {Promise} - Una promesa que resuelve con los archivos obtenidos o rechaza con un error.
*/

export const fetch_files = (personnel_id) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/files/?personnel=${personnel_id}`, {headers}).then(res => {
            // console.log("RES DATA");
            // console.log(res.data);
            // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
            return dispatch({type: 'FETCH_FILES', files: res.data})
        }).catch(err => {
            console.log("Error fetching zones");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * uploadFile función que realiza una solicitud para subir un archivo a una persona especificada.
 * @param {Object} data - Objeto que contiene la información necesaria para subir un archivo.
 * @param {File} data.file - Archivo que se va a subir.
 * @param {string} data.file_name - El nombre que se le asignará al archivo.
 * @param {number} data.personnel - El ID de la persona a la que se le va a subir el archivo.
 * @param {string} data.type - El tipo de archivo que se va a subir.
 * @returns {Promise} - Una promesa que resuelve con el archivo subido o rechaza con un error.
*/

export const uploadFile = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();
        let extension = data.file.name.split('.').pop();
        body.append('file', data.file);
        body.append('file_name', `${data.file_name}.${extension}`);
        body.append('personnel', data.personnel);
        body.append('type', data.type);

        return axios.post(`/api/files/?personnel=${data.personnel}`, body,{headers}).then(res => {
            return dispatch({type: 'UPLOAD_FILE', file: res.data})
        }).catch(err => {
            console.log("Error uploading file");
            console.log(err);
            console.log(err.response.status);
        })
    }
};