import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormInput,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "shards-react";
import CustomTable from "../CustomTable";

import {companies} from "../../actions";

/**
 * Componente que muestra una lista de contactos y permite agregar nuevos contactos.
 * @extends Component
*/
class Contacts extends Component {
/**
 * Constructor del componente. Inicializa el estado del componente.
 * @param {Object} props - Propiedades pasadas al componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            name: "",
            phone: "",
            email: "",
            position: "",
            id_900: "",
        }

        this.toggle_file = this.toggle_file.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }
/**
 * Maneja el evento de envío del formulario de agregar contacto.
 * @param {Object} event - Evento de envío del formulario.
*/
    submit(event) {
        event.preventDefault();
        console.log('SUBMIT', this.state);
        const data = {
            'name': this.state.name,
            'phone': this.state.phone,
            'email': this.state.email,
            'positon': this.state.position,
            'id_900': this.state.id_900,
        }
        console.log('ID', this.props.company_id)
        this.props.create_contact(this.props.company_id, data);
    }

    clear_all = () => {
        this.setState({
            open: false,
            name: "",
            file: "",
        })
    };

    toggle_file = () => {
        this.setState({
            open: !this.state.open
        });
    };
/**
 * Maneja el cambio en los campos de entrada y actualiza el estado correspondiente.
 * @param {Object} event - Evento de cambio de un campo de entrada.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }


    render() {
        const {contacts} = this.props;
        // console.log('FILES COMPONENT', this.props)
        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'No hay resultados',
            filterPlaceholder: 'Buscar',
        };
        const header = [
            {name: 'Nombre', prop: 'name', filterable: true},
            {name: 'Teléfono', prop: 'phone', filterable: true},
            {name: 'Correo', prop: 'email', filterable: true},
            {name: 'Puesto', prop: 'position', filterable: true},
            {name: 'Número 900', prop: 'id_900', filterable: true},
        ];
        let table_items = contacts.map((contact, id) => ({
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            position: contact.position,
            id_900: contact.id_900,
            // edit: <Button>Editar</Button>
        }));
        console.log('HELLO', this.props.company_id)
        return (
            <div>
                <Card className="my-4">
                    <CardHeader>
                        <h6 className="m-0">Contactos
                            {((this.props.user.admin_access && !this.props.user.read_only) || this.props.company) && <i className="material-icons add-elements"
                               onClick={this.toggle_file}>add</i>}

                        </h6>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive">
                            <CustomTable
                                tableHeaders={header}
                                tableBody={table_items}
                                tableClass="table-striped hover responsive"
                                rowsPerPage={10}
                                initialSort={{prop: 'name', isAscending: true}}
                                labels={customLabels}
                            />
                        </div>

                    </CardBody>
                </Card>
                <Modal open={this.state.open} toggle={this.toggle_file}>
                    <ModalHeader>Agregar Contacto</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.submit}>
                            <Row>
                                <Col lg="12" className="form-group">
                                    <label>Nombre</label>
                                    <FormInput
                                        type="text"
                                        value={this.state.name}
                                        onChange={(e) => this.setState({name: e.target.value})}
                                        required
                                    />
                                </Col>
                                <Col lg="12" className="form-group">
                                    <label>Teléfono</label>
                                    <FormInput
                                        type="text"
                                        value={this.state.phone}
                                        onChange={(e) => this.setState({phone: e.target.value})}
                                        required
                                    />
                                </Col>
                                <Col lg="12" className="form-group">
                                    <label>Correo</label>
                                    <FormInput
                                        type="text"
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        required
                                    />
                                </Col>
                                <Col lg="12" className="form-group">
                                    <label>Puesto</label>
                                    <FormInput
                                        type="text"
                                        value={this.state.position}
                                        onChange={(e) => this.setState({position: e.target.value})}
                                        required
                                    />
                                </Col>
                                <Col lg="12" className="form-group">
                                    <label>Número 900</label>
                                    <FormInput
                                        type="text"
                                        value={this.state.id_900}
                                        onChange={(e) => this.setState({id_900: e.target.value})}
                                    />
                                </Col>
                                <Col lg="12" className="my-4">
                                    <Button type="submit" className="col-lg-12">Agregar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        create_contact: (id, data) => {
            return dispatch(companies.create_contact(id, data));
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
