import React, {Component} from "react";
import {connect} from "react-redux";

import {BrowserRouter as Router} from "react-router-dom";
import routes from "../routes";
import withTracker from "../withTracker";

/**
 * Componente que maneja las rutas de la aplicación usando React Router y un conjunto de rutas definidas en un archivo separado.
 * @name Routes
 * @component
 * @example
 * import Routes from './Routes';
 * function App() {
 * return (
 * <div className="App">
 *  <Routes />
 * </div>
 * );
 * }
 * @returns {JSX.Element} Componente que renderiza las rutas de la aplicación.
*/
class Routes extends Component {
/**
 * Función que se encarga de renderizar el componente de administrador, si el usuario es un administrador.
 * @param {Object} admin_component - Componente de administrador a renderizar.
 * @param {Object} admin_user - Usuario administrador.
*/
    render_admin = (admin_component, admin_user) => {

    };

    render() {
        return (
            <Router>
                <div>
                    {routes.map((route, index) => {
                        return (
                            <route.routeComponent
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                groups={route.groups}
                                component={withTracker(props => {
                                    return (
                                        <route.layout {...props}>
                                            <route.component {...props} />
                                        </route.layout>
                                    );
                                })}
                            />
                        );
                    })}
                </div>
            </Router>
        )
    }
}
/**
 * Función que mapea el estado de la aplicación a props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props que se desean mapear desde el estado.
*/
const mapStateToProps = state => {

    return {
        // user: state.auth.user,
    };
};
/**
 * Función que mapea las acciones que se desean despachar a props del componente.
 * @param {Function} dispatch - Función para despachar acciones.
 * @returns {Object} Objeto con las props que se desean mapear desde las acciones.
*/
const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
/**
 * Componente conectado a Redux que maneja las rutas de la aplicación usando React Router y un conjunto de rutas definidas en un archivo separado.
*/
