import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {files} from '../../actions';
import {Link} from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormInput,
    FormSelect,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "shards-react";
import DownloadButton from "../common/DownloadButton";

/**
 * @class
 * @name PersonnelFiles
 * @classdesc Componente para mostrar y subir archivos relacionados con un usuario de personal.
 */
class PersonnelFiles extends Component {
    /**
     * @constructor
     * @param {Object} props - Propiedades del componente.
     */
    constructor(props) {
        super(props);

        this.state = {
            file_open: false,
            file: "",
            file_name: "",
            file_type: "",
        }

        this.toggle_file = this.toggle_file.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    /**
     * Método para limpiar los estados de los archivos.
     */
    clear_all = () => {
        this.setState({
            file_open: false,
            file_name: "",
            file_type: "",
            file: "",
        })
    };

    /**
     * Método del ciclo de vida del componente, se ejecuta después del primer renderizado.
     */
    componentDidMount() {
        this.props.fetchFiles(this.props.personnel_id);
    }

    /**
     * Método para cambiar el estado de visibilidad de los archivos.
     */
    toggle_file = () => {
        this.setState({
            file_open: !this.state.file_open
        });
    };
    /**
     * Método para subir un archivo al servidor.
     * @param {Object} e - Evento de envío del formulario.
     */
    submit_file = (e) => {
        e.preventDefault();
        let data = {
            file: this.state.file,
            file_name: this.state.file_name,
            personnel: this.props.personnel_id,
            type: this.state.file_type,
        }

        this.props.uploadFile(data).then(this.clear_all());
    };
    /**
     * Método para actualizar el estado del archivo.
     * @param {Object} e - Evento de cambio del archivo.
     */
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

    /**
     * Método para actualizar el estado de los campos del formulario.
     * @param {Object} event - Evento de cambio del campo del formulario.
     */
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    /**
     * Método para renderizar el componente.
     * @returns {JSX.Element}
     */
    render() {
        const {files} = this.props;
        const is_company = this.props.user.groups.includes('company');

        return (
            <div>
                <Card className="my-3">
                    <CardHeader>
                        <h6 className="m-0">Archivos {((this.props.user.admin_access && !this.props.user.read_only) || is_company) &&
                            <i className="material-icons add-elements"
                               onClick={this.toggle_file}>add</i>}</h6>
                    </CardHeader>

                    <CardBody>


                        <div className="table-responsive">
                            <table className="table">
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col" className="border-0">
                                        Nombre
                                    </th>
                                    <th scope="col" className="border-0">
                                        Fecha de Carga
                                    </th>
                                    <th scope="col" className="border-0">
                                        Descargar
                                    </th>

                                </tr>
                                </thead>
                                <tbody>

                                {files.files.map(file =>
                                    <tr key={file.id}>
                                        <td>{file.type}</td>
                                        <td>{file.created_at}</td>
                                        <td><DownloadButton url={`/files/download/${file.id}/`}/></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Modal open={this.state.file_open} toggle={this.toggle_file}>
                    <ModalHeader>Agregar Documento</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.submit_file}>
                            <Row>
                                <Col lg="12" className="form-group">
                                    <label>Archivo</label>
                                    <FormInput
                                        type="file"
                                        onChange={this.fileChangeHandler}
                                        required
                                    />
                                    <label htmlFor="file_type">Tipo de archivo</label>
                                    <FormSelect
                                        id="file_type"
                                        name="file_type"
                                        value={this.state.role}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        <option value={""}>---</option>
                                        <option value={"Identificación oficial"}>Identificación oficial</option>
                                        <option value={"Carta de liberación"}>Carta de liberación</option>
                                        <option value={"Comprobante de domicilio"}>Comprobante de domicilio</option>
                                        <option value={"Otros"}>Otros</option>
                                    </FormSelect>
                                </Col>
                                <Col lg="12" className="my-4">
                                    <Button type="submit" className="col-lg-12">Agregar Archivo</Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

/**
 * Función que mapea el estado de la aplicación a props específicos para el componente PersonnelFiles.
 * @function
 * @param {Object} state - El estado actual de la aplicación.
 * @returns {Object} - Objeto que contiene las props especificadas en la función, que son el listado de archivos y el usuario autenticado.
 */

const mapStateToProps = state => {
    return {
        files: state.files,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones que el componente puede llamar a dispatch de Redux para actualizar el estado de la aplicación.
 * @function
 * @param {function} dispatch - Función de Redux que permite llamar a las acciones.
 * @returns {Object} - Objeto que contiene las funciones que el componente puede llamar para actualizar el estado de la aplicación.
 */
const mapDispatchToProps = dispatch => {
    return {
        fetchFiles: (personnel_id) => {
            dispatch(files.fetch_files(personnel_id));
        },
        uploadFile: (data) => {
            return dispatch(files.uploadFile(data));
        },

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(PersonnelFiles);
