import React from "react";
import {Col, Container, Row} from "shards-react";

/**
 * Componente que representa el diseño de la página de inicio de sesión.
 * @name LoginLayout
 * @param {Object} props - Propiedades del componente.
 * @param {ReactNode} props.children - Elementos hijos que se renderizarán dentro del componente.
 * @returns {JSX.Element} - Elemento JSX que representa el diseño de la página de inicio de sesión.
*/

const LoginLayout = ({ children }) => (
  <Container fluid>
    <Row>
      <Col
        lg={{ size: 12}}
        md={{ size: 12}}
        sm="12"
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>
);


export default LoginLayout;