import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormInput,
    Row,
} from "shards-react";

import { personnel } from "../actions";
import CustomTable from "../components/CustomTable";

/**
 * Componente que representa una interfaz de búsqueda de personal.
 * @name PersonnelSearch
 * @function
 * @returns {JSX.Element} Elemento JSX que muestra la interfaz de búsqueda de personal.
 */
const PersonnelSearch = ({user}) => {
  const [query, setQuery] = useState("");
  const results = useSelector((state) => state.personnel.personnel_search);
  const dispatch = useDispatch();
  /**
   * Función que maneja el evento de clic en el botón de búsqueda y desencadena la acción de búsqueda en la tienda Redux.
   * @function
   * @param {string} query - Cadena de búsqueda introducida por el usuario.
   */
  const handleClick = (query) => {
    dispatch(personnel.fetch_personnel_search(query));
  };
  /**
   * Función que maneja el evento de cambio de valor del campo de búsqueda y actualiza el estado de la cadena de búsqueda.
   * @function
   * @param {Object} e - Objeto de evento que contiene la información del cambio en el campo de búsqueda.
   */
  const handleChange = (e) => {
    setQuery(e.target.value);
  };
  /**
   * Objeto que define las etiquetas personalizadas para el componente CustomTable.
   * @constant
   * @type {Object}
   * @property {string} first - Etiqueta personalizada para el botón "Primera página".
   * @property {string} last - Etiqueta personalizada para el botón "Última página".
   * @property {string} prev - Etiqueta personalizada para el botón "Página anterior".
   * @property {string} next - Etiqueta personalizada para el botón "Página siguiente".
   * @property {string} show - Etiqueta personalizada para la selección del número de elementos por página.
   * @property {string} entries - Etiqueta personalizada para la cantidad de elementos por página.
   * @property {string} noResults - Etiqueta personalizada para el mensaje de falta de resultados.
   * @property {string} filterPlaceholder - Etiqueta personalizada para el marcador de posición del campo de filtro.
   */
  const customLabels = {
    first: "<<",
    last: ">>",
    prev: "<",
    next: ">",
    show: "Display",
    entries: "rows",
    noResults: "No hay resultados",
    filterPlaceholder: "Filtrar resultados",
  };
  const personnel_header = [
    { title: "Estatus", prop: "status" },
    { title: "Nombre", prop: "name", filterable: true },
    { title: "Empresa", prop: "company_name", filterable: true },
    { title: "Rol", prop: "role_definition", filterable: true },
    { title: "Ver", prop: "view" },
  ];
  let personnel_table_items = results.map((person, id) => ({
    name: person.name,
    status: (
      <i
        className={`material-icons label-success tl-${person.accreditation_flag}`}
      >
        brightness_1
      </i>
    ),
    company_name: person.company_name,
    role_definition: person.role_definition,
    view: (
      <Link to={user.groups.includes('comercial') ? `/personnel/credential/${person.id}` : `/personnel/${person.id}`}>
        <Button className="m-0">Ver</Button>
      </Link>
    ),
  }));

  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Card className="my-3">
            <CardHeader>
              <h6 className="m-0">Búsqueda de Personal</h6>
            </CardHeader>
            <CardBody style={{ display: "flex", flwxFlow: "row" }}>
              <FormInput
                type="text"
                placeholder="Buscar"
                value={query}
                onChange={handleChange}
                style={{ margin: "0 0.5rem" }}
              />
              <Button
                onClick={() => handleClick(query)}
                disabled={query.length === 0}
              >
                <i
                  className="material-icons mr-1"
                  style={{ display: "inline" }}
                >
                  search
                </i>
                Buscar
              </Button>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="table-responsive">
                <CustomTable
                  tableHeaders={personnel_header}
                  tableBody={personnel_table_items}
                  tableClass="table-striped hover responsive"
                  rowsPerPage={10}
                  initialSort={{ prop: "username", isAscending: true }}
                  labels={customLabels}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(PersonnelSearch);
