import React, { Component } from "react";
import { connect } from "react-redux";
import { FormCheckbox } from "shards-react";

import { personnel } from "../../actions";

/**
 * Componente que representa un checkbox para la acreditación del personal.
 * @name AccreditationCheckbox
 * @class
 * @extends Component
 */
// const Login = () => (
class AccreditationCheckbox extends Component {
  /**
   * Crea una instancia del componente AccreditationCheckbox.
   * @constructor
   * @param {Object} props - Las props que recibe el componente.
   * @param {Object} props.personnel_accreditation - Acreditación del personal.
   * @param {function} props.update_personnel_accreditations - Función para actualizar las acreditaciones del personal.
   */
  constructor(props) {
    super(props);
    /**
     * Estado inicial del componente.
     * @property {boolean} checked - Valor actual del checkbox.
     * @property {boolean} pastChecked - Valor anterior del checkbox.
     */
    this.state = {
      checked: false,
      pastChecked: false,
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  /**
   * Maneja el evento onChange del checkbox.
   * Actualiza el estado del checkbox y llama a la función para actualizar las acreditaciones del personal.
   * @param {Object} event - Evento del checkbox.
   */
  handleCheckbox(event) {
    let checked = !this.state.checked;
    this.props
      .update_personnel_accreditations(
        this.props.personnel_accreditation.id,
        checked
      )
      .then(() => {
        this.setState({ checked });
      });
  }
  /**
   * Ciclo de vida del componente.
   * Se ejecuta después de que el componente se haya montado.
   * Actualiza el estado del componente con la acreditación del personal recibida por props.
   */
  componentDidMount() {
    this.setState({
      checked: this.props.personnel_accreditation.status,
      pastChecked: this.props.personnel_accreditation.status,
    });
  }
  /**
   * Renderiza el componente.
   * Si la acreditación es editable, se muestra el checkbox editable.
   * Si no es editable, se muestra el checkbox desactivado con el valor anterior.
   * @returns {JSX.Element} - Componente renderizado.
   */

  render() {
    if (this.props.personnel_accreditation.editable) {
      return (
        <FormCheckbox
          checked={this.state.checked}
          onChange={this.handleCheckbox}
          disabled={this.props.disableAll}
        />
      );
    } else {
      return <FormCheckbox checked={this.state.pastChecked} disabled />;
    }
  }
}
/**
 * Convierte las props del estado de la aplicación en props del componente.
 * @param {Object} state - Estado de la aplicación.
 * @returns {Object} - Props del componente.
 */
const mapStateToProps = (state) => {
  return {};
};
/**
 * Convierte las acciones de la aplicación en props del componente.
 * @param {function} dispatch - Acciones de la aplicación.
 * @returns {Object} - Props del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    update_personnel_accreditations: (id, status) => {
      return dispatch(personnel.update_personnel_accreditations(id, status));
    },
  };
};

/**
 * Conecta el componente con el estado y las acciones de la aplicación.
 */

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccreditationCheckbox);
