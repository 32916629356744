import React from "react";
import { Nav } from "shards-react";

import { connect } from "react-redux";
import { Store } from "../../../flux";
import SidebarNavItem from "./SidebarNavItem";

/**
 * Representa el componente SidebarNavItems, que es un componente de clase de React. Este componente renderiza una lista de elementos de navegación para la barra lateral.
 * @name SidebarNavItems
 * Este componente es conectado al store de Flux para obtener los elementos de la barra lateral. Además, los elementos se filtran en función de los grupos de usuario y luego se renderizan a través de SidebarNavItem.
 * @component
 * @param {Object} user - Un objeto que representa al usuario actual, obtenido del estado de autenticación.
 * @returns {JSX.Element} Retorna un JSX.Element que representa la lista de elementos de navegación en la barra lateral.
 * @requires React
 * @requires PropTypes
 * @requires connect
 * @requires classNames
 * @requires {Store} from "../../../flux";
 * @requires SidebarNavItem
 * @requires {Object} Constants - Un objeto que contiene constantes de acción que se utilizan en el manejador de cambios.
 */

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    if (props.user.groups.includes("comercial")) {
      this.state = {
        navItems: Store.getSidebarItems().filter(
          (e) =>
            e.title === "Inicio" ||
            e.title === "Búsqueda de Personal" ||
            e.title === "Personal"
        ),
      };
    } else {
      this.state = {
        navItems: Store.getSidebarItems(),
      };
    }

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    if (this.props.user.groups.includes("comercial")) {
      this.setState({
        ...this.state,
        navItems: Store.getSidebarItems().filter(
          (e) =>
            e.title === "Inicio" ||
            e.title === "Búsqueda de Personal" ||
            e.title === "Personal"
        ),
      });
    } else {
      this.setState({
        ...this.state,
        navItems: Store.getSidebarItems(),
      });
    }
  }

  render() {
    const { navItems: items } = this.state;
    const groups = this.props.user.groups;

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            let has_group = false;
            let intersections = item.groups.filter(
              (e) => groups.indexOf(e) !== -1
            );
            if (item.groups.length === 0 || intersections.length > 0) {
              has_group = true;
            }
            return has_group ? <SidebarNavItem key={idx} item={item} /> : null;
          })}
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);
