import React from "react";
import { Redirect, Route } from "react-router-dom";

import PrivateRoute from "./components/common/PrivateRoute";

import { DefaultLayout } from "./layouts";

import GroupRoute from "./components/common/GroupRoute";
import LoginLayout from "./layouts/Login";
import Public from "./layouts/Public";
import AdministrativeActs from "./views/AdministrativeActs";
import Announcements from "./views/Announcements";
import Companies from "./views/Companies";
import Company from "./views/Company";
import ComponentsOverview from "./views/ComponentsOverview";
import Home from "./views/Home";
import Login from "./views/Login";
import NonAccordance from "./views/NonAccordance";
import Personnel from "./views/Personnel";
import PersonnelAccreditation from "./views/PersonnelAccreditation";
import PersonnelDetail from "./views/PersonnelDetail";
import PersonnelDetailPublic from "./views/PersonnelDetailPublic";
import PersonnelDetailPublic2 from "./views/PersonnelDetailPublic2";
import PersonnelSearch from "./views/PersonnelSearch";
import PricingDetail from "./views/PricingDetail";
import QuotationCreate from "./views/QuotationCreate";
import QuotationDetail from "./views/QuotationDetail";
import QuotationList from "./views/QuotationList";
import MyDocument from "./views/ReportCreate";
import ReportDownload from "./views/ReportDownload";
import Reports from "./views/Reports";
import Reports2 from "./views/Reports2";
import Vetoes from "./views/Vetoes";

/**
 * Configuración de rutas para el sistema de gestión.
 * @type {Object[]}
 * @property {string} path - La ruta de la página.
 * @property {boolean} [exact=false] - Determina si la ruta debe coincidir exactamente con la URL.
 * @property {React.ComponentType} layout - El componente de diseño que se utilizará para la página.
 * @property {React.ComponentType} component - El componente que se utilizará para renderizar la página.
 * @property {React.ComponentType} [routeComponent=Route] - El componente de ruta que se utilizará para envolver la página.
 * @property {string[]} [groups=[]] - Los grupos a los que pertenece la página.                               Si no se especifica ningún grupo, la página será visible para todos los usuarios.
 */

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/inicio" />,
    routeComponent: PrivateRoute,
    groups: [""],
  },
  {
    path: "/login",
    exact: true,
    layout: LoginLayout,
    component: Login,
    routeComponent: Route,
    groups: [""],
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
    routeComponent: PrivateRoute,
  },
  {
    path: "/create-quotation",
    layout: DefaultLayout,
    component: QuotationCreate,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/inicio",
    layout: DefaultLayout,
    component: Home,
    routeComponent: PrivateRoute,
    groups: [""],
  },
  {
    path: "/quotations/:id",
    exact: true,
    layout: DefaultLayout,
    component: QuotationDetail,
    groups: ["naturgy", "company"],
    routeComponent: GroupRoute,
  },
  {
    path: "/quotations",
    exact: true,
    layout: DefaultLayout,
    component: QuotationList,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/pricing",
    exact: true,
    layout: DefaultLayout,
    component: PricingDetail,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/report-download",
    exact: true,
    layout: DefaultLayout,
    component: ReportDownload,
    routeComponent: GroupRoute,
    groups: ["naturgy"],
  },
  {
    path: "/vetos",
    exact: true,
    layout: DefaultLayout,
    component: Vetoes,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/no_conformidades",
    exact: true,
    layout: DefaultLayout,
    component: NonAccordance,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/anuncios",
    exact: true,
    layout: DefaultLayout,
    component: Announcements,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/actas_administrativas",
    exact: true,
    layout: DefaultLayout,
    component: AdministrativeActs,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/report-create",
    exact: true,
    layout: DefaultLayout,
    component: MyDocument,
    routeComponent: GroupRoute,
    groups: ["naturgy"],
  },
  {
    path: "/personnel-accreditation",
    exact: true,
    layout: DefaultLayout,
    component: PersonnelAccreditation,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company"],
  },
  {
    path: "/personnel/:id",
    exact: true,
    layout: DefaultLayout,
    component: PersonnelDetail,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company", "certifier", "comercial"],
  },
  {
    path: "/personnel/credential/:id",
    exact: true,
    layout: DefaultLayout,
    component: PersonnelDetailPublic2,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company", "certifier", "comercial"],
  },
  {
    path: "/credential/validation/:id",
    exact: true,
    layout: Public,
    component: PersonnelDetailPublic,
    routeComponent: Route,
    groups: [],
  },
  {
    path: "/personnel",
    exact: true,
    layout: DefaultLayout,
    component: Personnel,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company", "certifier", "comercial"],
  },
  {
    path: "/companies",
    exact: true,
    layout: DefaultLayout,
    component: Companies,
    routeComponent: GroupRoute,
    groups: ["naturgy", "company", "certifier"],
  },
  {
    path: "/companies/:id",
    exact: true,
    layout: DefaultLayout,
    component: Company,
    routeComponent: GroupRoute,
    groups: ["naturgy"],
  },
  {
    path: "/reports",
    layout: DefaultLayout,
    component: Reports,
    routeComponent: GroupRoute,
    groups: ["hide"],
  },
  {
    path: "/reportes",
    layout: DefaultLayout,
    component: Reports2,
    routeComponent: GroupRoute,
    groups: [],
  },
  {
    path: "/search",
    layout: DefaultLayout,
    component: PersonnelSearch,
    routeComponent: GroupRoute,
    groups: [],
  },
];
