import React from "react";
import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink} from "shards-react";

import {auth} from "../../../../actions";
import {connect} from "react-redux";

/**
 * Componente que muestra las acciones de usuario como un menú desplegable.
 * @name UserActions
 * @component
 * @example
 * import UserActions from "./UserActions";
 * <UserActions />
*/

class UserActions extends React.Component {
/**
 * Crea un UserActions.
 * @constructor
 * @param {Object} props - Las propiedades pasadas al componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggleUserActions = this.toggleUserActions.bind(this);
    }
/**
 * Alternar la visibilidad de las acciones de usuario.
 * 
 * @memberof UserActions
 * @inner
 */
    toggleUserActions() {
        this.setState({
            visible: !this.state.visible
        });
    }
/**
 * Renderiza el componente.
 * 
 * @returns {JSX.Element} El componente UserActions.
 * @memberof UserActions
 * @inner
 */
    render() {
        return (
            <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
                <DropdownToggle caret tag={NavLink} className="text-nowrap px-3 user-menu">
                    <span className="d-md-inline-block user-menu">{this.props.user.username}</span>
                </DropdownToggle>
                <Collapse tag={DropdownMenu} right small open={this.state.visible}>
                    <DropdownItem className="text-danger" onClick={this.props.logout}>
                        <i className="material-icons text-danger">&#xE879;</i> Logout
                    </DropdownItem>
                </Collapse>
            </NavItem>
        );
    }
}

/**
 * Función que mapea las propiedades de la store a las propiedades del componente.
 * @param {Object} state - El estado actual de la store.
 * @returns {Object} Las propiedades que se van a pasar al componente.
*/
const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};
/**
 * Función que mapea los métodos de la store a las propiedades del componente.
 * @param {Function} dispatch - El método dispatch de la store.
 * @returns {Object} Los métodos que se van a pasar al componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(auth.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions)