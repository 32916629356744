import React from "react";
import {
    Filter,
    Pagination,
    PaginationOpts,
    shouldTableUpdate,
    TableBody,
    TableHeader,
    useDatatableLifecycle
} from "react-bs-datatable";

/**
 * Componente CustomTable.
 * Es un componente de tabla personalizable que utiliza una serie de componentes personalizados
 * para ofrecer una solución escalable y fácil de usar.
 * @component
 * @category UI
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.data - Los datos que se mostrarán en la tabla.
 * @param {Array} props.rowsPerPageOption - Las opciones de paginación disponibles.
 * @param {Array} props.tableHeaders - Las cabeceras de la tabla.
 * @param {Function} props.onChangeFilter - Función que se llama cuando se cambia el filtro.
 * @param {Function} props.onPageNavigate - Función que se llama cuando se cambia de página.
 * @param {Object} props.classes - Clases CSS personalizadas para el componente.
 * @param {Function} props.onRowsPerPageChange - Función que se llama cuando se cambia la cantidad de filas por página.
 * @param {Function} props.onSortChange - Función que se llama cuando se cambia el orden de la tabla.
 * @param {string} props.tableClass - Clases CSS personalizadas para la tabla.
 * @param {Object} props.labels - Textos personalizados para el componente.
 * @param {boolean} props.filterable - Indica si la tabla es filtrable.
 * @param {string} props.filterText - El texto del filtro.
 * @param {number} props.rowsPerPage - La cantidad de filas que se muestran por página.
 * @param {number} props.currentPage - La página actual.
 * @param {string} props.sortedProp - La propiedad por la cual se ordena la tabla.
 * @param {number} props.maxPage - El número máximo de páginas.
 * @param {Object} props.Components - Componentes personalizados que se utilizarán en la tabla.
 * @returns {JSX.Element} Elemento JSX que representa el componente CustomTable.
*/

const CustomTable = React.memo(props => {
  const {
    data,
    rowsPerPageOption,
    tableHeaders,
    onChangeFilter,
    onPageNavigate,
    classes,
    onRowsPerPageChange,
    onSortChange,
    tableClass,
    labels,
    filterable,
    filterText,
    rowsPerPage,
    currentPage,
    sortedProp,
    maxPage,
    Components
  } = useDatatableLifecycle(props);

  return (
    <>
      <Components.Row className="controlRow__root">
        <Components.Col xs="12">
          <Filter
            classes="My Class"
            tableHeaders={tableHeaders}
            placeholder={labels.filterPlaceholder}
            onChangeFilter={onChangeFilter}
            filterText={filterText}
            filterable={filterable}
            components={{
              Adornment: Components.Adornment,
              Button: Components.Button,
              ClearIcon: Components.ClearIcon,
              FormControl: Components.FormControl,
              InputGroup: Components.InputGroup
            }}
          />
        </Components.Col>
      </Components.Row>
      <Components.Row>
        <Components.Col xs="12">
          <Components.Table className={tableClass}>
            <TableHeader
              classes={classes}
              tableHeaders={tableHeaders}
              sortedProp={sortedProp}
              onSortChange={onSortChange}
              components={{
                TableHead: Components.TableHead,
                TableCell: Components.TableCell,
                TableRow: Components.TableRow
              }}
            />
            <TableBody
              classes={classes}
              tableHeaders={tableHeaders}
              labels={labels}
              data={data}
              components={{
                TableBody: Components.TableBody,
                TableCell: Components.TableCell,
                TableRow: Components.TableRow
              }}
            />
          </Components.Table>
        </Components.Col>
      </Components.Row>
      <Components.Row className="controlRow__root bottom">
        <Components.Col xs={12} />
        <Components.Col xs={12}>
          <PaginationOpts
            classes={classes}
            labels={labels}
            onRowsPerPageChange={onRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOption={rowsPerPageOption}
            components={{
              Form: Components.Form,
              FormGroup: Components.FormGroup,
              FormControl: Components.FormControl
            }}
          />
        </Components.Col>
        <Components.Col xs={12} md={4} className="text-right">
          <Pagination
            classes={classes}
            data={data}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            onPageNavigate={onPageNavigate}
            labels={labels}
            maxPage={maxPage}
            components={{
              Button: Components.Button,
              ButtonGroup: Components.ButtonGroup
            }}
          />
        </Components.Col>
      </Components.Row>
    </>
  );
}, shouldTableUpdate);

export default CustomTable;