import axios from "axios";

/**
 * La funcion fetch_companies realiza una petición GET a la API de empresas.
 * @param {number} page - Número de página que se desea obtener.
 * @returns {Promise} Promesa que resuelve con los datos obtenidos de la API.
 * @throws {Error} Error en caso de fallo en la petición.
 *
 */
export const fetch_companies = (page) => {
  return (dispatch, getState) => {
    if (!page) page = 1;
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/companies/`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_COMPANIES_C", data: res.data });
      })
      .catch((err) => {
        console.log("Error fetching vetoes");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * La fetch_company realiza una solicitud GET para obtener información sobre una compañía específica, utilizando el ID proporcionado.
 * @param {number} id - ID de la compañía para obtener información.
 * @returns {Promise} - Devuelve una promesa que resuelve con los datos de la compañía obtenidos de la solicitud.
 * @throws {Error} - Devuelve un error si la solicitud no se realiza correctamente.
 */

export const fetch_company = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(`/api/companies/${id}/`, { headers })
      .then((response) => response.json())
      .then((data) => {
        return dispatch({ type: "FETCH_COMPANY", data: data });
      })
      .catch((err) => {
        console.log("Error fetching");
        console.log("Error", err);
      });
  };
};

/**
 * create_company que crea una nueva empresa en el servidor
 * @function create_company
 * @param {Object} data - Objeto que contiene los datos de la empresa a crear
 * @param {string} data.name - Nombre de la empresa
 * @param {string} data.address - Dirección de la empresa
 * @param {string} data.phone - Número de teléfono de la empresa
 * @param {string} data.email - Dirección de correo electrónico de la empresa
 * @param {string} data.website - Sitio web de la empresa
 * @returns {Promise} - Promesa que se resuelve con los datos de la empresa creada o se rechaza con un error
 * @throws {Error} - Error que se produce al intentar crear la empresa
 */

export const create_company = (data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    /**
     * Realiza una petición POST al servidor para crear una nueva empresa
     * @function axios.post
     * @param {string} url - URL del endpoint para crear empresas
     * @param {Object} data - Objeto que contiene los datos de la empresa a crear
     * @param {Object} headers - Cabeceras HTTP de la petición
     * @returns {Promise} - Promesa que se resuelve con los datos de la empresa creada o se rechaza con un error
     * @throws {Error} - Error que se produce al intentar crear la empresa
     */

    return axios
      .post(`/api/companies/`, data, { headers })
      .then((res) => {
        /**
         * Acción que se dispara cuando se crea una nueva empresa
         * @function dispatch
         * @param {Object} action - Objeto que describe la acción a dispara
         * @param {string} action.type - Tipo de la acción
         * @param {Object} action.data - Datos de la empresa creada
         */
        return dispatch({ type: "CREATE_COMPANY", data: res.data });
      })
      .catch((err) => {
        console.log("Error fetching vetoes");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * create_company_file crea un archivo para una empresa con el id especificado.
 * @param {string} id - El id de la empresa para la cual se va a crear el archivo.
 * @param {object} data - Los datos que contienen el archivo y su nombre.
 * @param {File} data.file - El archivo a cargar.
 * @param {string} data.name - El nombre del archivo.
 * @returns {Promise} Una promesa que se resuelve con los datos del archivo creado o se rechaza con un error.
 */

export const create_company_file = (id, data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    console.log("CREATE COMPANY FILE");
    let body = new FormData();
    body.append("file", data.file);
    body.append("name", data.name);
    return axios
      .post(`/api/v1/company/${id}/files/`, body, { headers })
      .then((res) => {
        return dispatch({ type: "CREATE_COMPANY_FILE", data: res.data });
      })
      .catch((err) => {
        console.log("Error creating company file");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * fetch_company_file_names recupera los nombres de los archivos de la compañía mediante una llamada a la API y los envía a través de una acción 'FETCH_COMPANY_FILE_NAMES' en el store de Redux.
 * @function
 * @async
 * @returns {Promise} - La respuesta de la llamada a la API.
 * @throws {Error} - Si ocurre algún error durante la llamada a la API.
 */

export const fetch_company_file_names = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    /**
     * Hace una llamada GET a la API para recuperar los nombres de los archivos de la compañía.
     * @method
     * @async
     * @param {string} url - La URL de la API para recuperar los nombres de los archivos de la compañía.
     * @param {Object} config - Configuración de la llamada a la API, en este caso, los encabezados de la solicitud.
     * @returns {Promise} - La respuesta de la llamada a la API.
     * @throws {Error} - Si ocurre algún error durante la llamada a la API.
     */

    return axios
      .get(`/api/company_file_names/`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_COMPANY_FILE_NAMES", data: res.data });
      })
      .catch((err) => {
        console.log("Error fetching company file names");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * fetch_company_files para obtener los archivos de una empresa específica.
 * @param {number} id - El id de la empresa a la que se desean obtener los archivos.
 * @returns {Promise} - Promesa que resuelve con un objeto de acción con el tipo "FETCH_COMPANY_FILE_MULTIPLE" y los datos obtenidos en la respuesta del servidor.
 */

export const fetch_company_files = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    /**
     * Realiza una petición GET al servidor para obtener los archivos de la empresa con el id especificado.
     * @function
     * @name axios.get
     * @param {string} url - La URL de la API para obtener los archivos de la empresa con el id especificado.
     * @param {Object} headers - Las cabeceras de la petición HTTP, incluyendo la autorización del token si se encuentra disponible.
     * @returns {Promise} - Promesa que resuelve con los datos obtenidos en la respuesta del servidor.
     */

    return axios
      .get(`/api/company_files/?company_id=${id}`, { headers })
      .then((res) => {
        /**
         * Retorna un objeto de acción con el tipo "FETCH_COMPANY_FILE_MULTIPLE" y los datos obtenidos en la respuesta del servidor.
         * @returns {Object} - Objeto de acción con el tipo "FETCH_COMPANY_FILE_MULTIPLE" y los datos obtenidos en la respuesta del servidor.
         */

        return dispatch({
          type: "FETCH_COMPANY_FILE_MULTIPLE",
          data: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching company file names");
        console.log(err);
        console.log(err.response.status);
      });
  };
};
/**
 * create_company_file_multiple función que crea un archivo para una compañía, recibiendo los datos necesarios.
 * @param {Object} data - Objeto con los datos necesarios para crear el archivo de la compañía.
 * @param {File} data.file - Archivo que se desea subir.
 * @param {String} data.name - Nombre del archivo que se desea subir.
 * @param {String} data.company - Nombre de la compañía para la cual se desea subir el archivo.
 * @returns {Function} - Retorna una función que realiza una petición POST al servidor y retorna una promesa.
 */

export const create_company_file_multiple = (data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    console.log("CREATE COMPANY FILE MULTIPLE");
    let body = new FormData();
    body.append("file", data.file);
    body.append("name", data.name);
    body.append("company", data.company);

    /**
     * Realiza una petición POST al servidor para crear un archivo para una compañía.
     * @async
     * @function
     * @returns {Promise} - Retorna una promesa con los datos del archivo creado.
     */

    return axios
      .post(`/api/company_files/`, body, { headers })
      .then((res) => {
        return dispatch({
          type: "CREATE_COMPANY_FILE_MULTIPLE",
          data: res.data,
        });
      })
      .catch((err) => {
        console.log("Error creating company file multiple");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * delete_company_file_multiple elimina un archivo de la empresa y devuelve la acción correspondiente
 * @function
 * @param {string} id - El ID del archivo a eliminar
 * @param {string} company_id - El ID de la empresa a la que pertenece el archivo
 * @returns {Function} - La función que se ejecutará para eliminar el archivo de la empresa
 * @throws {Error} - Si hay un error al eliminar el archivo de la empresa
 */

export const delete_company_file_multiple = (id, company_id) => {
  /**
   * @function
   * @param {Function} dispatch - El método utilizado para enviar la acción al store
   * @param {Function} getState - El método utilizado para obtener el estado actual del store
   * @returns {Promise} - La promesa que se ejecuta para eliminar el archivo de la empresa
   */

  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return axios
      .delete(`/api/company_files/${id}/?company_id=${company_id}`, { headers })
      .then((res) => {
        return dispatch({
          type: "DELETE_COMPANY_FILE_MULTIPLE",
          data: res.data,
        });
      })
      .catch((err) => {
        console.log("Error deleting company file multiple");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * fetch_company_data esta función realiza una solicitud GET a la API para obtener los datos de una empresa específica.
 * @param {number} id - El identificador único de la empresa para la que se solicitan los datos.
 * @return {function} - Devuelve una función que se puede ejecutar con el método dispatch y getState para obtener el estado actual de la aplicación.
 */

export const fetch_company_data = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    /**
     * Realiza la solicitud GET a la API para obtener los datos de la empresa.
     * @param {string} url - La URL de la API para obtener los datos de la empresa.
     * @param {object} headers - Los encabezados de la solicitud HTTP que incluyen el token de autorización.
     * @return {Promise} - Devuelve una promesa que se resuelve con los datos de la empresa si la solicitud se realiza con éxito.
     */

    return axios
      .get(`/api/company_data/${id}/`, { headers })
      .then((res) => {
        /**
         * Envía una acción al store de Redux para actualizar los datos de la empresa.
         * @param {string} type - El tipo de acción que se está realizando.
         * @param {object} data - Los datos de la empresa obtenidos de la API.
         * @return {object} - Devuelve un objeto con el tipo de acción y los datos de la empresa.
         */
        return dispatch({ type: "FETCH_COMPANY_DATA", data: res.data });
      })
      .catch((err) => {
        console.log("Error fetching company file names");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * update_company_data actualiza los datos de una empresa.
 * @param {Object} data - Objeto con los datos de la empresa a actualizar.
 * @param {string} id - Id de la empresa a actualizar.
 * @returns {Promise<Object>} - Promesa que resuelve con los datos actualizados de la empresa.
 */

export const update_company_data = (data, id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    // console.log('IN UPDATE', data);
    // console.log('IN UPDATE', data.work_division);
    // console.log('MERGED', data.work_division.join(","))
    let submit_data = { ...data };
    submit_data.work_division = submit_data.work_division.join(",");
    submit_data.business_area = submit_data.business_area.join(",");

    console.log("SENDING", submit_data);

    /**
     * Actualiza los datos de una empresa utilizando el método PATCH de axios.
     * @param {string} url - URL de la API para actualizar los datos de la empresa.
     * @param {Object} data - Objeto con los datos de la empresa a actualizar.
     * @param {Object} headers - Cabeceras HTTP.
     * @returns {Promise<Object>} - Promesa que resuelve con los datos actualizados de la empresa.
     */

    return axios
      .patch(`/api/company_data/${id}/`, submit_data, { headers })
      .then((res) => {
        return dispatch({ type: "UPDATE_COMPANY_DATA", data: res.data });
      })
      .catch((err) => {
        console.log("Error updating company values");
        console.log(err);
        console.log(err.response.status);
        console.log(err.response);
      });
  };
};

/**
 * create_contact que crea un nuevo contacto para una empresa específica.
 * @param {number} id - Identificador de la empresa.
 * @param {Object} data - Datos del contacto a crear.
 * @returns {Promise} Promesa que resuelve con los datos del contacto creado.
 * @throws {Error} Error en caso de fallo en la petición.
 */

export const create_contact = (id, data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    // Se agrega el token de autenticación si está disponible

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    // Se realiza la petición para crear el contacto
    return axios
      .post(`/api/v1/company/${id}/contacts/`, data, { headers })
      .then((res) => {
        // Se envía una acción al store de Redux con los datos del contacto creado
        return dispatch({ type: "CREATE_CONTACT", data: res.data });
      })
      .catch((err) => {
        // En caso de error se muestra un mensaje y se registra el error en la consola
        console.log("Error creating contact");
        console.log(err);
        console.log(err.response.status);
        console.log(err.response);
      });
  };
};
