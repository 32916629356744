import React, {Component} from 'react';

import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormTextarea, Row} from "shards-react";

import {users} from '../actions';
import {connect} from 'react-redux';

/**
 * Clase para la gestión de Anuncios.
 * @class Announcements
 * @extends Component
*/

class Announcements extends Component {
/**
 * Construye una nueva instancia de la clase Announcements.
 * @constructor
 * @param {Object} props - Propiedades de React.
*/
    constructor(props) {
        super(props);
/**
 * Estado inicial de la clase Announcements.
 * @property {string} file - Nombre del archivo adjunto.
 * @property {string} text - Texto del anuncio.
*/
        this.state = {
            file: '',
            text: '',
        }
/**
 * Método que se encarga de manejar el cambio de estado del componente.
 * @method handleChange
 * @param {Event} event - Evento que dispara el cambio.
*/
        this.handleChange = this.handleChange.bind(this);
/**
 * Método que se encarga de manejar el envío del formulario.
 * @method handleSubmit
 * @param {Event} event - Evento que dispara el envío del formulario.
*/
        this.handleSubmit = this.handleSubmit.bind(this);
/**
 * Metodo que se encarga de limpiar el estado del componente.
 * @method cleanup
*/
        this.cleanup = this.cleanup.bind(this);
    }

    componentDidMount() {
        this.props.fetch_announcements();
    }
/**
 * Método que se encarga de limpiar el estado del componente.
 * @method cleanup
*/
    cleanup() {
        this.setState({
            file: '',
            text: '',
        })
    }
/**
 * Método que se encarga de manejar el cambio de estado del componente. 
 * @method handleChange
 * @param {Event} event - Evento que dispara el cambio.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

/**
 * Manejador de cambios del archivo adjunto.
 * @method fileChangeHandler
 * @param {Event} e - Evento que dispara el cambio.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

/**
 * Método que se encarga de manejar el envío del formulario.
 * @method handleSubmit
 * @param {Event} event - Evento que dispara el envío del formulario.
*/
    handleSubmit(event) {
        console.log('HANDLE SUBMIT')
        event.preventDefault();
        console.log('SUBMIT', this.state)
        this.props.create_announcement(this.state).then(this.cleanup())
    }
/**
 * Método para renderizar el componente Announcements.
 * @method render
 * @return {JSX.Element} El componente Announcements.
*/
    render() {
        const {announcements} = this.props;
        console.log('State', this.state);
        console.log(this.props.user);

        return (
            <Container fluid>

                {(this.props.user.admin_access && !this.props.user.read_only) && <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Crear Anuncio</h6>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col lg="12" className="form-group">
                                            <label htmlFor="file_type">Anuncio</label>
                                            <FormTextarea
                                                id="text"
                                                name="text"
                                                value={this.state.text}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <label>Archivo</label>
                                            <FormInput
                                                type="file"
                                                onChange={this.fileChangeHandler}
                                                // value={this.state.file}
                                                required
                                            />
                                        </Col>
                                        <Col lg="12" className="my-4">
                                            <Button type="submit" className="col-lg-12">Crear Anuncio</Button>
                                        </Col>
                                    </Row>
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Listado de Anuncios</h6>
                            </CardHeader>
                            <CardBody>
                                <table className="table">
                                    {announcements.map((veto, index) =>
                                        <tr key={veto.id}>
                                            <td className={'upper'}>{veto.text}</td>
                                            <td><a href={veto.file} target='_blank'
                                                      className="btn btn-primary"
                                                      rel="noopener noreferrer" download>Descargar</a></td>
                                        </tr>
                                    )}

                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }


}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/

const mapStateToProps = state => {
    return {
        announcements: state.users.announcements,
        user: state.auth.user,
    }
};

/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_announcements: () => {
            return dispatch(users.fetch_announcements())
        },
        create_announcement: (data) => {
            return dispatch(users.create_announcement(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcements);