import React, { Component } from "react";
import { Col, Container, Row } from "shards-react";

import { connect } from "react-redux";

import { Carousel } from 'react-responsive-carousel';

import slide1 from "../images/carrousel/1.jpg";
import slide2 from "../images/carrousel/2.jpg";
import slide3 from "../images/carrousel/3.jpg";
import slide4 from "../images/carrousel/4.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import UserCompany from "./UserCompany";

/**
 * La clase Home representa un componente que muestra la página principal de la aplicación. Si el usuario tiene acceso de administrador, se muestra un carrusel de imágenes. Si el usuario no tiene acceso de administrador, se muestra información sobre su empresa.
 * @name Home
 * @extends Component
*/


class Home extends Component {

    render() {
        console.log('In Home', this.props)
        const {user} = this.props;

        console.log("user",user)

        return <>
            <Container fluid className="main-content-container px-4">
                {user.admin_access && <Row className="py-4 justify-content-center">
                    <Col lg={6} className="">
                        <Row>
                            <Col md={12} className="np">
                                <Carousel infiniteLoop={true} showThumbs={false} showStatus={false} autoPlay={true}
                                          interval={5000}
                                          showArrows={true}>
                                    <div>
                                        <img src={slide1} alt="Slide 1"/>
                                    </div>
                                    <div>
                                        <img src={slide2} alt="Slide 2"/>
                                    </div>
                                    <div>
                                        <img src={slide3} alt="Slide 3"/>
                                    </div>
                                    <div>
                                        <img src={slide4} alt="Slide 4"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                }
                {!user.admin_access && <UserCompany company_id={user.company}/>}

            </Container></>;
    }

}
/**
 * Mapea el estado de la aplicación a las propiedades del componente.
 * @param {Object} state - El estado de la aplicación.
 * @returns {Object} Las propiedades del componente.
*/
const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};
/**
 * Mapea las acciones de la aplicación a las propiedades del componente.
 * @param {function} dispatch - La función dispatch de Redux.
 * @returns {Object} Las propiedades del componente.
*/
const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(Home);
