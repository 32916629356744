import React, {Component} from 'react';

import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormTextarea, Row} from "shards-react";

import {users} from '../actions';
import {connect} from 'react-redux';

/**
 * Componente que maneja la vista y funcionalidad de la sección de Vetos.
 * @extends Component
*/
class Vetoes extends Component {
/**
 * Constructor del componente Vetoes.
 * @param {object} props - Propiedades del componente.
*/
    constructor(props) {
        super(props);
/**
 * Estado inicial del componente Vetoes.
 * @property {string} file - Archivo del veto.
 * @property {string} text - Texto del veto.
*/
        this.state = {
            file: '',
            text: '',
        }
/**
 * Vinculación de los métodos que utilizan el objeto this.
*/
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cleanup = this.cleanup.bind(this);
    }
/**
 * Método que se ejecuta después de que el componente haya sido montado en el DOM.
 * Se llama al método fetch_vetoes de las propiedades.
*/
    componentDidMount() {

        this.props.fetch_vetoes();
    }
/**
 * Método que actualiza el estado del componente Vetoes con valores vacíos.
*/
    cleanup() {
        this.setState({
            file: '',
            text: '',
        })
    }
/**
 * Método que actualiza el estado del componente Vetoes al detectar cambios en los elementos del formulario.
 * @param {object} event - Evento del elemento que ha cambiado.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

/**
 * Manejador del cambio de archivo del formulario.
 * @param {object} e - Evento del elemento que ha cambiado.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

/**
 * Manejador del envío del formulario.
 * Se llama al método create_veto de las propiedades y, tras su ejecución, se llama al método cleanup.
 * @param {object} event - Evento del formulario al ser enviado.
*/
    handleSubmit(event) {
        console.log('HANDLE SUBMIT')
        event.preventDefault();
        console.log('SUBMIT', this.state)
        this.props.create_veto(this.state).then(this.cleanup())
    }
/**
 * Método que renderiza el componente Vetoes.
 * @returns {JSX.Element} - Elemento JSX que representa el componente Vetoes.
*/
    render() {
        const {vetoes} = this.props;
        // console.log(vetoes);

        return (
            <Container fluid>

                {(this.props.user.admin_access && !this.props.user.read_only) && <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Crear Veto</h6>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col lg="12" className="form-group">
                                            <label htmlFor="file_type">Anuncio</label>
                                            <FormTextarea
                                                id="text"
                                                name="text"
                                                value={this.state.text}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <label>Archivo</label>
                                            <FormInput
                                                type="file"
                                                onChange={this.fileChangeHandler}
                                                // value={this.state.file}
                                                required
                                            />
                                        </Col>
                                        <Col lg="12" className="my-4">
                                            <Button type="submit" className="col-lg-12">Crear Veto</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Listado de Vetos</h6>
                            </CardHeader>
                            <CardBody>
                                <table className="table">
                                    {vetoes.map((veto, index) =>
                                        <tr key={veto.id}>
                                            <td className={'upper'}>{veto.name}</td>
                                            <td><a href={veto.file} target='_blank'
                                                      className="btn btn-primary"
                                                      rel="noopener noreferrer" download>Descargar</a></td>
                                        </tr>
                                    )}

                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }


}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/
const mapStateToProps = state => {
    return {
        vetoes: state.users.vetoes,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/

const mapDispatchToProps = dispatch => {
    return {
        fetch_vetoes: () => {
            return dispatch(users.fetch_vetoes())
        },
        create_veto: (data) => {
            return dispatch(users.create_veto(data))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Vetoes);