/**
 * Función que devuelve un array con los enlaces de navegación para la aplicación.
 * @returns {Array<Object>} - Un array de objetos que contienen información sobre los enlaces de navegación, como el título, la URL, el icono y los grupos de usuarios permitidos para acceder a cada enlace.
*/

export default function () {
    return [
        {
            title: "Inicio",
            to: "/inicio",
            htmlBefore: '<i class="material-icons">home</i>',
            htmlAfter: "",
            groups: []
        },
        // {
        //     title: "Crear Cotización IRI",
        //     to: "/create-quotation",
        //     htmlBefore: '<i class="material-icons">add</i>',
        //     htmlAfter: "",
        //     groups: ["naturgy", "company"]
        // },
        // {
        //     title: "Cotizaciones",
        //     to: "/quotations",
        //     htmlBefore: '<i class="material-icons">list</i>',
        //     htmlAfter: "",
        //     groups: ["naturgy", "company"]
        // },
        {
            title: "Estado de Personal",
            to: "/personnel-accreditation",
            htmlBefore: '<i class="material-icons">assignment</i>',
            htmlAfter: "",
            groups: ["naturgy", "company", "read_only"]
        },
        {
            title: "Empresas Colaboradoras",
            to: "/companies",
            htmlBefore: '<i class="material-icons">assignment</i>',
            htmlAfter: "",
            groups: ["naturgy", "read_only"]
        },
        {
            title: "Personal",
            to: "/personnel",
            htmlBefore: '<i class="material-icons">assignment</i>',
            htmlAfter: "",
            groups: ["naturgy", "company", "certifier", "read_only"]
        },
        {
            title: "Reportes",
            to: "/reports",
            htmlBefore: '<i class="material-icons">assignment</i>',
            htmlAfter: "",
            groups: ["hide"]
        },
        {
            title: "Búsqueda de Personal",
            to: "/search",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy", "customer_service", "read_only"]
        },
        {
            title: "Vetos",
            to: "/vetos",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy", "customer_service", "company", "read_only"]
        },
        {
            title: "Anuncios",
            to: "/anuncios",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy", "customer_service", "company", "read_only"]
        },
        {
            title: "Actas Administrativas",
            to: "/actas_administrativas",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy", "customer_service", "company", "read_only"]
        },
        {
            title: "No conformidades",
            to: "/no_conformidades",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy", "customer_service", "company", "read_only"]
        },
                {
            title: "Reportes",
            to: "/reportes",
            htmlBefore: '<i class="material-icons">search</i>',
            htmlAfter: "",
            groups: ["naturgy",]
        },
    ];
}
