import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";

import { Store } from "../../../flux";

/**
 * Componente que representa la barra lateral principal de la aplicación.
 * @name MainSidebar
 * Esta barra lateral consta de una barra superior con el logo de la aplicación y un menú de navegación,
 * seguido de una sección de búsqueda y una sección de elementos de navegación.
 * Esta barra lateral también mantiene un estado interno para saber si el menú de navegación está visible o no.
 * @param {Object} props - Las propiedades del componente.
 * @param {boolean} props.hideLogoText - Si se establece en true, se oculta el texto del logo.
 * @returns El componente del sidebar principal.
*/

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }
/**
 * Registra un callback para ser invocado cada vez que cambia el estado de la tienda.
 * @returns {void}
*/
  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }
/**
 * Elimina el callback registrado para ser invocado cada vez que cambia el estado de la tienda.
 * @returns {void}
*/
  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }
/**
 * Callback que se invoca cada vez que cambia el estado de la tienda.
 * @returns {void}
*/
  onChange() {
    this.setState({
      ...this.state,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems()
    });
  }
/**
 * Renderiza el componente.
 * @returns El componente del sidebar principal.
*/
  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <SidebarSearch />
        <SidebarNavItems />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
