import React from "react";
import {
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

/**
 * Componente para mostrar un conjunto de entradas de formulario con características especiales.
 * @name InputGroup
 * @return {JSX.Element} Elemento React que contiene las entradas de formulario.
 * @example
 * <InputGroups />
 * @component
*/

const InputGroups = () => (
  <div>
    <InputGroup className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>@</InputGroupText>
      </InputGroupAddon>
      <FormInput placeholder="Username" />
    </InputGroup>

    <InputGroup className="mb-3">
      <FormInput value="catalin" onChange={() => {}} />
      <InputGroupAddon type="append">
        <InputGroupText>@designrevision.com</InputGroupText>
      </InputGroupAddon>
    </InputGroup>

    <InputGroup className="mb-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>$</InputGroupText>
      </InputGroupAddon>
      <FormInput value="1000" onChange={() => {}} />
      <InputGroupAddon type="append">
        <InputGroupText>.00</InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </div>
);

/**
 * @typedef {Object} InputGroupAddonProps
 * @property {'prepend'|'append'} type Tipo de grupo de entrada.
 * @typedef {Object} InputGroupTextProps
 * @property {string} children Texto a mostrar dentro del grupo de entrada.
 * @typedef {Object} FormInputProps
 * @property {string} value Valor de la entrada de formulario.
 * @property {Function} onChange Función a ejecutar cuando cambia el valor de la entrada de formulario.
 * @typedef {Object} InputGroupProps
 * @property {string} className Clase CSS para dar estilo al grupo de entrada.
 * @property {JSX.Element} children Elementos que se mostrarán dentro del grupo de entrada.
*/

export default InputGroups;
