import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";

/**
 * Representa el título y subtítulo de una página.
 * @name PageTitle
 * @component
 * @param {object} props - Las props del componente.
 * @param {string} props.title - El título de la página.
 * @param {string} props.subtitle - El subtítulo de la página.
 * @param {string} [props.className] - La clase CSS para aplicar al componente.
 * @param {object} [props.attrs] - Otras propiedades a pasar al componente.
 * @returns {JSX.Element}
 * @example
 * <PageTitle title="Título" subtitle="Subtítulo" />
*/

const PageTitle = ({ title, subtitle, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="4" className={classes} { ...attrs }>
      <span className="text-uppercase page-subtitle">{subtitle}</span>
      <h3 className="page-title">{title}</h3>
    </Col>
  )
};

/**
 * Las props que se pueden pasar al componente.
 * @typedef {object} PageTitleProps
 * @property {string} title - El título de la página.
 * @property {string} subtitle - El subtítulo de la página.
 * @property {string} [className] - La clase CSS para aplicar al componente.
*/

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
