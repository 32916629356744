import React, {Component} from "react";
import 'react-dropzone-uploader/dist/styles.css'
import {connect} from "react-redux";
import {personnel} from '../../actions';
import {Link} from "react-router-dom";
import {
    Button, Card, CardBody, CardHeader, Col, Form, FormInput, FormSelect, Modal, ModalBody, ModalHeader, Row
} from "shards-react";
import DownloadButton from "../common/DownloadButton";


/**
 * Componente que muestra una lista de archivos generales y archivos SASISOPA,
 * permite descargarlos y agregar nuevos archivos a la lista.
 * @name NamedFiles
 * @extends Component
 * @param {Object} props - Propiedades del componente.
 * @param {number} props.personnel_id - ID del personal al que se refiere la lista de archivos.
 * @param {Object} props.user - Objeto que contiene información del usuario actual.
 * @param {boolean} props.user.admin_access - Indica si el usuario actual tiene acceso de administrador.
 * @param {boolean} props.user.read_only - Indica si el usuario actual tiene acceso de solo lectura.
 * @param {Array} props.personnel_file_choices - Lista de opciones de archivo.
 * @param {Array} props.personnel_files - Lista de archivos.
 * @param {Function} props.fetch_personnel_file_choices - Función que obtiene las opciones de archivo.
 * @param {Function} props.fetch_personnel_files - Función que obtiene la lista de archivos.
 * @param {Function} props.create_personnel_file - Función que crea un nuevo archivo.
 */

class NamedFiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            file: null, file_open: false, named_file_id: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Obtiene las opciones de archivo y la lista de archivos al montar el componente.
     */
    componentDidMount() {
        this.props.fetch_personnel_file_choices();
        this.props.fetch_personnel_files(this.props.personnel_id);
    }

    /**
     * Alterna el estado de la ventana modal para agregar un archivo.
     */
    toggle_file = () => {
        this.setState({
            file_open: !this.state.file_open
        });
    };
    /**
     * Maneja el evento de cambio de archivo seleccionado.
     * @param {Object} e - Objeto del evento.
     */
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

    /**
     * Maneja el evento de cambio de valor en un campo de entrada.
     * @param {Object} event - Objeto del evento.
     */
    handleChange(event) {
        this.setState({
            ...this.state, [event.target.name]: event.target.value,
        });
    }

    /**
     * Maneja el evento de envío del formulario para agregar un nuevo archivo.
     * @param {Object} e - Objeto del evento.
     */
    submit_file = (e) => {
        e.preventDefault();
        console.log('IN SUBMIT', this.state);
        let data = {
            "file": this.state.file, "name": this.state.named_file_id, "person": this.props.personnel_id,
        }

        this.props.create_personnel_file(data);
    };

    /**
     * Renderiza el componente.
     * @return {JSX.Element} Componente para mostrar la lista de archivos.
     */
    render() {
        let {personnel_file_choices, personnel_files} = this.props;
        const is_company = this.props.user.groups.includes('company');

        return (<div>
            <Row>
                <Col lg={6}>
                    <Card className="my-3">
                        <CardHeader>
                            <h6 className="m-0">Archivos
                                Generales{((this.props.user.admin_access && !this.props.user.read_only) || is_company) &&
                                    <i className="material-icons add-elements"
                                       onClick={this.toggle_file}>add</i>}</h6>
                        </CardHeader>

                        <CardBody>


                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Nombre
                                        </th>
                                        <th scope="col" className="border-0">
                                            Fecha de Carga
                                        </th>
                                        <th scope="col" className="border-0">
                                            Descargar
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody>

                                    {personnel_files.map(file => {
                                        if (file.category === 'General') {
                                            return <tr key={file.id}>
                                                <td>{file.name} {file.category}</td>
                                                <td>{file.created_at}</td>
                                                <td><DownloadButton url={`/files/personnel/download/${file.id}/`}/></td>
                                            </tr>
                                        }
                                        return <div/>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card className="my-3">
                        <CardHeader>
                            <h6 className="m-0">Archivos
                                SASISOPA{((this.props.user.admin_access && !this.props.user.read_only) || is_company) &&
                                    <i className="material-icons add-elements"
                                       onClick={this.toggle_file}>add</i>}</h6>
                        </CardHeader>

                        <CardBody>


                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">
                                            Nombre
                                        </th>
                                        <th scope="col" className="border-0">
                                            Fecha de Carga
                                        </th>
                                        <th scope="col" className="border-0">
                                            Descargar
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody>

                                    {personnel_files.map(file => {
                                        if (file.category === 'SASISOPA') {
                                            return <tr key={file.id}>
                                                <td>{file.name} {file.category}</td>
                                                <td>{file.created_at}</td>
                                                <td><DownloadButton url={`/files/personnel/download/${file.id}/`}/></td>
                                            </tr>
                                        }
                                        return <div/>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


            <Modal open={this.state.file_open} toggle={this.toggle_file}>
                <ModalHeader>Agregar Documento</ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.submit_file}>
                        <Row>
                            <Col lg="12" className="form-group">
                                <label>Archivo</label>
                                <FormInput
                                    type="file"
                                    onChange={this.fileChangeHandler}
                                    required
                                />
                                <label htmlFor="file_type">Tipo de archivo</label>
                                <FormSelect
                                    id="file_named_file_idtype"
                                    name="named_file_id"
                                    value={this.state.named_file_id}
                                    onChange={this.handleChange}
                                    required
                                >
                                    <option>---</option>
                                    {this.props.personnel_file_choices.map((file, index) => <option value={file.id}
                                                                                                    key={file.id}>{file.category}: {file.name}</option>)}
                                </FormSelect>
                            </Col>
                            <Col lg="12" className="my-4">
                                <Button type="submit" className="col-lg-12">Agregar Archivo</Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>)
    }

}

/**
 * Esta función es utilizada como mapeador de estado y propiedades de acción para el componente NamedFiles.
 * @function
 * @name mapStateToProps
 * @param {object} state - El estado actual de la aplicación.
 * @returns {object} - Devuelve un objeto con las propiedades personnel_file_choices, personnel_files y user del estado.
 */
const mapStateToProps = state => {
    return {
        personnel_file_choices: state.personnel.personnel_file_choices,
        personnel_files: state.personnel.personnel_files,
        user: state.auth.user,
    }
};
/**
 * Esta función es utilizada como mapeador de despacho de acciones para el componente NamedFiles.
 * @function
 * @name mapDispatchToProps
 * @param {function} dispatch - La función de despacho de Redux.
 * @returns {object} - Devuelve un objeto con las acciones fetch_personnel_file_choices, fetch_personnel_files y create_personnel_file.
 */
const mapDispatchToProps = dispatch => {
    return {
        fetch_personnel_file_choices: () => {
            return dispatch(personnel.fetch_personnel_file_choices())
        }, fetch_personnel_files: (id) => {
            return dispatch(personnel.fetch_personnel_files(id))
        }, create_personnel_file: (data) => {
            return dispatch(personnel.create_personnel_file(data))
        },
    }
};

/**
 * Este es el componente exportado que utiliza los mapeadores de estado y despacho de acciones.
 * @exports NamedFiles
 */

export default connect(mapStateToProps, mapDispatchToProps)(NamedFiles);
