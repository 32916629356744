/**
 * Este objeto representa el estado inicial de la aplicación.
 * @typedef {Object} initialState
 * @property {Object} personnelAccreditation - Los datos de acreditación del personal.
 * @property {Array} personnelAccreditation.results - Los resultados de la acreditación del personal.
 * @property {string} personnelAccreditation.next - El enlace a la siguiente página de resultados de acreditación del personal.
 * @property {string} personnelAccreditation.previous - El enlace a la página anterior de resultados de acreditación del personal.
 * @property {number} personnelAccreditation.page - El número de la página actual de resultados de acreditación del personal.
 * @property {number} personnelAccreditation.pages - El número total de páginas de resultados de acreditación del personal.
 * @property {number} personnelAccreditation.count - El número total de resultados de acreditación del personal.
 * @property {Array} personnel - Los datos del personal.
 * @property {Object} personnel_detail - Los detalles del personal.
 * @property {boolean} loading_personnel_detail - Indica si los detalles del personal están siendo cargados.
 * @property {Object} personnel_accreditation_detail - Los detalles de acreditación del personal.
 * @property {Array} roles - Los roles disponibles.
 * @property {Array} zones - Las zonas disponibles.
 * @property {Array} companies - Las empresas disponibles.
 * @property {Array} personnel_search - Los resultados de la búsqueda de personal.
 * @property {Array} personnel_file_choices - Las opciones de los archivos del personal.
 * @property {Array} personnel_files - Los archivos del personal.
*/

const initialState = {
    personnelAccreditation: {
        results: [],
        next: null,
        previous: null,
        page: null,
        pages: null,
        count: null,
    },
    personnel: [],
    personnel_detail: null,
    loading_personnel_detail: true,
    personnel_accreditation_detail: null,
    roles: [],
    zones: [],
    companies: [],
    personnel_search: [],
    personnel_file_choices: [],
    personnel_files: [],
};

/**
 * Esta función maneja las acciones de la aplicación y actualiza el estado de la aplicación.
 * @function quote
 * @param {Object} state - El estado actual de la aplicación.
 * @param {Object} action - La acción que se debe realizar.
 * @returns {Object} El nuevo estado de la aplicación.
*/

export default function quote(state = initialState, action) {
    switch (action.type) {

        case 'FETCH_PERSONNEL_ACCREDITATION':
            return {...state, personnelAccreditation: {...action.personnelAccreditation}};

        case 'FETCH_PERSONNEL':
            return {...state, personnel: action.personnel};

        case 'CREATE_PERSONNEL':
            return {...state, personnel: [action.personnel, ...state.personnel]}

        case 'UPDATE_PERSONNEL_ACREDITATION':
            return state

        case 'FETCH_PERSONNEL_DETAIL':
            return {...state, personnel_detail: action.personnel_detail, loading_personnel_detail: false}

        case 'UPDATE_PERSONNEL_DETAIL':
            return {...state, personnel_detail: action.personnel_detail}

        case 'FETCH_PERSONNEL_SEARCH':
            return {...state, personnel_search: action.personnel_search}

        case 'FETCH_PERSONNEL_ACCREDITATION_DETAIL':
            return {...state, personnel_accreditation_detail: action.personnel_accreditation_detail}

        case 'FETCH_ROLES':
            return {...state, roles: action.roles};

        case 'FETCH_ZONES':
            return {...state, zones: action.zones};

        case 'FETCH_COMPANIES':
            return {...state, companies: action.companies};

        case 'FETCH_PERSONNEL_FILE_CHOICES':
            return {...state, personnel_file_choices: action.data};
        case 'FETCH_PERSONNEL_FILES':
            return {...state, personnel_files: action.data};
        case 'CREATE_PERSONNEL_FILE':
            return {...state, personnel_files: [action.data, ...state.personnel_files]}
        default:
            return state;
    }
}