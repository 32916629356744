import React from "react";
import PropTypes from "prop-types";
import {Navbar, NavbarBrand} from "shards-react";

import {Dispatcher, Constants} from "../../../flux";

/**
 * Componente que representa la barra de navegación principal de la barra lateral.
 * @name SidebarMainNavbar
 * @component
 * @param {Object} props Propiedades del componente.
 * @param {boolean} props.hideLogoText Indica si se debe ocultar el texto del logo, o no.
 * @returns {JSX.Element} Elemento JSX que renderiza la barra de navegación principal de la barra lateral.
*/

class SidebarMainNavbar extends React.Component {
    constructor(props) {
/**
 * Crea una instancia de SidebarMainNavbar.
 * @param {Object} props Propiedades del componente.
*/
        super(props);

        this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
    }
/**
 * Manejador del evento clic del botón para mostrar/ocultar la barra lateral.
*/
    handleToggleSidebar() {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR
        });
    }
/**
 * Renderiza la barra de navegación principal de la barra lateral.
 * @returns {JSX.Element} Elemento JSX que renderiza la barra de navegación principal de la barra lateral.
*/
    render() {
        const {hideLogoText} = this.props;
        return (
            <div className="main-navbar">
                <Navbar
                    className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
                    type="light"
                >
                    <NavbarBrand
                        className="w-100 mr-0"
                        href="#"
                        style={{lineHeight: "25px"}}
                    >
                        <div className="d-table m-auto">
                            {!hideLogoText && (
                                <span className="d-none d-md-inline ml-1">
                                Canal Acerca - Personal
                                </span>
                                )}
                        </div>
                    </NavbarBrand>
                    <a
                        className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                        onClick={this.handleToggleSidebar}
                    >
                        <i className="material-icons">&#xE5C4;</i>
                    </a>
                </Navbar>
            </div>
        );
    }
}

SidebarMainNavbar.propTypes = {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
    hideLogoText: false
};

export default SidebarMainNavbar;
