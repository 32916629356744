import axios from "axios";

/**
 * Recupera una lista de vetos.
 * @name fetch_vetoes
 * @param {number} page - Número de página de resultados a recuperar.
 * @returns {Function} - Función que realiza una petición GET al endpoint '/api/v1/vetoes/' y devuelve una promesa.
 * @throws {Error} - Si ocurre un error en la petición, se lanza una excepción con información detallada.
*/

export const fetch_vetoes = (page) => {
    return (dispatch, getState) => {
        if (!page) page=1
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v1/vetoes/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_VETOES', vetoes: res.data})
        }).catch(err => {
            console.log("Error fetching vetoes");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Crea un nuevo veto.
 * @name create_veto
 * @param {Object} data - Datos necesarios para crear el veto.
 * @param {string} data.text - El texto del veto.
 * @param {File} data.file - El archivo del veto.
 * @returns {function} Una función que realiza la creación del veto.
*/

export const create_veto = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
        console.log('HERE', data);
        let body = new FormData();
        body.append('name', data.text)
        body.append('file', data.file)

        return axios.post(`/api/v1/vetoes/`, body, {headers}).then(res => {
            return dispatch({type: 'CREATE_VETO', data: res.data})
        }).catch(err => {
            console.log("Error creating veto");
            console.log(err);
            console.log(err.response);
            console.log(err.response.status);
        })
    }
};

/**
 * @name fetch_administrative_acts
 * @description Función que realiza una petición GET a la API para obtener los actos administrativos.
 * @param {number} page - Número de página de la lista de actos administrativos a obtener.
 * @returns {Promise} Promesa que resuelve con los datos de los actos administrativos obtenidos.
*/

export const fetch_administrative_acts = (page) => {
    return (dispatch, getState) => {
        if (!page) page=1
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v1/administrative_acts/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_ADMINISTRATIVE_ACTS', data: res.data})
        }).catch(err => {
            console.log("Error fetching vetoes");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener los anuncios
 * @name fetch_announcements
 * @param {number} page - Página de resultados que se quiere obtener
 * @returns {Promise} Promesa que se resuelve con un objeto que contiene la acción a despachar
*/

export const fetch_announcements = (page) => {
    return (dispatch, getState) => {
        if (!page) page=1
        // Se definen los encabezados de la petición
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        // Si hay token, se agrega al encabezado
        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v1/announcements/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_ANNOUNCEMENTS', data: res.data})
        }).catch(err => {
            console.log("Error fetching vetoes");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición GET a la API para obtener los elementos sin acuerdo
 * @name fetch_non_accordance
 * @param {number} page - Página de resultados que se quiere obtener
 * @returns {Promise} Promesa que se resuelve con un objeto que contiene la acción a despachar
*/

export const fetch_non_accordance = (page) => {
    return (dispatch, getState) => {
        if (!page) page=1
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return axios.get(`/api/v1/non_accordance/`, {headers}).then(res => {
            return dispatch({type: 'FETCH_NON_ACCORDANCE', data: res.data})
        }).catch(err => {
            console.log("Error fetching vetoes");
            console.log(err);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición POST a la API para crear un anuncio
 * @name create_announcement
 * @param {Object} data - Datos del anuncio a crear
 * @param {string} data.text - Texto del anuncio
 * @param {File} data.file - Archivo asociado al anuncio (opcional)
 * @returns {Promise} Promesa que se resuelve con un objeto que contiene la acción a despachar
*/

export const create_announcement = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;
// Si hay token, se agrega al encabezado
        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
// Se crea un objeto FormData para enviar los datos del anuncio
        let body = new FormData();
        body.append('text', data.text)
        body.append('file', data.file)

        return axios.post(`/api/v1/announcements/`, body, {headers}).then(res => {
            return dispatch({type: 'CREATE_ANNOUNCEMENT', data: res.data})
        }).catch(err => {
            console.log("Error creating announcement");
            console.log(err);
            console.log(err.response);
            console.log(err.response.status);
        })
    }
};

/**
 * Función que realiza una petición POST a la API para crear un elemento sin acuerdo
 * @name create_non_accordance
 * @param {Object} data - Datos del elemento sin acuerdo a crear
 * @param {string} data.description - Descripción del elemento sin acuerdo
 * @param {string} data.company - Empresa relacionada con el elemento sin acuerdo
 * @param {File} data.file - Archivo asociado al elemento sin acuerdo (opcional)
 * @returns {Promise} Promesa que se resuelve con un objeto que contiene la acción a despachar
*/

export const create_non_accordance = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }
// Se crea un objeto FormData para enviar los datos del elemento sin acuerdo
        let body = new FormData();
        body.append('description', data.description)
        body.append('company', data.company)
        body.append('file', data.file)

        return axios.post(`/api/v1/non_accordance/`, body, {headers}).then(res => {
            return dispatch({type: 'CREATE_NON_ACCORDANCE', data: res.data})
        }).catch(err => {
            console.log("Error creating non accordance");
            console.log(err);
            console.log(err.response);
            console.log(err.response.status);
        })
    }
};

/**
 * Crea un acto administrativo.
 * @name create_administrative_act
 * @param {Object} data - Los datos del acto administrativo.
 * @param {string} data.text - El texto del acto administrativo.
 * @param {string} data.company - La empresa del acto administrativo.
 * @param {File} data.file - El archivo adjunto del acto administrativo.
 * @returns {Promise} Promesa que resuelve en un objeto que indica si la creación fue exitosa.
 * @throws {Error} Si hay algún error al crear el acto administrativo.
*/

export const create_administrative_act = (data) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};
        let {token} = getState().auth;

        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = new FormData();
        body.append('text', data.text)
        body.append('company', data.company)
        body.append('file', data.file)

        return axios.post(`/api/v1/administrative_acts/`, body, {headers}).then(res => {
            return dispatch({type: 'CREATE_ADMINISTRATIVE_ACT', data: res.data})
        }).catch(err => {
            console.log("Error creating administrative act");
            console.log(err);
            console.log(err.response);
            console.log(err.response.status);
        })
    }
};