import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

/**
 * Un componente de ruta que verifica si el usuario está autenticado y pertenece a los grupos especificados
 * @name GroupRoute
 * @param {Object} props - Propiedades del componente
 * @param {React.Component} props.component - El componente que se va a renderizar en la ruta
 * @param {Object} props.auth - Información de autenticación del usuario
 * @param {boolean} props.auth.isLoading - Indica si la autenticación está en proceso
 * @param {boolean} props.auth.isAuthenticated - Indica si el usuario está autenticado
 * @param {Array<string>} props.auth.user.groups - Grupos a los que pertenece el usuario autenticado
 * @param {Array<string>} props.groups - Grupos permitidos para acceder a la ruta
 * @param {Object} rest - Propiedades adicionales para el componente Route de react-router
 * @returns {React.Component} - El componente de ruta con la verificación de autenticación y grupos
*/

const GroupRoute = ({component: Component, auth, groups, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading) {
                return <h2>Loading...</h2>;
            } else if (auth.isAuthenticated) {
                let intersections = groups.filter(e => auth.user.groups.indexOf(e) !== -1);

                if (groups.length === 0 || intersections.length > 0) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {originPath: props.match.path}
                            }}
                        />
                    );
                }

            } else {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {originPath: props.match.path}
                        }}
                    />
                );
            }
        }}
    />
);

/**
 * Mapea el estado de Redux a las propiedades del componente
 * @param {Object} state - Estado de Redux
 * @param {Object} state.auth - Información de autenticación del usuario
 * @returns {Object} - Propiedades del componente mapeadas desde el estado de Redux
*/

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(GroupRoute);