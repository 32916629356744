import React, {Component} from "react";
import {Card, CardBody, CardHeader, Col, Container, Row} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * @class Reports2
 * @extends Component
 * Componente encargado de renderizar la sección de reportes del sistema.
*/
class Reports2 extends Component {
/**
 * Función encargada de renderizar el contenido del componente.
 * @function render
 * @returns {JSX.Element} Contenido a renderizar.
*/
    render() {
        console.log(this.props.customer2_reports)
        let container = <><Container fluid className="main-content-container px-4">
            {/* Page Header */}
            <Row noGutters className="page-header py-4">
                <PageTitle title="Reportes" className="text-sm-left mb-3"/>
            </Row>

            <Row>
                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"/>
                            <h6 className="m-0">Reporte de Personal</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <Col className="my-4">
                                    <Link to={`/api/personnel_report/`} target='_blank'
                                          className="btn btn-primary col-lg-12"
                                          rel="noopener noreferrer" download>Descargar</Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"/>
                            <h6 className="m-0">Reporte de Empresas</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <Col className="my-4">
                                    <Link to={`/api/company_report/`} target='_blank'
                                          className="btn btn-primary col-lg-12"
                                          rel="noopener noreferrer" download>Descargar</Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"/>
                            <h6 className="m-0">Reporte de Archivos de Empresas</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <Col className="my-4">
                                    <Link to={`/api/company_files_report/`} target='_blank'
                                          className="btn btn-primary col-lg-12"
                                          rel="noopener noreferrer" download>Descargar</Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col lg="12" className="mb-8 my-4">
                    <Card small className="">
                        <CardHeader className="border-bottom">
                            <i className="icon icon-altacliente header-icon"/>
                            <h6 className="m-0">Reporte de Contactos</h6>
                        </CardHeader>
                        <CardBody className="d-flex flex-column">
                            <Row>
                                <Col className="my-4">
                                    <Link to={`/api/contacts_report/`} target='_blank'
                                          className="btn btn-primary col-lg-12"
                                          rel="noopener noreferrer" download>Descargar</Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Container></>;
        return container
    }

}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/

const mapStateToProps = state => {
    return {}
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(Reports2);
