import React from "react";
import { ButtonGroup, Button } from "shards-react";

/**
 * Componente que renderiza un grupo de botones.
 * @function
 * @name ButtonGroups
 * @returns {JSX.Element} Componente de React que contiene un grupo de botones.
*/

const ButtonGroups = () => (
  <ButtonGroup className="mb-3">
    <Button theme="primary">Fizz</Button>
    <Button theme="white">Buzz</Button>
    <Button theme="white">Foo</Button>
    <Button theme="white">Bar</Button>
  </ButtonGroup>
);

export default ButtonGroups;
