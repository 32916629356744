import React, {Component} from 'react';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormInput,
    FormSelect,
    FormTextarea,
    Row
} from "shards-react";

import {personnel, users} from '../actions';
import {connect} from 'react-redux';

/**
 * Componente React que representa la pantalla de creación y listado de no conformidades.
 * @constructor
 * @param {Object} props - Propiedades recibidas por el componente.
 * @param {Function} props.fetch_non_accordance - Función para obtener las no conformidades desde el servidor.
 * @param {Function} props.fetch_companies - Función para obtener las empresas colaboradoras desde el servidor.
 * @param {Function} props.create_non_accordance - Función para crear una nueva no conformidad en el servidor.
 * @param {Object} props.non_accordance - Lista de no conformidades obtenidas desde el servidor.
 * @param {Array} props.companies - Lista de empresas colaboradoras obtenidas desde el servidor.
 * @param {Object} props.user - Objeto que representa el usuario actualmente logueado.
 * @param {boolean} props.user.admin_access - Indica si el usuario tiene permisos de administrador.
 * @param {boolean} props.user.read_only - Indica si el usuario tiene permisos de solo lectura.
*/

class NonAccordance extends Component {

    constructor(props) {
        super(props);
/**
  * Estado del componente.
  *
  * @type {Object}
  * @property {string} file - Archivo adjunto a la no conformidad.
  * @property {string} description - Descripción de la no conformidad.
  * @property {string} company - ID de la empresa colaboradora asociada a la no conformidad.
*/
        this.state = {
            file: '',
            description: '',
            company: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cleanup = this.cleanup.bind(this);
    }
/**
 * Método que se ejecuta al montar el componente.
 * Obtiene las no conformidades y las empresas colaboradoras desde el servidor.
*/
    componentDidMount() {

        this.props.fetch_non_accordance();
        this.props.fetch_companies();
    }
/**
 * Método que limpia el estado del componente.
*/
    cleanup() {
        this.setState({
            file: '',
            description: '',
            company: '',
        })
    }
/**
 * Método que se ejecuta al cambiar el valor de un campo de formulario.
 * Actualiza el estado del componente con el nuevo valor.
 * @param {Event} event - Evento que disparó el cambio.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

/**
 * Método que se ejecuta al seleccionar un archivo en el input de tipo "file".
 * Actualiza el estado del componente con el archivo seleccionado.
 * @param {Event} e - Evento que disparó la selección de archivo.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

/**
 * Método que se ejecuta al enviar el formulario de creación de no conformidad.
 * Crea la nueva no conformidad en el servidor y limpia el estado del componente.
 * @param {Event} event - Evento que disparó el envío del formulario.
*/
    handleSubmit(event) {
        console.log('HANDLE SUBMIT')
        event.preventDefault();
        console.log('SUBMIT', this.state)
        this.props.create_non_accordance(this.state).then(this.cleanup())
    }

    render() {
        const {non_accordance, companies} = this.props;
        console.log(non_accordance);

        return (
            <Container fluid>
                {(this.props.user.admin_access && !this.props.user.read_only) && <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Crear no conformidad</h6>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col lg="12" className="form-group">
                                            <label htmlFor="file_type">Descripción</label>
                                            <FormTextarea
                                                id="description"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <label>Archivo</label>
                                            <FormInput
                                                type="file"
                                                onChange={this.fileChangeHandler}
                                                // value={this.state.file}
                                                required
                                            />
                                            <label htmlFor="file_type">Empresa colaboradora</label>
                                            <FormSelect
                                                onChange={this.handleChange}
                                                value={this.state.company}
                                                name="company"
                                                required
                                            >
                                                <option value=''>---</option>
                                                {companies.map((company, index) =>
                                                    <option key={company.id}
                                                            value={company.id}>{company.company_name}</option>
                                                )}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="12" className="my-4">
                                            <Button type="submit" className="col-lg-12">Crear no conformidad</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Listado de no conformidades</h6>
                            </CardHeader>
                            <CardBody>
                                <table className="table">
                                    {non_accordance.map((non_accordance, index) =>
                                        <tr key={non_accordance.id}>
                                            <td className={'upper'}>{non_accordance.company_name}</td>
                                            <td className={'upper'}>{non_accordance.description}</td>
                                            <td><a href={non_accordance.file} target='_blank'
                                                      className="btn btn-primary"
                                                      rel="noopener noreferrer" download>Descargar</a></td>
                                        </tr>
                                    )}

                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }


}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/
const mapStateToProps = state => {
    return {
        non_accordance: state.users.non_accordance,
        companies: state.personnel.companies,
        user: state.auth.user,
    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/

const mapDispatchToProps = dispatch => {
    return {
        fetch_non_accordance: () => {
            return dispatch(users.fetch_non_accordance())
        },
        create_non_accordance: (data) => {
            return dispatch(users.create_non_accordance(data))
        },
        fetch_companies: () => {
            return dispatch(personnel.fetch_companies())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NonAccordance);