/**
 * Objeto de estado inicial para el reducer de auth.
 * @typedef {Object} InitialAuthState
 * @property {Array} list - Lista de empresas.
 * @property {Object|null} detail - Detalles de la empresa.
 * @property {Array} company_files_multiple - Archivos múltiples de la empresa. 
 * @property {Array} company_files_multiple_names - Nombres de los archivos múltiples de la empresa.
 * @property {Object|null} data - Datos de la empresa.
 * @property {Array} contacts - Contactos de la empresa.
*/

const initialState = {
    list: [],
    detail: null,
    company_files_multiple: [],
    company_files_multiple_names: [],
    data: null,
    contacts: []
};

/**
 * Reducer para la companies.
 * @param {InitialAuthState} state - Estado actual.
 * @param {Object} action - Acción a realizar.
 * @param {string} action.type - Tipo de acción a realizar.
 * @param {*} action.data - Datos necesarios para realizar la acción.
 * @returns {InitialAuthState} Nuevo estado.
*/
export default function auth(state = initialState, action) {

    switch (action.type) {

        case 'FETCH_COMPANIES_C':
            console.log('IN FETCH')
            return {...state, list: action.data};

        case 'FETCH_COMPANY':
            return {...state, detail: action.data, contacts: action.data.contacts};

        case 'CREATE_COMPANY_FILE':
            return {...state, detail: {...state.detail, files: [action.data, ...state.detail.files]}}

        case 'FETCH_COMPANY_FILE_MULTIPLE':
            return {...state, company_files_multiple: action.data}

        case 'FETCH_COMPANY_FILE_NAMES':
            return {...state, company_files_multiple_names: action.data}

        case 'CREATE_COMPANY_FILE_MULTIPLE':
            return {...state, company_files_multiple: [action.data, ...state.company_files_multiple]}

        case 'CREATE_COMPANY':
            return {...state, list: [action.data, ...state.list]};


        case 'FETCH_COMPANY_DATA':
            return {...state, data: action.data}

        case 'UPDATE_COMPANY_DATA':
            return {...state, data: action.data}

        case 'CREATE_CONTACT':
            return {...state, contacts: [...state.contacts, action.data]}

        default:
            return state;
    }
}