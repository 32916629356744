/**
 * @typedef {Object} AuthState
 * @property {Array} vetoes - Lista de vetos.
 * @property {Array} administrative_acts - Lista de actos administrativos.
 * @property {Array} announcements - Lista de anuncios.
 * @property {Array} non_accordance - Lista de desacuerdos.
*/

/**
 * Función reductora que actualiza el estado de la autenticación.
 * @function
 * @param {AuthState} state - Estado actual de la autenticación.
 * @param {Object} action - Acción a ejecutar.
 * @returns {AuthState} Nuevo estado de la autenticación.
*/

const initialState = {
    vetoes: [],
    administrative_acts: [],
    announcements: [],
    non_accordance: [],
};

/**
 * Función reductora que actualiza el estado.
 * @function
 * @param {AuthState} state - Estado actual de la autenticación.
 * @param {Object} action - Acción a ejecutar.
 * @returns {AuthState} Nuevo estado de la autenticación.
*/

export default function auth(state = initialState, action) {

    switch (action.type) {

        case 'FETCH_VETOES':
            return {...state, vetoes: action.vetoes};
        case 'CREATE_VETO':
            return {...state, vetoes: [action.data, ...state.vetoes]}

        case 'FETCH_ADMINISTRATIVE_ACTS':
            return {...state, administrative_acts: action.data};
        case 'CREATE_ADMINISTRATIVE_ACT':
            return {...state, administrative_acts: [action.data, ...state.administrative_acts]};
        case 'FETCH_ANNOUNCEMENTS':
            return {...state, announcements: action.data};
        case 'CREATE_ANNOUNCEMENT':
            return {...state, announcements: [action.data, ...state.announcements]}
        case 'FETCH_NON_ACCORDANCE':
            return {...state, non_accordance: action.data};
        case 'CREATE_NON_ACCORDANCE':
            return {...state, non_accordance: [action.data, ...state.non_accordance]};
        default:
            return state;
    }
}