import React, {Component} from 'react';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormInput,
    FormSelect,
    FormTextarea,
    Row
} from "shards-react";

import {personnel, users} from '../actions';
import {connect} from 'react-redux';

/**
 * Componente que muestra un formulario para crear actas administrativas y una tabla con el listado de las actas ya creadas.
 * @name AdministrativeActs
 * @extends Component
*/

class AdministrativeActs extends Component {
/**
 * Constructor de la clase. Inicializa el estado del componente.
 * @param {object} props - Propiedades pasadas al componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            file: '',
            text: '',
            company: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cleanup = this.cleanup.bind(this);
    }
/**
 * Método que se ejecuta después de que el componente es montado.
 * Se llama a dos acciones para obtener las actas administrativas y las empresas colaboradoras.
*/
    componentDidMount() {

        this.props.fetch_administrative_acts();
        this.props.fetch_companies();
    }
/**
 * Método que limpia el estado del componente.
*/
    cleanup() {
        this.setState({
            file: '',
            text: '',
            company: '',
        })
    }
/**
 * Método que se encarga de actualizar el estado del componente cuando los valores de los campos cambian.
 * @param {object} event - Evento que provocó el cambio de valor.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

/**
 * Método que se encarga de actualizar el estado del componente cuando se selecciona un archivo.
 * @param {object} e - Evento que provocó el cambio.
*/
    fileChangeHandler = (e) => {
        this.setState({
            file: e.target.files[0],
        })
    };

/**
 * Método que se encarga de enviar los datos del formulario al servidor al presionar el botón de crear acta administrativa.
 * Llama a la acción para crear actas administrativas y luego limpia el estado del componente.
 * @param {object} event - Evento que provocó la acción.
*/
    handleSubmit(event) {
        console.log('HANDLE SUBMIT')
        event.preventDefault();
        console.log('SUBMIT', this.state)
        this.props.create_administrative_acts(this.state).then(this.cleanup())
    }

/**
 * Método que se encarga de renderizar el componente.
 * @returns {JSX.Element} - Elemento JSX que representa el componente.
*/
    render() {
        const {administrative_acts, companies} = this.props;
        console.log('COMPANIES', companies);

        return (
            <Container fluid>
                {(this.props.user.admin_access && !this.props.user.read_only) && <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Crear acta administrativa</h6>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={this.handleSubmit}>
                                    <Row>
                                        <Col lg="12" className="form-group">
                                            <label htmlFor="file_type">Descripción</label>
                                            <FormTextarea
                                                id="text"
                                                name="text"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <label>Archivo</label>
                                            <FormInput
                                                type="file"
                                                onChange={this.fileChangeHandler}
                                                // value={this.state.file}
                                                required
                                            />
                                            <label htmlFor="file_type">Empresa colaboradora</label>
                                            <FormSelect
                                                onChange={this.handleChange}
                                                value={this.state.company}
                                                name="company"
                                                required
                                            >
                                                <option value=''>---</option>
                                                {companies.map((company, index) =>
                                                    <option key={company.id}
                                                            value={company.id}>{company.company_name}</option>
                                                )}
                                            </FormSelect>
                                        </Col>
                                        <Col lg="12" className="my-4">
                                            <Button type="submit" className="col-lg-12">Crear acta
                                                administrativa</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                }
                <Row>
                    <Col>
                        <Card className="my-4">
                            <CardHeader>
                                <h6 className="m-0">Listado de actas administrativas</h6>
                            </CardHeader>
                            <CardBody>
                                <table className="table">
                                    {administrative_acts.map((administrative_act, index) =>
                                        <tr key={administrative_act.id}>
                                            <td className={'upper'}>{administrative_act.company_name}</td>
                                            <td className={'upper'}>{administrative_act.text}</td>
                                            <td><a href={administrative_act.file} target='_blank'
                                                      className="btn btn-primary"
                                                      rel="noopener noreferrer" download>Descargar</a></td>
                                        </tr>
                                    )}

                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }


}

/**
 * Función que mapea el estado de la aplicación a las props del componente AdministrativActs.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/

const mapStateToProps = state => {
    return {
        administrative_acts: state.users.administrative_acts,
        companies: state.personnel.companies,
        user: state.auth.user,
    }
};

/**
 * Función que mapea las acciones de la aplicación a las props del componente AdministrativActs.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_administrative_acts: () => {
            return dispatch(users.fetch_administrative_acts())
        },
        create_administrative_acts: (data) => {
            return dispatch(users.create_administrative_act(data))
        },
        fetch_companies: () => {
            return dispatch(personnel.fetch_companies())
        },
    }
};

/**
 * Componente que muestra un formulario para crear actas administrativas y un listado de las mismas.
*/

export default connect(mapStateToProps, mapDispatchToProps)(AdministrativeActs);