import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Card, CardBody, CardHeader, Container} from "shards-react";
import {Link} from "react-router-dom";

import {quote} from "../actions";
/**
 * Componente de lista de cotizaciones
 * @name QuotationList
 * @class
 * @extends Component
*/

class QuotationList extends Component {
/** 
 * Constructor del componente
 * @constructor
 * @param {Object} props - Propiedades del componente
*/
    constructor(props) {
        super(props);

        this.state = {
            created_at: "",
            name: "",
            rfc: "",
            phone: "",
            email: "",
            client_type: "",
        }
    }
/**
 * Método del ciclo de vida del componente que se ejecuta cuando se monta
 * @method
*/
    componentDidMount() {
        // console.log("component did mount");
        this.props.fetch_quotations();
    }
/**
 * Método para renderizar el componente
 * @method
 * @returns {JSX.Element} Elemento JSX que representa el componente
*/
    render() {

        const quotationList = (
            <tbody>
            {this.props.quotations.map(quotation =>
                <tr key={quotation.id}>
                    <td>{quotation.created_at}</td>
                    <td>{quotation.name}</td>
                    <td><Link to={`/quotations/${quotation.id}`}><Button className="m-0">Ver</Button></Link></td>
                </tr>
            )}
            </tbody>
        );

        return <Container>

            <Card className="my-3">
                <CardHeader>
                    <h6 className="m-0">Cotizaciones</h6>
                </CardHeader>

                <CardBody>
                    <table className="table">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                Fecha
                            </th>
                            <th scope="col" className="border-0">
                                Cotización
                            </th>
                            <th scope="col" className="border-0">
                                Detalle
                            </th>
                        </tr>
                        </thead>
                        {quotationList}
                    </table>
                </CardBody>

            </Card>

        </Container>
    }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/

const mapStateToProps = state => {
    return {
        quotations: state.quote.quotations,
    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_quotations: () => {
            dispatch(quote.fetch_quotations())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationList);