import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";

import { companies, personnel } from "../actions";
import CompanyData from "../components/companies/CompanyData";
import CompanyFilesMultiple from "../components/companies/CompanyFilesMultiple";
import CompanyPersonnel from "../components/companies/CompanyPersonnel";
import CompanyUsers from "../components/companies/CompanyUsers";
import Contacts from "../components/companies/Contacts";

/**
 * Componente React que representa los datos y funcionalidades de una empresa.
 * @class Company
 * @extends Component
 * @property {Object} props - Las propiedades que recibe el componente.
 * @property {Object} props.match - Las propiedades de la ruta del componente.
 * @property {string} props.match.params.id - El ID de la empresa que se va a mostrar.
 * @property {Object} props.company - La información de la empresa que se va a mostrar.
 * @property {Object[]} props.contacts - La lista de contactos de la empresa.
 * @property {Object[]} props.zones - La lista de zonas de la empresa.
 * @property {Function} props.fetch_company - La función que se utiliza para obtener los datos de la empresa desde el servidor.
 * @property {Function} props.fetch_zones - La función que se utiliza para obtener la lista de zonas desde el servidor.
 * @property {Object} state - El estado del componente.
 * @property {string} state.company_name - El nombre de la empresa que se va a mostrar.
 * @property {string} state.zone - La zona de la empresa que se va a mostrar.
 * @property {boolean} state.edit - Indica si se está editando la información de la empresa o no.
 * @property {Function} submit - La función que se utiliza para enviar los datos editados de la empresa al servidor.
 * @method componentDidMount - Se llama después de que el componente se haya montado en el DOM. Obtiene los datos de la empresa y las zonas desde el servidor.
 * @returns {JSX.Element} - El elemento JSX que representa el componente.
 */

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_name: "",
      zone: "",
      edit: false,
    };

    this.submit = this.submit.bind(this);
  }
  /**
   * Función que se llama cuando se envían los datos editados de la empresa al servidor.
   * @function submit
   * @param {Event} event - El evento que se ha producido.
   */
  submit(event) {}

  componentDidMount() {
    this.props.fetch_company(this.props.match.params.id).then(() => {
      const { company } = this.props;
      this.setState({
        company_name: company.company_name,
        zone: company.zone.id,
      });
    });
    this.props
      .fetch_zones()
      .then((zones) => this.setState({ ...this.state, zones: zones.zones }));
  }

  render() {
    console.log(this.state);
    const { company, contacts } = this.props;
    if (company === null)
      return (
        <Container fluid>
          <h2>Cargando información</h2>
        </Container>
      );
    const personnel = company.personnel;
    const users = company.users;
    return (
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card className="my-4">
              <CardHeader>
                <h6 className="m-0">Datos de la empresa</h6>
              </CardHeader>
              <CardBody>
                <CompanyData
                  company_id={this.props.match.params.id}
                  company={false}
                  zones={this.state.zones}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Contacts
              contacts={contacts}
              company_id={this.props.match.params.id}
            />
          </Col>
          <Col lg="6">
            <CompanyUsers
              users={users}
              company_id={this.props.match.params.id}
            />
          </Col>
          <Col lg="12">
            <CompanyFilesMultiple company_id={this.props.match.params.id} />
          </Col>
          <Col lg="12">
            <Card className="my-4">
              <CardHeader>
                <h6 className="m-0">Personal</h6>
              </CardHeader>
              <CardBody>
                <CompanyPersonnel personnel={personnel} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    company: state.companies.detail,
    zones: state.personnel.zones,
    contacts: state.companies.contacts,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_company: (id) => {
      return dispatch(companies.fetch_company(id));
    },
    fetch_zones: () => {
      return dispatch(personnel.fetch_zones());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
