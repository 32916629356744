import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormInput, FormSelect, Row} from "shards-react";

import {Link} from "react-router-dom";

import {quote} from '../actions';
import {connect} from 'react-redux';

/**
 * Componente QuotationDetail que muestra los detalles de una cotización.
 * @name QuotationDetail
 * @component
*/

class QuotationDetail extends Component {

/**
 * Constructor del componente. Inicializa el estado con los valores necesarios para mostrar la información de la cotización.
 * @constructor
 * @param {Object} props - Propiedades pasadas al componente.
*/
    constructor(props) {
        super(props);

        this.state = {
            created_at: "",
            name: "",
            rfc: "",
            phone: "",
            email: "",
            client_type: "",
            indirect_cost_1: "",
            indirect_cost_1_description: "",
            indirect_cost_2: "",
            indirect_cost_2_description: "",
            indirect_cost_3: "",
            indirect_cost_3_description: "",
            indirect_cost_4: "",
            indirect_cost_4_description: "",
            pricing: {
                meters: "",
                materials_price: "",
                workforce: "",
                indirect_costs: "",
            },
            additional_costs: "",
            total: "",
            owner: "",
            pricingId: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeMeters = this.handleChangeMeters.bind(this);

        // console.log("constructor")
    }
/**
 * Método del ciclo de vida del componente que se ejecuta después de que el componente haya sido montado.
 * Se encarga de cargar la información de la cotización y los precios en el estado del componente.
 * @method
*/
    componentDidMount() {

        this.props.fetch_all_pricing()
        this.props.fetch_quotation(this.props.match.params.id).then(() => {
            this.setState({
                created_at: this.props.quotation_detail.created_at,
                name: this.props.quotation_detail.name,
                rfc: this.props.quotation_detail.rfc,
                phone: this.props.quotation_detail.phone,
                email: this.props.quotation_detail.email,
                client_type: this.props.quotation_detail.client_type,
                indirect_cost_1: this.props.quotation_detail.indirect_cost_1,
                indirect_cost_1_description: this.props.quotation_detail.indirect_cost_1_description,
                indirect_cost_2: this.props.quotation_detail.indirect_cost_2,
                indirect_cost_2_description: this.props.quotation_detail.indirect_cost_2_description,
                indirect_cost_3: this.props.quotation_detail.indirect_cost_3,
                indirect_cost_3_description: this.props.quotation_detail.indirect_cost_3_description,
                indirect_cost_4: this.props.quotation_detail.indirect_cost_4,
                indirect_cost_4_description: this.props.quotation_detail.indirect_cost_4_description,
                pricing: this.props.quotation_detail.pricing || {
                    meters: "",
                    materials_price: "",
                    workforce: "",
                    indirect_costs: "",
                },
                additional_costs: this.props.quotation_detail.additional_costs,
                total: this.props.quotation_detail.total,
                owner: this.props.quotation_detail.owner,
                pricingId: this.props.quotation_detail.pricing.id,
            })
        })
    }
/**
 * Manejador de eventos que se ejecuta cuando se cambia un valor en un campo del formulario.
 * Actualiza el estado del componente con los valores ingresados por el usuario.
 * @method
 * @param {Event} event - Evento del campo que cambió.
*/
    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }
/**
 * Maneja la acción de enviar el formulario.
 * @param {Event} event - El evento submit del formulario.
 * @return {void}
*/
    handleSubmit(event) {
        event.preventDefault();
        const data = {...this.state, pricing: this.state.pricingId}
        // console.log("clicked submit", data);
        this.props.update_quotation(data, this.props.match.params.id);
        // window.location.reload();
    }
/**
 * Maneja el cambio en el valor del input de metros en la sección de precios.
 * @param {Event} event - El evento change del input de metros.
 * @return {void}
*/
    handleChangeMeters(event) {
        const eventValue = event.target.value;
        this.setState(prevState => ({
            ...this.state,
            pricing: {
                ...prevState.pricing,
                meters: eventValue
            },
            pricingId: +eventValue + 1,
        }))
    }

    render() {
        let quote = this.props.quotation_detail;
        const all_pricing = this.props.all_pricing;
        console.log('quote', all_pricing);
        return <Container fluid>
            {quote ? (
                <div>
                    <Row className="m-3">
                        <Col>
                            <Link to={`/${quote.id}/pdf/download/`} target='_blank' rel="noopener noreferrer" download>
                                <Button className="float-right col-md-6 col-lg-3">
                                    Descargar
                                </Button>
                            </Link>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6">
                            <Card className="my-3">
                                <CardHeader>
                                    <h6 className="m-0">Costos por instalación</h6>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <table className="table">
                                            <thead className="bg-light">
                                            <tr>
                                                <th scope="col" className="border-0">Concepto</th>
                                                <th scope="col" className="border-0">Costo</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{quote.actual_pricing.meters}</td>
                                                <td>Metros</td>
                                            </tr>
                                            <tr>
                                                <td>{quote.actual_pricing.materials_price}</td>
                                                <td>Materiales</td>
                                            </tr>
                                            <tr>
                                                <td>{quote.actual_pricing.workforce}</td>
                                                <td>Mano de Obra</td>
                                            </tr>
                                            <tr>
                                                <td>{quote.actual_pricing.indirect_costs}</td>
                                                <td>Indirectos</td>
                                            </tr>
                                            <tr>
                                                <td>{quote.additional_costs}</td>
                                                <td>Costos Adicionales</td>
                                            </tr>
                                            <tr>
                                                <td>$ {quote.total.toLocaleString("en-US", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}</td>
                                                <td>Total</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <Button className="w-100" type="submit"
                                                onSubmit={this.handleSubmit}>Guardar</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6">
                            <Card className="my-3">
                                <CardHeader>
                                    <h6 className="m-0">Datos del Cliente</h6>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <FormInput
                                                    size="lg"
                                                    type="date"
                                                    placeholder="Fecha de cotización"
                                                    name="created_at"
                                                    value={this.state.created_at}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <FormInput
                                                    size="lg"
                                                    type="text"
                                                    placeholder="Razón Social o Nombre"
                                                    name="name"
                                                    value={this.state.name}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <FormInput
                                                    size="lg"
                                                    type="text"
                                                    placeholder="RFC"
                                                    name="rfc"
                                                    value={this.state.rfc}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <FormInput
                                                    size="lg"
                                                    type="tel"
                                                    placeholder="Teléfono"
                                                    // pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                                                    name="phone"
                                                    value={this.state.phone}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <FormInput
                                                    size="lg"
                                                    type="email"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <FormSelect
                                                    size="lg"
                                                    name="client_type"
                                                    value={this.state.client_type}
                                                    onChange={this.handleChange}>
                                                    <option value="IN">Persona Fisica</option>
                                                    <option value="LI">Persona Moral</option>
                                                </FormSelect>
                                            </Col>
                                        </Row>
                                        <Button className="w-100" type="submit">Guardar</Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="6">
                            <Card className="my-3">
                                <CardHeader>
                                    <h6 className="m-0">Metros de instalación</h6>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <FormSelect
                                            size="lg"
                                            name="pricingId"
                                            value={this.state.pricingId}
                                            onChange={this.handleChange}>
                                            {all_pricing.map((pricing, key) => (
                                                <option key={pricing.id}
                                                        value={pricing.id}>{pricing.meters} metros</option>
                                            ))}
                                        </FormSelect>

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="6">
                            <Card className="my-3">
                                <CardHeader>
                                    <h6 className="m-0">Costos adicionales</h6>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.handleSubmit}>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostOne">Costo Adicional 1</label>
                                                <FormInput
                                                    id="additionalCostOne"
                                                    size="lg"
                                                    type="number"
                                                    placeholder="0"
                                                    name="indirect_cost_1"
                                                    value={this.state.indirect_cost_1}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostOneDesc">Descripción</label>
                                                <FormInput
                                                    id="additionalCostOneDesc"
                                                    size="lg"
                                                    type="text"
                                                    placeholder=""
                                                    name="indirect_cost_1_description"
                                                    value={this.state.indirect_cost_1_description}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostTwo">Costo Adicional 2</label>
                                                <FormInput
                                                    id="additionalCostTwo"
                                                    size="lg"
                                                    type="number"
                                                    placeholder="0"
                                                    name="indirect_cost_2"
                                                    value={this.state.indirect_cost_2}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostTwoDesc">Descripción</label>
                                                <FormInput
                                                    id="additionalCostTwoDesc"
                                                    size="lg"
                                                    type="text"
                                                    placeholder=""
                                                    name="indirect_cost_2_description"
                                                    value={this.state.indirect_cost_2_description}
                                                    onChange={this.handleChange}

                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostThree">Costo Adicional 3</label>
                                                <FormInput
                                                    id="additionalCostThree"
                                                    size="lg"
                                                    type="number"
                                                    placeholder="0"
                                                    name="indirect_cost_3"
                                                    value={this.state.indirect_cost_3}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostThreeDesc">Descripción</label>
                                                <FormInput
                                                    id="additionalCostThreeDesc"
                                                    size="lg"
                                                    type="text"
                                                    placeholder=""
                                                    name="indirect_cost_3_description"
                                                    value={this.state.indirect_cost_3_description}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostFour">Costo Adicional 4</label>
                                                <FormInput
                                                    id="additionalCostFour"
                                                    size="lg"
                                                    type="number"
                                                    placeholder="0"
                                                    name="indirect_cost_4"
                                                    value={this.state.indirect_cost_4}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col md="6" className="mb-3">
                                                <label htmlFor="additionalCostFourDesc">Descripción</label>
                                                <FormInput
                                                    id="additionalCostFourDesc"
                                                    size="lg"
                                                    type="text"
                                                    placeholder=""
                                                    name="indirect_cost_4_description"
                                                    value={this.state.indirect_cost_4_description}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        {/*<Button className="w-100" type="submit">Guardar</Button>*/}
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            ) : null
            }
        </Container>
    }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/
const mapStateToProps = state => {
    return {
        quotation_detail: state.quote.quotation_detail,
        all_pricing: state.quote.all_pricing,
    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        fetch_quotation: id => {
            return dispatch(quote.fetch_quotation(id))
        },
        fetch_all_pricing: () => {
            return dispatch(quote.fetch_all_pricing())
        },
        update_quotation: (data, id) => {
            return dispatch(quote.update_quotation(data, id))
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetail);