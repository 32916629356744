import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, Container } from "shards-react";

import { personnel } from "../actions";
import AccreditationCheckbox from "../components/personnel/Checkboxes";
import Paging from "../components/personnel/Paging";

/**
 * Componente para mostrar una lista de acreditaciones de personal.
 * @name QuotationList
 * @class
 * @extends Component
 * @property {Array} personnel - Un array que contiene la información de personal y sus acreditaciones.
 * @property {Function} fetch_personnel_accreditations - Función para buscar la información de acreditaciones de personal.
 */

class QuotationList extends Component {
  /**
   * Crea una instancia de QuotationList.
   * @constructor
   * @param {Object} props - Propiedades para inicializar el componente.
   */
  constructor(props) {
    super(props);

    this.state = {
      accreditations: [],
      page: 1,
    };

    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  /**
   * Función que se ejecuta después de montar el componente, llama a la función para buscar la información de acreditaciones de personal.
   */
  componentDidMount() {
    this.props.fetch_personnel_accreditations(this.state.page);
  }
  /**
   * Función para manejar el cambio de estado del checkbox.
   * @param {Object} event - Evento del checkbox.
   */
  handleCheckbox(event) {
    this.setState({
      checked: !this.state.checked,
    });
  }
  /**
   * Renderiza el componente.
   * @returns {JSX.Element}
   */
  render() {
    const { personnel } = this.props;

    let p_header = [];
    if (personnel.length > 0) {
      p_header = personnel[0].personnel_accreditation;
    }

    return (
      <Container fluid>
        <Card className="my-3">
          <CardHeader>
            <h6 className="m-0">Acreditación de Personal</h6>
          </CardHeader>

          <CardBody>
            <Paging />
            <div className="table-responsive">
              <table className="table">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Estatus
                    </th>
                    <th scope="col" className="border-0">
                      Empresa
                    </th>
                    <th scope="col" className="border-0">
                      Nombre
                    </th>
                    <th scope="col" className="border-0">
                      Perfil
                    </th>
                    <th scope="col" className="border-0">
                      Número de Acreditación
                    </th>
                    {p_header.map((p) => (
                      <th scope="col" className="border-0" key={p.id}>
                        {p.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {personnel.map((person) => (
                    <tr key={person.id}>
                      <td className="text-center">
                        <i
                          className={`material-icons label-success tl-${person.accreditation_flag}`}
                        >
                          brightness_1
                        </i>
                      </td>
                      <td>{person.name}</td>
                      <td>{person.company_name}</td>
                      <td>{person.role}</td>
                      <td>{person.accreditation_number}</td>
                      {person.personnel_accreditation.map(
                        (personnel_accreditation) => (
                          // <td key={pa.id}>{pa.status.toString()}</td>
                          <td key={personnel_accreditation.id}>
                            <AccreditationCheckbox
                              personnel_accreditation={personnel_accreditation}
                              disableAll={this.props.user.read_only}
                            />
                          </td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paging />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
 */
const mapStateToProps = (state) => {
  return {
    personnel: state.personnel.personnelAccreditation.results,
    user: state.auth.user,
  };
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetch_personnel_accreditations: (page) => {
      return dispatch(personnel.fetch_personnel_accreditations(page));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationList);
