import React from 'react';

class DownloadButton extends React.Component {
    handleDownload = async () => {
        const {url} = this.props;

        try {
            const response = await fetch(url, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = '';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(downloadUrl);
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.error}`);
            }
        } catch (error) {
            alert(`An error occurred: ${error.message}`);
        }
    };

    render() {
        return (<button onClick={this.handleDownload} className="btn btn-primary">Descargar</button>);
    }
}

export default DownloadButton;
