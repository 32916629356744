import React from "react";
import { Row, Col, Button } from "shards-react";

/**
 * Componente que muestra una serie de botones con bordes y texto de color sólido y fondo transparente.
 * @name NormalOutlineButtons
 * @return {JSX.Element} Elemento React que contiene los botones.
 * @example
 * <NormalOutlineButtons />
 * @component
*/

const NormalOutlineButtons = () => (
  <Row>
    <Col>
      <Button outline theme="primary" className="mb-2 mr-1">
        Primary
      </Button>
      <Button outline theme="secondary" className="mb-2 mr-1">
        Secondary
      </Button>
      <Button outline theme="success" className="mb-2 mr-1">
        Success
      </Button>
      <Button outline theme="danger" className="mb-2 mr-1">
        Danger
      </Button>
      <Button outline theme="warning" className="mb-2 mr-1">
        Warning
      </Button>
      <Button outline theme="info" className="mb-2 mr-1">
        Info
      </Button>
      <Button outline theme="dark" className="mb-2 mr-1">
        Dark
      </Button>
      <Button outline theme="light" className="mb-2 mr-1">
        Light
      </Button>
    </Col>
  </Row>
);

export default NormalOutlineButtons;

/**
 * @typedef {Object} ButtonProps 
 * @property {string} theme Tema de color del botón.
 * @property {string} className Clase CSS para dar estilo al botón.
 * @property {boolean} outline Indica si el botón debe tener borde o no.
 * @typedef {Object} RowProps
 * @property {JSX.Element} children Elementos que se mostrarán dentro de la fila.
 * @typedef {Object} ColProps
 * @property {JSX.Element} children Elementos que se mostrarán dentro de la columna.
*/