import axios from "axios";

/**
 * @function fetch_personnel_accreditations
 * @description Esta función realiza una petición GET a la API para obtener las acreditaciones del personal. Si se especifica una página, la función realiza la petición para esa página, de lo contrario se establece la página en 1. También se establece el encabezado de la solicitud con el tipo de contenido "application/json" y el token de autorización si está presente en el estado de autenticación.
 * @param {number} page - El número de página a recuperar (opcional)
 * @returns {Promise} - Una promesa que devuelve un objeto con los datos de acreditación del personal.
 */

export const fetch_personnel_accreditations = (page) => {
  return (dispatch, getState) => {
    if (!page) page = 1;
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/personnel_accreditation/?page=${page}`, { headers })
      .then((res) => {
        return dispatch({
          type: "FETCH_PERSONNEL_ACCREDITATION",
          personnelAccreditation: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching personnel accreditations");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función que realiza una solicitud GET a la API para obtener información del personal.
 * @function fetch_personnel
 * @returns {Promise} Promesa que resuelve con un objeto que contiene la acción 'FETCH_PERSONNEL' y el personal obtenido.
 * @param {Function} dispatch - Función de redux que se utiliza para enviar una acción al store.
 * @param {Function} getState - Función de redux que se utiliza para obtener el estado actual del store.
 * @throws {Error} - Si ocurre un error al realizar la solicitud.
 */

export const fetch_personnel = (filter) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    if (filter === "activos" || filter === "inactivos") {
      return axios
        .get(`/api/personnel/?active=${filter === "activos" ? true : false}`, {
          headers,
        })
        .then((res) => {
          return dispatch({ type: "FETCH_PERSONNEL", personnel: res.data });
        })
        .catch((err) => {
          console.log("Error fetching personnel");
          console.log(err);
          console.log(err.response.status);
        });
    }

    return axios
      .get("/api/personnel/", { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_PERSONNEL", personnel: res.data });
      })
      .catch((err) => {
        console.log("Error fetching personnel");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función que actualiza el estado de las acreditaciones del personal en la API.
 * @function
 * @name update_personnel_accreditations
 * @param {number} id - ID del personal cuyas acreditaciones se actualizarán.
 * @param {string} status - Nuevo estado de las acreditaciones del personal.
 * @returns {Promise} Promesa que resuelve con un objeto que contiene la acción 'UPDATE_PERSONNEL_ACREDITATION'.
 * @param {Function} dispatch - Función de redux que se utiliza para enviar una acción al store.
 * @param {Function} getState - Función de redux que se utiliza para obtener el estado actual del store.
 * @throws {Error} - Si ocurre un error al realizar la solicitud.
 */

export const update_personnel_accreditations = (id, status) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const data = {
      status: status,
    };
    return axios
      .patch(`/api/personnel_accreditation_status/${id}/`, data, { headers })
      .then((res) => {
        console.log("SUCCESS");
        console.log(res);
        return dispatch({ type: "UPDATE_PERSONNEL_ACREDITATION" });
      })
      .catch((err) => {
        console.log("Error updating personnel accreditations");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función que crea un nuevo registro de personal en la API.
 * @function
 * @name create_personnel
 * @param {Object} data - Datos del personal que se enviarán en la solicitud de creación.
 * @returns {Promise} Promesa que resuelve con un objeto que contiene la acción 'CREATE_PERSONNEL' y los datos del personal creado.
 * @param {Function} dispatch - Función de redux que se utiliza para enviar una acción al store.
 * @param {Function} getState - Función de redux que se utiliza para obtener el estado actual del store.
 * @throws {Error} - Si ocurre un error al realizar la solicitud.
 */

export const create_personnel = (data) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    console.log("Data", data);

    return axios
      .post(`/api/personnel/`, data, { headers })
      .then((res) => {
        console.log("SUCCESS");
        console.log(res);
        return dispatch({ type: "CREATE_PERSONNEL", personnel: res.data });
      })
      .catch((err) => {
        console.log("Error updating personnel accreditations");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Obtiene el detalle de una persona en particular mediante una solicitud GET a la API.
 * @function
 * @name fetch_personnel_detail
 * @param {number} id - Identificador de la persona que se desea obtener el detalle.
 * @return {function} - Retorna una función que acepta como argumentos 'dispatch' y 'getState' y devuelve una promesa que resuelve en una acción con el tipo 'FETCH_PERSONNEL_DETAIL' y la información detallada de la persona solicitada en caso de éxito, o en su defecto imprime en consola información detallada del error.
 */

export const fetch_personnel_detail = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return axios
      .get(`/api/personnel/${id}/`, { headers })
      .then((res) => {
        console.log("RES DATA");
        console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        return dispatch({
          type: "FETCH_PERSONNEL_DETAIL",
          personnel_detail: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching personnel detail");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Obtiene el detalle público de una persona en particular mediante una solicitud GET a la API.
 * @function
 * @name fetch_personnel_detail_public
 * @param {number} id - Identificador de la persona que se desea obtener el detalle público.
 * @return {function} - Retorna una función que acepta como argumentos 'dispatch' y 'getState' y devuelve una promesa que resuelve en una acción con el tipo 'FETCH_PERSONNEL_DETAIL' y la información detallada de la persona solicitada en caso de éxito, o en su defecto imprime en consola información detallada del error.
 */

export const fetch_personnel_detail_public = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    return axios
      .get(`/api/personnel_public/${id}/`, { headers })
      .then((res) => {
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        return dispatch({
          type: "FETCH_PERSONNEL_DETAIL",
          personnel_detail: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching personnel detail");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Actualiza los detalles de un miembro del personal en la base de datos.
 * @name update_personnel_detail
 * @param {Object} data - Los datos del miembro del personal que se van a actualizar.
 * @param {string} id - El ID del miembro del personal que se va a actualizar.
 * @returns {Promise} - Una promesa que se resuelve con los detalles actualizados del miembro del personal.
 */

export const update_personnel_detail = (data, id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    // data = JSON.stringify(data);
    console.log("UPDATE", data);
    let body = new FormData();
    body.append("first_names", data.first_names);
    body.append("last_names", data.last_names);
    body.append("rfc", data.rfc);
    body.append("ssn", data.ssn);
    body.append("role", data.role);
    body.append("company", data.company);
    // body.append('zone', data.zone);
    body.append("address", data.address);
    body.append("phone", data.phone);
    if (data.accreditation_date !== null) {
      body.append("accreditation_date", data.accreditation_date);
    }
    body.append("accreditation_number", data.accreditation_number);
    body.append("email", data.email);
    body.append("active", data.active);
    body.append("naturgy_id", data.naturgy_id);

    if (data.profile_picture !== "") {
      body.append("profile_picture", data.profile_picture);
    }

    console.log("UPDATE EE", body.values());

    /**
     * Envía una solicitud de actualización de los detalles de un miembro del personal a la API.
     * @param {string} url - La URL de la API donde se actualizarán los detalles.
     * @param {Object} body - Los datos del miembro del personal que se van a actualizar.
     * @param {Object} headers - Las cabeceras de la solicitud HTTP.
     * @returns {Promise} - Una promesa que se resuelve con los detalles actualizados del miembro del personal.
     */

    return axios
      .patch(`/api/personnel/${id}/`, body, { headers })
      .then((res) => {
        console.log(res.data);
        return dispatch({
          type: "UPDATE_PERSONNEL_DETAIL",
          personnel_detail: res.data,
        });
      })
      .catch((err) => {
        console.log("Error updating personnel detail");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Obtiene los detalles de la acreditación de un miembro del personal de la base de datos.
 * @name fetch_personnel_accreditation_detail
 * @param {string} id - El ID del miembro del personal cuya acreditación se quiere obtener.
 * @returns {Promise} - Una promesa que se resuelve con los detalles de la acreditación del miembro del personal.
 */

export const fetch_personnel_accreditation_detail = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * Envía una solicitud para obtener los detalles de la acreditación de un miembro del personal a la API.
     *
     * @param {string} url - La URL de la API donde se obtendrán los detalles de la acreditación.
     * @param {Object} headers - Las cabeceras de la solicitud HTTP.
     * @returns {Promise} - Una promesa que se resuelve con los detalles de la acreditación del miembro del personal.
     */
    return axios
      .get(`/api/personnel_accreditation/${id}/`, { headers })
      .then((res) => {
        // console.log("RES DATA");
        // console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        return dispatch({
          type: "FETCH_PERSONNEL_ACCREDITATION_DETAIL",
          personnel_accreditation_detail: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching personnel accreditation detail");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Recupera los roles desde el servidor.
 * @returns {function} Función que acepta 'dispatch' y 'getState' y devuelve una promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
 * @throws {Error} Si ocurre un error al recuperar los roles.
 */

export const fetch_roles = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    /**
     * Token de autenticación.
     * @type {string}
     */
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * Realiza una solicitud GET al servidor para recuperar los roles.
     * @param {string} url - URL de la solicitud.
     * @param {Object} headers - Cabeceras de la solicitud.
     * @returns {Promise} Promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
     */
    return axios
      .get(`/api/roles/`, { headers })
      .then((res) => {
        // console.log("RES DATA");
        // console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        /**
         * Acción a despachar para almacenar los roles recuperados.
         * @type {Object}
         * @property {string} type - Tipo de la acción.
         * @property {Array} roles - Lista de roles recuperados.
         */
        return dispatch({ type: "FETCH_ROLES", roles: res.data });
      })
      .catch((err) => {
        console.log("Error fetching roles");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Recupera las zonas desde el servidor.
 * @name fetch_zones
 * @returns {function} Función que acepta 'dispatch' y 'getState' y devuelve una promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
 * @throws {Error} Si ocurre un error al recuperar las zonas.
 */

export const fetch_zones = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    /**
     * Token de autenticación.
     * @type {string}
     */
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * Realiza una solicitud GET al servidor para recuperar las zonas.
     *
     * @param {string} url - URL de la solicitud.
     * @param {Object} headers - Cabeceras de la solicitud.
     *
     * @returns {Promise} Promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
     */

    return axios
      .get(`/api/zones/`, { headers })
      .then((res) => {
        // console.log("RES DATA");
        // console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        /**
         * Acción a despachar para almacenar las zonas recuperadas.
         * @type {Object}
         * @property {string} type - Tipo de la acción.
         * @property {Array} zones - Lista de zonas recuperadas.
         */
        return dispatch({ type: "FETCH_ZONES", zones: res.data });
      })
      .catch((err) => {
        console.log("Error fetching zones");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Recupera las compañías desde el servidor.
 * @name fetch_companies
 * @returns {function} Función que acepta 'dispatch' y 'getState' y devuelve una promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
 * @throws {Error} Si ocurre un error al recuperar las compañías.
 */

export const fetch_companies = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    /**
     * Realiza una solicitud GET al servidor para recuperar las compañías.
     * @param {string} url - URL de la solicitud.
     * @param {Object} headers - Cabeceras de la solicitud.
     * @returns {Promise} Promesa que se resuelve con el resultado de la solicitud o se rechaza con un error.
     */
    return axios
      .get(`/api/companies/`, { headers })
      .then((res) => {
        // console.log("RES DATA");
        // console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        return dispatch({ type: "FETCH_COMPANIES", companies: res.data });
      })
      .catch((err) => {
        console.log("Error fetching zones");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * @name fetch_personnel_search
 * Esta función realiza una búsqueda de personal en la API con el término de búsqueda especificado.
 * @param {string} query - Término de búsqueda para buscar el personal en la API.
 * @returns {function} - Una función que realiza una solicitud GET a la API con el término de búsqueda especificado y devuelve los resultados de la búsqueda de personal.
 */

export const fetch_personnel_search = (query) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    // Agregar token de autenticación a las cabeceras de la solicitud HTTP si existe
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    // Realizar una solicitud GET a la API con el término de búsqueda especificado y las cabeceras proporcionadas
    return axios
      .get(`/api/personnel_search/?search=${query}`, { headers })
      .then((res) => {
        console.log("RES DATA");
        // console.log(res.data);
        // NOTE TO SELF: si te regresa puro html es porque no pusiste el último /
        // Despachar acción con los resultados de la búsqueda de personal
        return dispatch({
          type: "FETCH_PERSONNEL_SEARCH",
          personnel_search: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching personnel detail");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * @name fetch_personnel_file_choices
 * Esta función obtiene las opciones de nombres de archivos de personal disponibles en la API.
 * @returns {function} - Una función que realiza una solicitud GET a la API y devuelve las opciones de nombres de archivos de personal disponibles.
 */

export const fetch_personnel_file_choices = () => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      // Agregar token de autenticación a las cabeceras de la solicitud HTTP si existe
      headers["Authorization"] = `Token ${token}`;
    }
    // Realizar una solicitud GET a la API para obtener las opciones de nombres de archivos de personal
    return axios
      .get(`/api/personnel_file_names/`, { headers })
      .then((res) => {
        // Despachar acción con los datos de las opciones de nombres de archivos de personal
        return dispatch({
          type: "FETCH_PERSONNEL_FILE_CHOICES",
          data: res.data,
        });
      })
      .catch((err) => {
        console.log("Error fetching zones");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función para obtener los archivos del personal de la API mediante una solicitud GET con axios.
 * @name fetch_personnel_files
 * @param {number} id - El id del personal cuyos archivos se van a buscar.
 * @returns {Promise} Promesa que devuelve los datos de la solicitud si se realiza correctamente.
 */

export const fetch_personnel_files = (id) => {
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    /**
     * Realiza una solicitud GET a la API de los archivos de personal que corresponden al id especificado.
     * @param {string} url - La url de la API a la que se va a realizar la solicitud.
     * @param {Object} headers - Los encabezados que se van a incluir en la solicitud.
     * @returns {Promise} Promesa que devuelve los datos de la solicitud si se realiza correctamente.
     */

    return axios
      .get(`/api/personnel_files/?personnel_id=${id}`, { headers })
      .then((res) => {
        return dispatch({ type: "FETCH_PERSONNEL_FILES", data: res.data });
      })
      .catch((err) => {
        console.log("Error fetching zones");
        console.log(err);
        console.log(err.response.status);
      });
  };
};

/**
 * Función para crear un archivo de personal en la API mediante una solicitud POST con axios.
 * @name create_personnel_file
 * @param {Object} data - Los datos del archivo de personal que se van a crear.
 * @param {string} data.name - El nombre del archivo de personal.
 * @param {number} data.person - El id de la persona asociada al archivo de personal.
 * @param {File} data.file - El archivo de personal a cargar.
 * @returns {Promise} Promesa que devuelve los datos de la solicitud si se realiza correctamente.
 */

export const create_personnel_file = (data) => {
  console.log("POST REQUEST");
  return (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    let body = new FormData();
    body.append("name", data.name);
    body.append("person", data.person);
    body.append("file", data.file);

    /**
     * Realiza una solicitud POST a la API para crear un nuevo archivo de personal con los datos especificados.
     * @param {string} url - La url de la API a la que se va a realizar la solicitud.
     * @param {Object} body - Los datos que se van a enviar en el cuerpo de la solicitud.
     * @param {Object} headers - Los encabezados que se van a incluir en la solicitud.
     * @returns {Promise} Promesa que devuelve los datos de la solicitud si se realiza correctamente.
     */
    return axios
      .post(`/api/personnel_files/`, body, { headers })
      .then((res) => {
        return dispatch({ type: "CREATE_PERSONNEL_FILE", data: res.data });
      })
      .catch((err) => {
        console.log("Error creating file");
        console.log(err);
        console.log(err.response.status);
        console.log(err.response);
      });
  };
};
