import React, {Component} from 'react';
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    CardBody,
    Form,
    FormInput,
    Row,
    FormSelect, Alert
} from "shards-react";

import {quote} from '../actions';
import {connect} from 'react-redux';
/**
 * Clase que representa un componente para crear cotizaciones.
 * @name QuotationCreate
 * @extends Component
*/

class QuotationCreate extends Component {
/**
 * Crea una instancia de QuotationCreate.
 * @constructor
 * @param {Object} props - Propiedades recibidas por el componente.
*/
    constructor(props) {
        super(props);
/**
 * Estado inicial del componente.
 * @property {string} created_at - Fecha de creación de la cotización.
 * @property {string} name - Razón social o nombre del cliente.
 * @property {string} rfc - Registro Federal de Contribuyentes del cliente.
 * @property {string} phone - Número de teléfono del cliente.
 * @property {string} email - Correo electrónico del cliente.
 * @property {string} client_type - Tipo de cliente, puede ser "IN" para persona física o "LI" para persona moral.
 * @property {boolean} success - Indica si la creación de la cotización fue exitosa.
*/
        this.state = {
            created_at: "",
            name: "",
            rfc: "",
            phone: "",
            email: "",
            client_type: "IN",
            success: false,
        }

        this.submitHandler = this.submitHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.stateCleanup = this.stateCleanup.bind(this);
    }
/**
 * Manejador de eventos para el envío del formulario.
 * @param {Event} event - Evento del formulario.
*/
    submitHandler(event) {
        event.preventDefault();
        console.log("clicked submit");
        this.props.create_quotation(this.state)
            .then(this.stateCleanup())
            .then(this.setState({success: true}))
            .then(setTimeout(() => this.setState({ success: false }), 5000));
    }
/**
 * Manejador de eventos para cambios en los campos del formulario.
 * @param {Event} event - Evento del campo del formulario.
*/
    changeHandler(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }
/**
 * Limpia el estado del componente.
*/
    stateCleanup() {
        this.setState({
            created_at: "",
            name: "",
            rfc: "",
            phone: "",
            email: "",
            client_type: "IN",
        })
    }
/**
 * Método que renderiza el componente.
 * @returns {ReactNode} Elemento del DOM que representa el componente.
*/
    render() {
        return <Container>

            {this.state.success ? <Container>
                <Alert theme="success" className="my-2">
                    Cotización creada exitosamente
                </Alert>
            </Container> : null}

            <Card className="my-3">

                <CardHeader>
                    <h6 className="m-0">Crear Cotización IRI</h6>
                </CardHeader>

                <CardBody>

                    <Form onSubmit={this.submitHandler}>
                        <Row form>
                            <Col md="6" className="mb-3">
                                <FormInput
                                    size="lg"
                                    type="date"
                                    placeholder="Fecha de cotización"
                                    name="created_at"
                                    onChange={this.changeHandler}
                                    value={this.state.created_at}
                                />
                            </Col>
                            <Col md="6" className="mb-3">
                                <FormInput
                                    size="lg"
                                    type="text"
                                    placeholder="Razón Social o Nombre"
                                    name="name"
                                    onChange={this.changeHandler}
                                    value={this.state.name}
                                />
                            </Col>
                        </Row>
                        <Row form>
                            <Col md="6" className="mb-3">
                                <FormInput
                                    size="lg"
                                    type="text"
                                    placeholder="RFC"
                                    name="rfc"
                                    onChange={this.changeHandler}
                                    value={this.state.rfc}
                                />
                            </Col>
                            <Col md="6" className="mb-3">
                                <FormInput
                                    size="lg"
                                    type="tel"
                                    placeholder="Teléfono"
                                    // pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                                    name="phone"
                                    onChange={this.changeHandler}
                                    value={this.state.phone}
                                />
                            </Col>
                        </Row>
                        <Row form>
                            <Col md="6" className="mb-3">
                                <FormInput
                                    size="lg"
                                    type="email"
                                    placeholder="Correo Electrónico"
                                    name="email"
                                    onChange={this.changeHandler}
                                    value={this.state.email}
                                />
                            </Col>
                            <Col md="6" className="mb-3">
                                <FormSelect size="lg" name="client_type" onChange={this.changeHandler} value={this.state.client_type}>
                                    <option value="IN">Persona Física</option>
                                    <option value="LI">Persona Moral</option>
                                </FormSelect>
                            </Col>
                        </Row>

                        <Button className="w-100" type="submit">Siguiente</Button>

                    </Form>


                </CardBody>
            </Card>

        </Container>;
    }
}
/**
 * Función que mapea el estado de la aplicación a las props del componente.
 * @param {Object} state - Estado actual de la aplicación.
 * @returns {Object} Objeto con las props del componente.
*/
const mapStateToProps = state => {
    return {

    }
};
/**
 * Función que mapea las acciones de la aplicación a las props del componente.
 * @param {Function} dispatch - Función de Redux para despachar acciones.
 * @returns {Object} Objeto con las funciones que despachan acciones del componente.
*/
const mapDispatchToProps = dispatch => {
    return {
        create_quotation: (data) => {
            return dispatch(quote.create_quotation(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuotationCreate);